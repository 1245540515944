import {  Dialog, DialogContent } from "@mui/material";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect, useRef } from "react";
import { TreeSelect } from "components/TreeSelect/TreeSelect";
import { SingleTreeSelect } from "components/TreeSelect/SingleTreeSelect";
import { SingleTreeSecondFilter } from "components/TreeSelect/SingleTreeSecondFilter";
import { SingleTreeThirdOnwardFilter } from "components/TreeSelect/SingleTreeThirdOnwardFilter";
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { Box } from "@mui/material";
import { TDashboardUnitFilter } from "types/dashboard";
import Chip from '@mui/material/Chip';
import ChipsUnitWidget from "components/ChipsUnitWidget/ChipsUnitWidget";

type UnitSelectionPopupProps = {
  open: boolean;
  onClose: () => void;
  onSelect: (units: string[]) => void;
  units: TDashboardUnitFilter[];
  isSingleSelect: boolean;
  isEditMode?: boolean;
  isFirstFilter?: boolean;
  previousSelections?: string[];
  filterLevel?: number;
};

const UnitSelectionPopup = ({ open, onClose, onSelect, units, isSingleSelect, isEditMode = false, isFirstFilter = undefined, previousSelections = [], filterLevel = 1 }: UnitSelectionPopupProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const treeSelectRef = useRef<any>(null);
  const singleTreeSelectRef = useRef<any>(null);
  const secondFilterRef = useRef<any>(null);
  const thirdOnwardFilterRef = useRef<any>(null);
  const [matches, setMatches] = useState<{ id: string; label: string }[]>([]);
  const [currentMatchId, setCurrentMatchId] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [initialSelection, setInitialSelection] = useState<string[]>([]);
  const [isChipsExpanded, setIsChipsExpanded] = useState(false);
  const chipsContainerRef = useRef<HTMLDivElement>(null);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const isSecondFilterModeRef = useRef(false);
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  
  const getParentIds = (items: TDashboardUnitFilter[], targetId: string): string[] => {
    const result: string[] = [];
    
    const findParentRecursive = (allItems: TDashboardUnitFilter[], childId: string, path: string[] = []): string[] | null => {
      for (const item of allItems) {
        if (item.children?.some(child => child.id === childId)) {
          return [...path, item.id];
        }
        
        if (item.children) {
          const foundPath = findParentRecursive(item.children, childId, [...path, item.id]);
          if (foundPath) {
            return foundPath;
          }
        }
      }
      
      return null;
    };
    
    const path = findParentRecursive(items, targetId, []);
    return path || [];
  };
  
  const calculatedIsFirstFilter = isFirstFilter !== undefined 
    ? isFirstFilter 
    : initialSelection.length === 0;

  const findMatches = (items: any[]): { id: string; label: string }[] => {
    const matches: { id: string; label: string }[] = [];
    
    const search = (item: any) => {
      if (item.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        matches.push({ id: item.id, label: item.label });
      }
      if (item.children) {
        item.children.forEach(search);
      }
    };
    
    items.forEach(search);
    return matches;
  };

  useEffect(() => {
    if (searchTerm) {
      const foundMatches = findMatches(units);
      setMatches(foundMatches);
      if (foundMatches.length > 0) {
        setCurrentMatchId(foundMatches[0].id);
      } else {
        setCurrentMatchId('');
      }
    } else {
      setMatches([]);
      setCurrentMatchId('');
    }
  }, [searchTerm, units]);

  const handleNext = () => {
    if (matches.length === 0) return;
    
    const currentIndex = matches.findIndex(match => match.id === currentMatchId);
    const nextIndex = currentIndex < matches.length - 1 ? currentIndex + 1 : 0;
    const nextMatchId = matches[nextIndex].id;
    
    setCurrentMatchId(nextMatchId);
    
    if (isSingleSelect) {
      if (filterLevel >= 3 && thirdOnwardFilterRef.current?.scrollToMatch) {
        thirdOnwardFilterRef.current.scrollToMatch(nextMatchId);
      } else if (filterLevel === 2 && secondFilterRef.current?.scrollToMatch) {
        secondFilterRef.current.scrollToMatch(nextMatchId);
      } else if (singleTreeSelectRef.current?.scrollToMatch) {
        singleTreeSelectRef.current.scrollToMatch(nextMatchId);
      }
    } else if (treeSelectRef.current?.scrollToMatch) {
      treeSelectRef.current.scrollToMatch(nextMatchId);
    }
  };

  const handlePrev = () => {
    if (matches.length === 0) return;
    
    const currentIndex = matches.findIndex(match => match.id === currentMatchId);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : matches.length - 1;
    const prevMatchId = matches[prevIndex].id;
    
    setCurrentMatchId(prevMatchId);
    
    if (isSingleSelect) {
      if (filterLevel >= 3 && thirdOnwardFilterRef.current?.scrollToMatch) {
        thirdOnwardFilterRef.current.scrollToMatch(prevMatchId);
      } else if (filterLevel === 2 && secondFilterRef.current?.scrollToMatch) {
        secondFilterRef.current.scrollToMatch(prevMatchId);
      } else if (singleTreeSelectRef.current?.scrollToMatch) {
        singleTreeSelectRef.current.scrollToMatch(prevMatchId);
      }
    } else if (treeSelectRef.current?.scrollToMatch) {
      treeSelectRef.current.scrollToMatch(prevMatchId);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setCurrentMatchIndex(0);
  };

  useEffect(() => {
    const areArraysEqual = (a: string[], b: string[]) => 
      a.length === b.length && 
      [...a].sort().join(',') === [...b].sort().join(',');
    
    const isSecondFilter = isSecondFilterModeRef.current;
    
    if (isSecondFilter) {
      const parentUnitId = initialSelection[0];
      const hasOtherSelections = selectedItems.some(id => id !== parentUnitId);
      
      setIsDisabled(!hasOtherSelections);
    } else {
      setIsDisabled(areArraysEqual(selectedItems, initialSelection));
    }
  }, [selectedItems, initialSelection, isEditMode]);

  const findUnitById = (unitsList: TDashboardUnitFilter[], id: string | number): TDashboardUnitFilter | null => {
    id = id.toString();
    for (const unit of unitsList) {
      if (unit.id.toString() === id) {
        return unit;
      }
      if (unit.children) {
        const found = findUnitById(unit.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const initialSelected: string[] = [];
    const extractSelected = (items: TDashboardUnitFilter[]) => {
      items.forEach(item => {
        if (item.selected) {
          initialSelected.push(item.id);
        }
        if (item.children) {
          extractSelected(item.children);
        }
      });
    };
    
    extractSelected(units);
    const calculatedIsFirstFilter = isFirstFilter !== undefined 
      ? isFirstFilter 
      : previousSelections.length === 0;
      
    const isSecondFilter = !calculatedIsFirstFilter && filterLevel === 2;
    const isThirdOrHigherFilter = filterLevel >= 3;
    
    isSecondFilterModeRef.current = isSecondFilter;
    
    let finalInitialSelection: string[] = [];
    
    if (isThirdOrHigherFilter) {
      finalInitialSelection = [...previousSelections];
      initialSelected.forEach(id => {
        if (!finalInitialSelection.includes(id)) {
          finalInitialSelection.push(id);
        }
      });
    } else if (isSecondFilter && isEditMode && initialSelected.length > 1) {
      if (previousSelections.length > 0) {
        const parentUnitId = previousSelections[0];
        if (!initialSelected.includes(parentUnitId)) {
          finalInitialSelection = [parentUnitId, ...initialSelected];
        } else {
          finalInitialSelection = [...initialSelected];
        }
      } else {
        finalInitialSelection = [...initialSelected];
      }
    } else if (isSingleSelect && initialSelected.length > 0 && !isSecondFilter) {
      finalInitialSelection = [initialSelected[0]];
    } else {
      finalInitialSelection = [...initialSelected];
    }
    
    setInitialSelection(finalInitialSelection);
    setSelectedItems(finalInitialSelection);
    
    const newExpandedItems = new Set<string>();
    
    units.forEach(unit => {
      if (unit.children && unit.children.length > 0) {
        newExpandedItems.add(unit.id.toString());
      }
    });
    
    const allSelections = [...previousSelections, ...finalInitialSelection];
    
    allSelections.forEach(id => {
      const item = findUnitById(units, id);
      if (item && item.children && item.children.length > 0) {
        newExpandedItems.add(id.toString());
      }
      
      const parentIds = getParentIds(units, id);
      parentIds.forEach(parentId => {
        newExpandedItems.add(parentId);
      });
    });
    
    if (filterLevel >= 2 && previousSelections.length > 0) {
      previousSelections.forEach(id => {
        const unit = findUnitById(units, id);
        if (unit && unit.children) {
          unit.children.forEach(child => {
            if (child.children && child.children.length > 0) {
              newExpandedItems.add(child.id.toString());
            }
          });
        }
      });
    }
    
    setExpandedItems(newExpandedItems);
    
  }, [units, isSingleSelect, isFirstFilter, previousSelections, filterLevel, isEditMode]);

  const handleTreeSelect = (items: string[]) => {
    const processSelection = (allItems: TDashboardUnitFilter[]): string[] => {
      let processedIds: string[] = [];
      
      const getAllChildrenIds = (item: TDashboardUnitFilter): string[] => {
        let childIds = [item.id];
        if (item.children) {
          item.children.forEach(child => {
            childIds = [...childIds, ...getAllChildrenIds(child)];
          });
        }
        return childIds;
      };

      allItems.forEach(item => {
        if (items.includes(item.id)) {
          getAllChildrenIds(item).forEach(id => {
            if (!processedIds.includes(id)) {
              processedIds.push(id);
            }
          });
        } else if (item.children) {
          processedIds = [...processedIds, ...processSelection(item.children)];
        }
      });

      return processedIds;
    };

    const processedItems = processSelection(units);
    setSelectedItems(processedItems);
  };

  const handleSingleTreeSelect = (items: string[]) => {
    const isSecondFilter = isSecondFilterModeRef.current;

    if (isSecondFilter) {
      const previouslySelectedUnit = initialSelection[0];
      
      if (selectedItems.length > 1 && 
          selectedItems.includes(previouslySelectedUnit) && 
          items.length === 1 && 
          items[0] === previouslySelectedUnit) {
        return;
      }
      
      if (!items.includes(previouslySelectedUnit)) {
        items = [...items, previouslySelectedUnit];
      }
      
      units.forEach(unit => {
        if (unit.id !== previouslySelectedUnit) {
          unit.selected = false;
        } else {
          unit.selected = true;
        }
        
        if (items.includes(unit.id)) {
          unit.selected = true;
        }
        
        if (unit.children) {
          unit.children.forEach(child => {
            child.selected = items.includes(child.id);
            
            if (child.children) {
              child.children.forEach(grandchild => {
                grandchild.selected = items.includes(grandchild.id);
              });
            }
          });
        }
      });
      
      updateUnitSelections(units, items);
      
      setSelectedItems(items);
      
      if (items.length > initialSelection.length) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      
      return;
    }
    
    const selectedUnits = items.length > 0 ? [items[0]] : [];
    
    updateUnitSelections(units, selectedUnits);
    
    setSelectedItems(selectedUnits);
  };

  const handleThirdOnwardSelect = (items: string[]) => {
    if (items.length === 0) {
      return;
    }

    const itemsAsStrings = items.map(id => id.toString());
    const previousAsStrings = previousSelections.map(id => id.toString());
    
    let updatedItems: string[] = [];

    if (filterLevel >= 3 && previousSelections.length > 0) {
      const newlySelectedItems = itemsAsStrings.filter(id => !previousAsStrings.includes(id));
      
      updatedItems = [...previousAsStrings, ...newlySelectedItems];
    } else {
      updatedItems = [...itemsAsStrings];
    }
    
    updateUnitSelections(units, updatedItems);
    setSelectedItems(updatedItems);
    
    if (updatedItems.length > initialSelection.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleSecondFilterSelect = (items: string[]) => {
    if (items.length === 0) {
      return;
    }

    const itemsAsStrings = items.map(id => id.toString());
    const previousAsStrings = previousSelections.map(id => id.toString());
    
    let updatedItems: string[] = [];

    if (previousSelections.length > 0) {
      updatedItems = [...previousAsStrings];
      
      itemsAsStrings.forEach(id => {
        if (!previousAsStrings.includes(id)) {
          updatedItems.push(id);
        }
      });
    } else {
      updatedItems = [...itemsAsStrings];
    }
    
    updateUnitSelections(units, updatedItems);
    setSelectedItems(updatedItems);
    
    if (updatedItems.length > initialSelection.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const updateUnitSelections = (allUnits: TDashboardUnitFilter[], selectedIds: string[]) => {
    const clearAllSelections = (units: TDashboardUnitFilter[]) => {
      units.forEach(unit => {
        unit.selected = false;
        if (unit.children) {
          clearAllSelections(unit.children);
        }
      });
    };
    
    const markSelectedUnits = (units: TDashboardUnitFilter[], targetIds: string[]) => {
      units.forEach(unit => {
        if (targetIds.includes(unit.id)) {
          unit.selected = true;
        }
        
        if (unit.children) {
          markSelectedUnits(unit.children, targetIds);
        }
      });
    };
    
    clearAllSelections(allUnits);
    markSelectedUnits(allUnits, selectedIds);
  };

  const handleSelect = () => {
    // Function to check if an item is a direct members unit
    const isDirectMembersItem = (id: string): boolean => {
      const idLower = id.toString().toLowerCase();
      return (
        idLower.includes("direct_members") ||
        idLower.includes("direct-members") ||
        idLower.includes("-direct") ||
        idLower.includes("_direct")
      );
    };
    
    // Ensure we have string IDs for consistency
    const selectedIdsStr = selectedItems.map(id => id.toString());
    
    // Filter out direct members from the final selection
    const filteredSelectedIds = selectedIdsStr.filter(id => !isDirectMembersItem(id));
    
    // Update the unit selection flags in the data model
    const updatedUnits = units.map(unit => {
      const updateUnitFlags = (item: TDashboardUnitFilter): TDashboardUnitFilter => {
        const newItem = {
          ...item,
          selected: selectedIdsStr.includes(item.id.toString())
        };
        
        if (item.children) {
          newItem.children = item.children.map(updateUnitFlags);
        }
        
        return newItem;
      };
      
      return updateUnitFlags(unit);
    });

    // Notify parent component with filtered selected items
    onSelect(filteredSelectedIds);
    onClose();
  };

  useEffect(() => {
    // Debug the currently selected items
    if (selectedItems.length > 0) {
      const selectedUnits = findSelectedUnits(units, selectedItems);
      console.log('Currently selected units:', selectedUnits.map(u => `${u.label} (ID: ${u.id})`));
    }
  }, [selectedItems, units]);

  const handleRemoveItem = (idToRemove: string) => {
    const updatedItems = selectedItems.filter(id => id !== idToRemove);
    setSelectedItems(updatedItems);
    
    const isSecondFilter = isSecondFilterModeRef.current;
    if (isSecondFilter && updatedItems.length <= 1) {
      onClose();
    }
  };

  const handleClearAll = () => {
    setSelectedItems([]);
  };

  const toggleChipsContainer = () => {
    setIsChipsExpanded(!isChipsExpanded);
  };

  useEffect(() => {
    const container = chipsContainerRef.current;
    if (container) {
      setShowExpandButton(container.scrollHeight > container.clientHeight);
    }
  }, [selectedItems]);

  const findSelectedUnits = (units: TDashboardUnitFilter[], selectedIds: string[]): TDashboardUnitFilter[] => {
    // Ensure we're working with string IDs for comparison
    const selectedIdsStr = selectedIds.map(id => id.toString());
    let result: TDashboardUnitFilter[] = [];
    
    // Function to check if an item is a direct members unit
    const isDirectMembersItem = (item: TDashboardUnitFilter): boolean => {
      const label = item.label?.toLowerCase() || '';
      const id = item.id?.toString()?.toLowerCase() || '';
      
      return (
        // Check label patterns
        label.includes("direct members") ||
        label.includes("direct member") ||
        
        // Check ID patterns
        id.includes("direct_members") ||
        id.includes("direct-members") ||
        id.includes("-direct") ||
        id.includes("_direct") ||
        
        // Check for labels that end with direct members/member
        label.endsWith("direct members") ||
        label.endsWith("direct member")
      );
    };
    
    // Recursive function to search through all units and their children
    const searchUnit = (unit: TDashboardUnitFilter) => {
      // Check if this unit is selected AND is not a direct members unit
      if (selectedIdsStr.includes(unit.id.toString()) && !isDirectMembersItem(unit)) {
        const unitCopy = {
          ...unit,
          id: unit.id,
          label: unit.label
        };
        result.push(unitCopy);
      }
      
      // Search through children if they exist
      if (unit.children && Array.isArray(unit.children) && unit.children.length > 0) {
        unit.children.forEach(searchUnit);
      }
    };
    
    // Start the search from each top-level unit
    units.forEach(searchUnit);
    
    console.log('Selected units found:', result.length, result.map(u => u.label));
    
    return result;
  };

  useEffect(() => {
    const simplifiedUnits = units.map(unit => ({
      id: unit.id,
      label: unit.label,
      childrenCount: unit.children?.length || 0
    }));
    
    if (isSingleSelect && units.length > 0) {
      const allUnitIds = new Set<string>();
      
      const collectIds = (items: TDashboardUnitFilter[]) => {
        items.forEach(item => {
          allUnitIds.add(item.id);
          if (item.children) {
            collectIds(item.children);
          }
        });
      };
      
      collectIds(units);
    }
  }, [units, isSingleSelect, initialSelection, selectedItems]);

  useEffect(() => {
    if (singleTreeSelectRef.current?.updateInternalSelection) {
      singleTreeSelectRef.current.updateInternalSelection([...selectedItems]);
    }
    
    if (thirdOnwardFilterRef.current?.updateInternalSelection) {
      thirdOnwardFilterRef.current.updateInternalSelection([...selectedItems]);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (!open) return;
    
    const newExpandedItems = new Set<string>();
    
    const expandAllWithChildren = (items: TDashboardUnitFilter[]) => {
      items.forEach(item => {
        if (item.children && item.children.length > 0) {
          newExpandedItems.add(item.id.toString());
          expandAllWithChildren(item.children);
        }
      });
    };
    
    expandAllWithChildren(units);
    
    setExpandedItems(newExpandedItems);
    
    setTimeout(() => {
      if (filterLevel >= 3 && thirdOnwardFilterRef.current?.resetComponent) {
        thirdOnwardFilterRef.current.resetComponent();
      } else if (filterLevel === 2 && secondFilterRef.current?.resetComponent) {
        secondFilterRef.current.resetComponent();
      } else if (singleTreeSelectRef.current?.resetComponent) {
        singleTreeSelectRef.current.resetComponent();
      }
    }, 100);
    
  }, [open, units, filterLevel]);

  const getTreeContent = () => {
    if (!units || units.length === 0) {
      return <EmptyState>No units available</EmptyState>;
    }
    
    if (isSingleSelect) {
      if (filterLevel >= 3) {
        return (
          <SingleTreeThirdOnwardFilter
            ref={thirdOnwardFilterRef}
            items={units}
            onSelect={handleThirdOnwardSelect}
            searchTerm={searchTerm}
            currentMatchIndex={currentMatchIndex}
            hideUnselectedDisabledItems={true}
            value={selectedItems}
            previousSelections={previousSelections}
            filterLevel={filterLevel}
          />
        );
      } else if (filterLevel === 2) {
        return (
          <SingleTreeSecondFilter
            ref={secondFilterRef}
            items={units}
            onSelect={handleSecondFilterSelect}
            searchTerm={searchTerm}
            currentMatchIndex={currentMatchIndex}
            hideUnselectedDisabledItems={true}
            value={selectedItems}
            previousSelections={previousSelections}
            filterLevel={2}
          />
        );
      }
      
      return (
        <SingleTreeSelect
          ref={singleTreeSelectRef}
          items={units}
          onSelect={handleSingleTreeSelect}
          searchTerm={searchTerm}
          currentMatchIndex={currentMatchIndex}
          hideUnselectedDisabledItems={true}
          value={selectedItems}
          previousSelections={initialSelection}
          mode={isEditMode ? 'edit' : 'initial'}
          isFirstFilter={calculatedIsFirstFilter}
        />
      );
    }
    
    return (
      <TreeSelect
        ref={treeSelectRef}
        items={units}
        onSelect={handleTreeSelect}
        searchTerm={searchTerm}
        currentMatchIndex={currentMatchIndex}
        isSingleSelect={false}
      />
    );
  };

  const fixedItemIdValue = !calculatedIsFirstFilter && previousSelections.length > 0 ? previousSelections[0] : undefined;

  const handleClose = () => {
    if (filterLevel >= 3 && thirdOnwardFilterRef.current?.resetComponent) {
      thirdOnwardFilterRef.current.resetComponent();
    } else if (filterLevel === 2 && secondFilterRef.current?.resetComponent) {
      secondFilterRef.current.resetComponent();
    } else if (singleTreeSelectRef.current?.resetComponent) {
      singleTreeSelectRef.current.resetComponent();
    }
    
    setSelectedItems(initialSelection);
    
    onClose();
  };

  return (
    <StyledDialog 
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '8px',
          maxHeight: '90vh',
        },
      }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'transparent' }
        }
      }}
    >
      <HeaderContainer>
        <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          padding: '24px 0 0 0', 
          backgroundColor: 'var(--colorNeutralBackground1)'
        }}>
          <CloseBtnWrapper>
            <CloseButton onClick={handleClose}>
              <ArrowBackIcon />
            </CloseButton>
            <TextTitle style={{ marginRight: '4px' }}>Back to filters </TextTitle>
            <TextSubTitle>(Your changes will be discarded)</TextSubTitle>
          </CloseBtnWrapper>
          
          <ChipsUnitWidget 
            selectedItems={findSelectedUnits(units, selectedItems)}
            onRemoveItem={handleRemoveItem}
            onClearAll={handleClearAll}
            fixedItemId={fixedItemIdValue}
            hideRemoveButton={false}
          />

          <SearchContainer>
            <StyledDialogTitle>Select units</StyledDialogTitle>
            <SearchWrapper>
              <SearchInputWrapper>
                <SearchInput 
                  type="text" 
                  placeholder="Search for unit"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
              </SearchInputWrapper>
            </SearchWrapper>
          </SearchContainer>
        </div>
      </HeaderContainer>

      <ContentContainer>
        <Divider style={{margin: '0 24px'}}/>

        {searchTerm && (
          <SearchNavigation>
            <div style={{ width: '75%' }}>
              <MatchCount>
                {matches.length} results found for "{searchTerm}"
              </MatchCount>
              <NavClearButton onClick={handleClearSearch}>
                <CloseIconWrapper>
                  <CloseIcon color="primary"/>
                  <CloseText>Clear Search</CloseText>
                </CloseIconWrapper>
              </NavClearButton>
            </div>

            <NavButtons style={{ width: '25%' }}>
              <NavButton onClick={handlePrev}>
                <ArrowUpward />
              </NavButton>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                <TextCounter>
                  {matches.length > 0 ? 
                    `${matches.findIndex(match => match.id === currentMatchId) + 1} of ${matches.length}` : 
                    '0 of 0'}
                </TextCounter>
              </Box>
              <NavButton onClick={handleNext}>
                <ArrowDownward />
              </NavButton>
            </NavButtons>
          </SearchNavigation>
        )}

        <TreeSelectContainer>
          {units.length === 0 ? (
            <EmptyState>No units available</EmptyState>
          ) : isSingleSelect ? (
            filterLevel >= 3 ? (
              <SingleTreeThirdOnwardFilter
                ref={thirdOnwardFilterRef}
                items={units}
                onSelect={handleThirdOnwardSelect}
                searchTerm={searchTerm}
                currentMatchIndex={currentMatchIndex}
                hideUnselectedDisabledItems={true}
                value={selectedItems}
                previousSelections={previousSelections}
                filterLevel={filterLevel}
              />
            ) : filterLevel === 2 ? (
              <SingleTreeSecondFilter
                ref={secondFilterRef}
                items={units}
                onSelect={handleSecondFilterSelect}
                searchTerm={searchTerm}
                currentMatchIndex={currentMatchIndex}
                hideUnselectedDisabledItems={true}
                value={selectedItems}
                previousSelections={previousSelections}
                filterLevel={2}
              />
            ) : (
              <SingleTreeSelect
                ref={singleTreeSelectRef}
                items={units}
                onSelect={handleSingleTreeSelect}
                searchTerm={searchTerm}
                currentMatchIndex={currentMatchIndex}
                hideUnselectedDisabledItems={true}
                value={selectedItems}
                previousSelections={initialSelection}
                mode={isEditMode ? 'edit' : 'initial'}
                isFirstFilter={calculatedIsFirstFilter}
              />
            )
          ) : (
            <TreeSelect
              ref={treeSelectRef}
              items={units}
              onSelect={handleTreeSelect}
              searchTerm={searchTerm}
              currentMatchIndex={currentMatchIndex}
              isSingleSelect={false}
            />
          )}
        </TreeSelectContainer>
      </ContentContainer>

      <FooterContainer>
        <ButtonWrapper>
          <SelectButton 
            onClick={handleSelect}
          >
            Select units
          </SelectButton>
        </ButtonWrapper>
      </FooterContainer>
    </StyledDialog>
  );
};

export default UnitSelectionPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
    min-height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 12px 24px;
  background-color: var(--colorNeutralBackground4);
  z-index: 1100;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 300px;
  flex: 0 0 auto;
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  padding-right: 40px;
  border: 1px solid var(--colorNeutralStroke1);
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  &::placeholder {
    color: var(--colorNeutralForeground2);
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--colorNeutralForeground2);
  display: flex;
  align-items: center;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 12px 20px 0 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: var(--colorBrandForeground3);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: white;
  }

  &:hover {
    background-color: var(--colorBrandForeground3);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: var(--colorBrandForeground3);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 24px 12px 24px;
`;

const SelectButton = styled(CustomButton)`
  width: 100%;
  height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  color: ${props => props.disabled ? 'var(--colorTextAllButton)' : 'var(--colorNeutralBackground1)'} !important;
  background-color: ${props => props.disabled ? 'var(--colorPaletteGrayBackground1)' : 'var(--colorBrandForeground1)'} !important;
  text-decoration: none !important;

  &:hover {
    background-color: ${props => props.disabled ? 
      'var(--colorPaletteGrayBackground1)' : 
      'var(--colorBrandForeground1)'} !important;
    text-decoration: none !important;
  }
`; 

const TextTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 12px;
  color: var(--colorNeutralForeground3);
`;

const TextCounter = styled(TextTitle)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
  padding-left: 0;
  padding-right: 0;
`;

const TextSubTitle = styled(TextTitle)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 0;
  color: var(--colorNeutralForeground5);
`;

const CloseText = styled(TextTitle)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 0;
  color: var(--colorBrandForeground1);
  padding-left: 4px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: var(--colorNeutralBackground4);
  margin-bottom: 0;
  z-index: 1200;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--colorNeutralStroke1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(80vh - 180px);
  background-color: var(--colorNeutralBackground4);
  margin-top: 0;
  z-index: 1000;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--colorNeutralBackground1);
  margin-top: auto;
  
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
  }
`;

const StyledDialogTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-right: 12px;
  color: var(--colorNeutralForeground3);
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SearchNavigation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  background-color: var(--colorSearchResultBackground);
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px 24px;
  position: sticky;
  top: 0;
  z-index: 1150;
`;

const MatchCount = styled.span`
  font-size: 12px;
  color: var(--colorNeutralForeground2);
`;

const NavButtons = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
`;

const NavButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px;
  color: var(--colorBrandForeground3);
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  display: flex;
  border: 1px solid var(--colorBrandForeground3);

  &:active {
    background-color: var(--colorNeutralBackground4);
  }

  svg {
    font-size: 20px;
  }
`;

const NavClearButton = styled.div`
  background-color: var(--colorSearchResultBackground);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TreeSelectContainer = styled.div`
  flex: 1;
  padding: 24px 24px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1000;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: var(--colorNeutralForeground3);
  font-family: Roboto;
  font-size: 16px;
`;