import { FC, useEffect, useState } from "react";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import QuestionsList from "./QuestionsList";
import DimensionsList from "./DimensionsList";
import ConditionalLogicPopup from "./ConditionalLogicPopup";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import ErrorPage from "components/errors/ErrorPage";

type ScoredQuestionsTabProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const ScoredQuestionsTab: FC<ScoredQuestionsTabProps> = (props) => {
  const [hasQuestions, setHasQuestions] = useState(false);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "responses" &&
      topStore.responsesActiveLocalTab === "scored_questions"
    ) {
      store.getDashboardResponsesScoredDimensions(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [
    topStore?.selectedSurvey?.value,
    topStore.activeTab,
    topStore.role,
    topStore.overviewBenchmark,
    topStore.filterUnitList,
    props.localFilters,
  ]);

  useEffect(() => {
    setHasQuestions(store.scoredQuestionsList && store.scoredQuestionsList.length > 0);
  }, [store.scoredQuestionsList]);

  return (
    <>
      {topStore.isDashboardDataError ? (
        <ErrorPage />
      ) : (
        <Container>
          {hasQuestions && <DimensionsList />}
          <Content hasQuestions={hasQuestions}>
            <QuestionsList localFilters={props.localFilters} />
          </Content>
          <ConditionalLogicPopup />
        </Container>
      )}
    </>
  );
};

export default ScoredQuestionsTab;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralForegroundInverted2);
  padding: 24px 0px;
`;

const Content = styled.div<{ hasQuestions: boolean }>`
  width: ${({ hasQuestions }) => (hasQuestions ? 'calc(100% - 191px)' : '100%')};
`;
