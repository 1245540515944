import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { ExpandLess, ExpandMore, CheckCircle } from "@mui/icons-material";
import styled from "styled-components";
import { TDashboardUnitFilter } from "types/dashboard";
import { Radio } from "@mui/material";
import store from "features/Dashboard/hotspots/store";

interface ExtendedDashboardUnitFilter extends TDashboardUnitFilter {
  parent_id?: string | number;
}

interface SingleTreeSecondFilterProps {
  items: TDashboardUnitFilter[];
  onSelect: (selectedIds: string[]) => void;
  searchTerm?: string;
  currentMatchIndex?: number;
  hideUnselectedDisabledItems?: boolean;
  value?: string[];
  previousSelections?: string[];
  filterLevel?: number;
}

interface MatchInfo {
  id: string;
  label: string;
}

const getInitialSelectedItems = (items: TDashboardUnitFilter[], value?: string[]): string[] => {
  if (!value || value.length === 0) {
    return [];
  }

  const selectedIds: string[] = [];

  const findItemsById = (allItems: TDashboardUnitFilter[], ids: string[]) => {
    for (const item of allItems) {
      if (ids.includes(item.id)) {
        selectedIds.push(item.id);
      }
      if (item.children) {
        findItemsById(item.children, ids);
      }
    }
  };

  findItemsById(items, value);
  return selectedIds;
};

const isDescendantOf = (
  items: TDashboardUnitFilter[],
  itemId: string,
  potentialAncestorId: string,
): boolean => {
  // Ensure string comparison for IDs
  const itemIdStr = itemId.toString();
  const ancestorIdStr = potentialAncestorId.toString();

  const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id.toString() === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const item = findItem(items, itemIdStr);
  if (!item) return false;

  let currentItem = item;
  const findParent = (
    items: TDashboardUnitFilter[],
    childId: string,
  ): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some((child) => child.id.toString() === childId)) {
        return item;
      }
      if (item.children) {
        const found = findParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  while (true) {
    const parent = findParent(items, currentItem.id.toString());
    if (!parent) break;
    if (parent.id.toString() === ancestorIdStr) return true;
    currentItem = parent;
  }

  return false;
};

export const SingleTreeSecondFilter = forwardRef<
  {
    scrollToMatch: (itemId: string) => void;
    clearSelection: () => void;
    handleSelect: (id: string) => void;
    updateInternalSelection: (selections: string[]) => void;
    resetComponent: () => void;
  },
  SingleTreeSecondFilterProps
>(
  (
    {
      items,
      onSelect,
      searchTerm,
      currentMatchIndex = 0,
      hideUnselectedDisabledItems = false,
      value,
      previousSelections = [],
      filterLevel = 2,
    },
    ref,
  ) => {
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [selectedItems, setSelectedItems] = useState<Set<string>>(() => {
      const initialSelections = new Set<string>();
      if (previousSelections && previousSelections.length > 0) {
        previousSelections.forEach((id) => initialSelections.add(id.toString()));
      }
      if (value && value.length > 0) {
        value.forEach((id) => initialSelections.add(id.toString()));
      }
      return initialSelections;
    });
    const [matches, setMatches] = useState<MatchInfo[]>([]);
    const [currentMatchId, setCurrentMatchId] = useState<string>("");
    const [renderKey, setRenderKey] = useState<number>(0);
    const itemRefs = useRef<Map<string, HTMLElement>>(new Map());
    const [previousUnitId, setPreviousUnitId] = useState<string | null>(null);
    const [previouslySelectedItems, setPreviouslySelectedItems] = useState<Set<string>>(
      new Set(previousSelections),
    );
    const [processedItems, setProcessedItems] = useState<TDashboardUnitFilter[]>([]);

    const [isSelectionMode, setIsSelectionMode] = useState<boolean>(
      previousSelections.length === 0,
    );

    const itemLevelMap = useRef<Map<string, number>>(new Map());
    const isFirstRenderRef = useRef(true);
    const selectedItemsRef = useRef<string[]>([]);

    useEffect(() => {
      const treeItems = ensureTreeStructure(items);
      setProcessedItems(treeItems);
      console.log("FILTER LEVEL PARAM:", filterLevel);
      
      // Log whether we're in edit mode or initialization mode
      const isEditMode = selectedItems.size > 0 || previousSelections.length > 0;
      console.log(`COMPONENT MODE: ${isEditMode ? 'EDIT MODE' : 'INITIALIZATION MODE'}`);
      console.log(`Previous selections: ${previousSelections.length}, Current selections: ${selectedItems.size}`);
    }, [items]);

    useEffect(() => {
      selectedItemsRef.current = Array.from(selectedItems).map((id) => id.toString());
    }, [selectedItems]);

    useEffect(() => {
      const newSelectedItems = new Set<string>();

      if (previousSelections && previousSelections.length > 0) {
        previousSelections.forEach((id) => newSelectedItems.add(id.toString()));
      }

      if (value && value.length > 0) {
        value.forEach((id) => newSelectedItems.add(id.toString()));
      }

      selectedItemsRef.current.forEach((id) => {
        if (!newSelectedItems.has(id)) {
          newSelectedItems.add(id);
        }
      });

      if (
        JSON.stringify(Array.from(newSelectedItems).sort()) !==
        JSON.stringify(Array.from(selectedItems).sort())
      ) {
        setSelectedItems(newSelectedItems);
      }
    }, [value, previousSelections]);

    // Build level map
    useEffect(() => {
      itemLevelMap.current.clear();

      const buildLevelMap = (itemsList: TDashboardUnitFilter[], level = 0) => {
        itemsList.forEach((item) => {
          // First try to get level from the item itself
          const extendedItem = item as ExtendedDashboardUnitFilter;
          const itemLevel = extendedItem.level !== undefined 
            ? Number(extendedItem.level) 
            : level;
          
          // Store the level in our map
          itemLevelMap.current.set(item.id.toString(), itemLevel);

          // Process children with incremented level
          if (item.children && item.children.length > 0) {
            buildLevelMap(item.children, itemLevel + 1);
          }
        });
      };

      buildLevelMap(processedItems);
      
      // Debugging - log the item levels for verification
      if (processedItems.length > 0) {
        console.log('Item levels built successfully:', 
          processedItems.map(item => `${item.label}: Level ${itemLevelMap.current.get(item.id)}`));
      }
    }, [processedItems]);

    useEffect(() => {
      const currentPrevSelected = Array.from(previouslySelectedItems);
      const newPrevSelected = Array.from(new Set(previousSelections));

      const prevSelectionsChanged =
        JSON.stringify(currentPrevSelected.sort()) !== JSON.stringify(newPrevSelected.sort());

      if (prevSelectionsChanged) {
        setPreviouslySelectedItems(new Set(previousSelections));
      }

      if (previousSelections.length > 0) {
        if (isSelectionMode) {
          setIsSelectionMode(false);
        }
      }
    }, [previousSelections, isSelectionMode, previouslySelectedItems]);

    useEffect(() => {
      const newExpandedItems = new Set<string>();

      const expandAllItemsWithChildren = (itemsList: TDashboardUnitFilter[]) => {
        itemsList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            newExpandedItems.add(item.id.toString());
            expandAllItemsWithChildren(item.children);
          }
        });
      };

      const expandPathToSelectedItems = (selectedIds: string[]) => {
        selectedIds.forEach((id) => {
          const parentIds = getParentIds(processedItems, id.toString());
          parentIds.forEach((parentId) => {
            newExpandedItems.add(parentId);
          });

          const item = findItem(processedItems, id);
          if (item && item.children && item.children.length > 0) {
            newExpandedItems.add(id.toString());
            
            // Always auto-expand the children of selected items for better UX
            if (previousSelections.includes(id) || (value && value.includes(id))) {
              item.children.forEach(child => {
                if (child.children && child.children.length > 0) {
                  newExpandedItems.add(child.id.toString());
                }
              });
            }
          }
        });
      };

      // In edit mode, only expand relevant parts of the tree
      const isEditMode = selectedItems.size > 0 || previousSelections.length > 0;
      
      // Check if there's a selection in the second filter
      const hasSecondFilterSelection = Array.from(selectedItems).some(id => !previousSelections.includes(id));
      
      if (isEditMode && hasSecondFilterSelection) {
        // In edit mode with second filter selection, be more selective about what's expanded
        // First expand ancestors of all selected items
        expandPathToSelectedItems([...previousSelections, ...Array.from(selectedItems)]);
        
        // Get all selected ids from the second filter only
        const secondFilterSelections = Array.from(selectedItems)
          .filter(id => !previousSelections.includes(id));
        
        // For each second filter selection, also expand its immediate children
        secondFilterSelections.forEach(id => {
          const item = findItem(processedItems, id);
          if (item && item.children && item.children.length > 0) {
            item.children.forEach(child => {
              newExpandedItems.add(child.id.toString());
            });
          }
        });
      } else {
        // Not in edit mode or no second filter selection yet
        expandAllItemsWithChildren(processedItems);

        if (previousSelections.length > 0) {
          expandPathToSelectedItems(previousSelections);
        }

        if (value) {
          const valueArr = Array.isArray(value) ? value : [value];
          expandPathToSelectedItems(valueArr);
        }
      }

      setExpandedItems(newExpandedItems);
    }, [processedItems, previousSelections, value, selectedItems]);

    const ensureTreeStructure = (inputItems: TDashboardUnitFilter[]): TDashboardUnitFilter[] => {
      console.log("RAW INPUT ITEMS:", JSON.stringify(inputItems));
      
      // If there are no items, return an empty array
      if (!inputItems || inputItems.length === 0) {
        return [];
      }
      
      // Create a map to track all items by ID for easy parent-child lookup
      const itemsById = new Map<string, TDashboardUnitFilter>();
      
      // First pass: add all items to the map
      inputItems.forEach(item => {
        // Ensure we're working with string IDs consistently
        const itemId = item.id.toString();
        itemsById.set(itemId, {
          ...item,
          id: itemId,
          children: item.children || []
        });
      });
      
      // Second pass: build parent-child relationships if they're not already set
      // This handles flat arrays that use parent_id references
      inputItems.forEach(item => {
        const itemId = item.id.toString();
        const extendedItem = item as ExtendedDashboardUnitFilter;
        
        // Skip if the item already has children defined
        if (item.children && item.children.length > 0) {
          return;
        }
        
        // If this item has a parent_id, find the parent and add this as a child
        if (extendedItem.parent_id) {
          const parentId = extendedItem.parent_id.toString();
          const parent = itemsById.get(parentId);
          
          if (parent) {
            // Initialize children array if it doesn't exist
            if (!parent.children) {
              parent.children = [];
            }
            
            // Add this item as a child of the parent if not already present
            const isAlreadyChild = parent.children.some(child => 
              child.id.toString() === itemId
            );
            
            if (!isAlreadyChild) {
              parent.children.push(item);
            }
          }
        }
      });
      
      // Final pass: return only root-level items (those without parents)
      // This converts a flat structure into a tree
      const rootItems = inputItems.filter(item => {
        const extendedItem = item as ExtendedDashboardUnitFilter;
        return !extendedItem.parent_id;
      });
      
      // If we found root items through parent_id filtering, return those
      if (rootItems.length > 0) {
        console.log("Found root items based on parent_id:", rootItems.map(i => i.label));
        return rootItems;
      }
      
      // Otherwise, return the original items which likely already have a tree structure
      console.log("Using original tree structure");
      return inputItems;
    };

    // Ensure previously selected items are marked as selected
    useEffect(() => {
      if (previousSelections.length > 0) {
        const newSelectedItems = new Set(selectedItems);
        previousSelections.forEach((id) => {
          newSelectedItems.add(id);
        });

        if (
          JSON.stringify(Array.from(newSelectedItems).sort()) !==
          JSON.stringify(Array.from(selectedItems).sort())
        ) {
          setSelectedItems(newSelectedItems);
        }
      }
    }, [previousSelections]); // Only dependency is previousSelections

    // Ensure all selected values are reflected in state
    useEffect(() => {
      if (value && previousSelections.length > 0) {
        const valueArray = Array.isArray(value) ? value : value ? [value] : [];
        const combinedSelections = new Set([...previousSelections, ...valueArray]);

        if (
          JSON.stringify(Array.from(combinedSelections).sort()) !==
          JSON.stringify(Array.from(selectedItems).sort())
        ) {
          setSelectedItems(combinedSelections);
        }
      }
    }, [value, previousSelections]);

    // Toggle expansion of an item
    const toggleExpand = (itemId: string, event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      // Convert to string for consistent comparison
      const itemIdStr = itemId.toString();

      const newExpanded = new Set(expandedItems);
      if (newExpanded.has(itemIdStr)) {
        newExpanded.delete(itemIdStr);
      } else {
        newExpanded.add(itemIdStr);
      }

      setExpandedItems(newExpanded);
    };

    // Handle item selection
    const handleSelect = (id: string) => {
      const item = findItem(processedItems, id);
      if (!item) {
        return;
      }

      const idStr = id.toString();

      // Create a new set for selected items
      let newSelected = new Set<string>();

      // Always maintain previous selections
      if (previousSelections.length > 0) {
        previousSelections.forEach((prevId) => {
          newSelected.add(prevId.toString());
        });
      }

      // Add the newly selected item
      newSelected.add(idStr);

      // Update the selected items
      setSelectedItems(newSelected);
      
      // Notify parent component of selection
      onSelect(Array.from(newSelected));

      // Keep reference to current selections
      selectedItemsRef.current = Array.from(newSelected).map((id) => id.toString());

      // Store selected unit in the dashboard store for context
      storeSelectedUnit(item);

      // Automatically expand relevant parts of the tree
      const newExpanded = new Set(expandedItems);
      let hasExpandChanges = false;

      const parentIds = getParentIds(processedItems, idStr);
      parentIds.forEach((parentId) => {
        if (!newExpanded.has(parentId)) {
          newExpanded.add(parentId);
          hasExpandChanges = true;
        }
      });

      // Always expand the selected item if it has children
      if (item.children && item.children.length > 0 && !newExpanded.has(idStr)) {
        newExpanded.add(idStr);
        hasExpandChanges = true;
      }

      if (hasExpandChanges) {
        setExpandedItems(newExpanded);
      }

      // Force refresh component with filtered view based on the new selection
      // This is needed to ensure proper filtering in edit mode
      setTimeout(() => {
        // Update the previous unit ID for context
        setPreviousUnitId(idStr);
        setIsSelectionMode(false);
        
        // Force re-evaluation of which items should be shown
        const processedCopy = [...processedItems];
        setProcessedItems([]);
        setTimeout(() => setProcessedItems(processedCopy), 0);
        
        setRenderKey((prevKey) => prevKey + 1);
      }, 0);
    };

    // Store selected unit in the dashboard store
    const storeSelectedUnit = (item: TDashboardUnitFilter) => {
      // Check if item has the necessary properties for a unit
      if (!item) return;

      // Get the item level from the map
      const level = itemLevelMap.current.get(item.id) || 0;

      // Get parent unit
      const parentUnit = getParentUnit(item);
      const parentId = parentUnit?.id;

      // Create unit data object with all available information
      const unitData = {
        id: item.id,
        name: item.label,
        level: level,
        parent_id: parentId,
        // Only include unitHeadEmails if it exists on the item
        ...(item.unitHeadEmails !== undefined && { unitHeadEmails: item.unitHeadEmails }),
      };

      // Store the unit data in the store
      if (store && typeof store.storeSelectedUnit === "function") {
        store.storeSelectedUnit(unitData);
      }
    };

    // Helper function to get the parent unit
    const getParentUnit = (item: TDashboardUnitFilter): TDashboardUnitFilter | null => {
      const parentIds = getParentIds(processedItems, item.id);
      if (parentIds.length === 0) return null;

      const parentId = parentIds[parentIds.length - 1];
      return findItem(processedItems, parentId);
    };

    const findItem = (
      itemsToSearch: TDashboardUnitFilter[],
      id: string,
    ): TDashboardUnitFilter | null => {
      const idToFind = id.toString();

      for (const item of itemsToSearch) {
        const itemId = item.id.toString();
        if (itemId === idToFind) {
          return item;
        }
        if (item.children) {
          const found = findItem(item.children, idToFind);
          if (found) return found;
        }
      }
      return null;
    };

    const getAllDescendantIds = (item: TDashboardUnitFilter): string[] => {
      const ids: string[] = [];
      if (item.children) {
        for (const child of item.children) {
          // Skip direct members when collecting descendant IDs
          if (isDirectMembersItem(child)) {
            continue;
          }
          ids.push(child.id);
          ids.push(...getAllDescendantIds(child));
        }
      }
      return ids;
    };

    const getParentIds = (
      itemsToSearch: TDashboardUnitFilter[],
      targetId: string,
      path: string[] = [],
    ): string[] => {
      const targetIdStr = targetId.toString();

      for (const item of itemsToSearch) {
        if (item.id.toString() === targetIdStr) {
          return path;
        }
        if (item.children) {
          const found = getParentIds(item.children, targetIdStr, [...path, item.id.toString()]);
          if (found.length) return found;
        }
      }
      return [];
    };

    const findAllMatches = (itemsToSearch: TDashboardUnitFilter[]): MatchInfo[] => {
      const matches: MatchInfo[] = [];

      const searchItems = (item: TDashboardUnitFilter) => {
        if (item.label.toLowerCase().includes(searchTerm?.toLowerCase() || "")) {
          matches.push({ id: item.id, label: item.label });
        }

        if (item.children) {
          item.children.forEach(searchItems);
        }
      };

      itemsToSearch.forEach(searchItems);
      return matches;
    };

    useEffect(() => {
      if (searchTerm) {
        const foundMatches = findAllMatches(processedItems);
        setMatches(foundMatches);

        if (foundMatches.length > 0) {
          const matchIndex = Math.min(currentMatchIndex, foundMatches.length) - 1;
          setCurrentMatchId(foundMatches[matchIndex]?.id || "");
        } else {
          setCurrentMatchId("");
        }

        const nodesToExpand = new Set<string>(expandedItems);
        let hasChanges = false;

        foundMatches.forEach((match) => {
          if (!nodesToExpand.has(match.id)) {
            nodesToExpand.add(match.id);
            hasChanges = true;
          }

          const parentIds = getParentIds(processedItems, match.id);
          parentIds.forEach((id) => {
            if (!nodesToExpand.has(id)) {
              nodesToExpand.add(id);
              hasChanges = true;
            }
          });
        });

        if (hasChanges) {
          setExpandedItems(nodesToExpand);
        }
      } else {
        setMatches([]);
        setCurrentMatchId("");
      }
    }, [searchTerm, processedItems, currentMatchIndex, expandedItems]);

    const renderHighlightedText = (text: string, itemId: string) => {
      if (!searchTerm) return text;

      const matchIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());
      if (matchIndex === -1) return text;

      const isCurrentMatch = itemId === currentMatchId;

      return (
        <span>
          {text.slice(0, matchIndex)}
          <HighlightedText
            $isCurrent={isCurrentMatch}
            ref={(el) => {
              if (el) {
                itemRefs.current.set(itemId, el);
              }
            }}
          >
            {text.slice(matchIndex, matchIndex + searchTerm.length)}
          </HighlightedText>
          {text.slice(matchIndex + searchTerm.length)}
        </span>
      );
    };

    const isDirectMembersItem = (item: TDashboardUnitFilter): boolean => {
      const label = item.label?.toLowerCase() || '';
      const id = item.id?.toString()?.toLowerCase() || '';
      
      return (
        // Check label patterns
        label.includes("direct members") ||
        label.includes("direct member") ||
        
        // Check ID patterns
        id.includes("direct_members") ||
        id.includes("direct-members") ||
        id.includes("-direct") ||
        id.includes("_direct") ||
        
        // Check for labels that end with direct members/member
        label.endsWith("direct members") ||
        label.endsWith("direct member")
      );
    };

    const getParentOfDirectMembers = (directMembersItem: TDashboardUnitFilter): string | null => {
      const extendedItem = directMembersItem as ExtendedDashboardUnitFilter;
      if (extendedItem.parent_id) {
        return extendedItem.parent_id.toString();
      }

      const itemId = directMembersItem.id.toString();

      if (itemId.includes("-direct")) {
        return itemId.split("-direct")[0];
      }

      if (itemId.includes("_direct")) {
        return itemId.split("_direct")[0];
      }

      const parentIds = getParentIds(processedItems, directMembersItem.id);
      return parentIds.length > 0 ? parentIds[parentIds.length - 1] : null;
    };

    // Check if an item is disabled
    const isItemDisabled = (itemId: string): boolean => {
      const item = findItem(processedItems, itemId);
      if (!item) return false;

      // Ensure string comparison for IDs
      const itemIdStr = item.id?.toString() || "";

      // Get the item's level
      const itemLevel = itemLevelMap.current.get(item.id) || 0;

      // Always disable previously selected items
      if (previousSelections.length > 0 && previousSelections.includes(itemIdStr)) {
        return true;
      }

      // If there are previous selections, check parent-child relationships
      if (previousSelections.length > 0) {
        const firstSelectionId = previousSelections[0].toString();
        const firstSelectionItem = findItem(processedItems, firstSelectionId);

        if (firstSelectionItem) {
          const firstSelectionLevel = itemLevelMap.current.get(firstSelectionId) || 0;
          
          // If the first selection is of level 1 (like A1, B1)
          if (firstSelectionLevel === 1) {
            // If current item is also level 1 but not the selected one, disable it
            if (itemLevel === 1 && itemIdStr !== firstSelectionId) {
              return true;
            }

            // If the item isn't a descendant of the first selection, disable it
            if (itemLevel > 1 && !isDescendantOf(processedItems, itemIdStr, firstSelectionId)) {
              return true;
            }
          }

          // Always check if current item is a parent of any selected item
          // This ensures parents are disabled regardless of the selected item's level
          const parentIds = getParentIds(processedItems, firstSelectionId);
          if (parentIds.includes(itemIdStr)) {
            return true;
          }
        }
      }

      return false;
    };

    // Check if an item should be shown
    const shouldShowItem = (item: TDashboardUnitFilter): boolean => {
      // Skip direct members items
      if (isDirectMembersItem(item)) {
        return false;
      }
      
      // Always show all items when there are no processed items
      // This ensures we don't end up with a blank screen
      if (!processedItems || processedItems.length === 0) {
        return true;
      }
      
      // Ensure string comparison for IDs
      const itemIdStr = item.id?.toString() || "";

      // Get the item's level
      const itemLevel = itemLevelMap.current.get(item.id) || 0;

      // Always show previously selected items
      if (previousSelections.includes(itemIdStr)) {
        return true;
      }

      // Always show selected items
      if (selectedItems.has(itemIdStr)) {
        return true;
      }

      // Check if we're in edit mode (have selections active)
      const isEditMode = selectedItems.size > 0 || previousSelections.length > 0;
      
      // If we're in edit mode with a selected item in this filter, apply stricter filtering
      if (isEditMode && Array.from(selectedItems).some(id => !previousSelections.includes(id))) {
        // Get all currently selected IDs that aren't from the first filter
        const currentFilterSelections = Array.from(selectedItems)
          .filter(id => !previousSelections.includes(id));
        
        if (currentFilterSelections.length > 0) {
          // Show this item if it's a parent of a selected item
          for (const selectedId of currentFilterSelections) {
            const parentIds = getParentIds(processedItems, selectedId);
            if (parentIds.includes(itemIdStr)) {
              return true;
            }
          }
          
          // Show this item if it's related to a selected item (sibling, child)
          for (const selectedId of currentFilterSelections) {
            // Get parent IDs of the selected item
            const selectedParentIds = getParentIds(processedItems, selectedId);
            
            // Show siblings (items that share the same immediate parent)
            if (selectedParentIds.length > 0) {
              const immediateParent = selectedParentIds[selectedParentIds.length - 1];
              const itemParentIds = getParentIds(processedItems, itemIdStr);
              
              // If this item has the same parent as the selected item
              if (itemParentIds.length > 0 && 
                  itemParentIds[itemParentIds.length - 1] === immediateParent) {
                return true;
              }
            }
            
            // Show children of the selected item
            if (isDescendantOf(processedItems, itemIdStr, selectedId)) {
              return true;
            }
          }
          
          // In edit mode with selection in this filter, only show closely related items
          return false;
        }
      }

      // Check if this item is a direct child of a previously selected unit
      if (previousSelections.length > 0) {
        for (const selectedId of previousSelections) {
          const selectedItem = findItem(processedItems, selectedId);
          if (selectedItem && selectedItem.children) {
            // Check if this item is a direct child of the selected item by matching IDs
            const isDirectChild = selectedItem.children.some(child => 
              child.id.toString() === itemIdStr
            );
            if (isDirectChild) {
              return true;
            }
          }
        }
      }

      // For first filter or when there's no previous selection, show everything
      if (filterLevel === 1 || previousSelections.length === 0) {
        return true;
      }

      // If there are previous selections, always show parent items of those selections
      if (previousSelections.length > 0) {
        for (const selectedId of previousSelections) {
          const parentIds = getParentIds(processedItems, selectedId);
          if (parentIds.includes(itemIdStr)) {
            // This item is a parent of a selected item, so always show it (though it will be disabled)
            return true;
          }
        }
      }

      // Get the first selected unit (from first filter)
      const firstSelectedUnitId = previousSelections[0];
      const firstSelectedUnit = findItem(processedItems, firstSelectedUnitId);
      
      if (firstSelectedUnit) {
        const firstSelectedLevel = itemLevelMap.current.get(firstSelectedUnitId) || 0;
        
        // Case 1: First selection is a top-level unit (level 0)
        if (firstSelectedLevel === 0) {
          // Hide other level 0 units
          if (itemLevel === 0 && itemIdStr !== firstSelectedUnitId) {
            return false;
          }
          
          // Hide items that are descendants of different root units
          if (!isDescendantOf(processedItems, itemIdStr, firstSelectedUnitId) && itemIdStr !== firstSelectedUnitId) {
            const itemParentIds = getParentIds(processedItems, itemIdStr);
            for (const parentId of itemParentIds) {
              const parentLevel = itemLevelMap.current.get(parentId) || 0;
              if (parentLevel === 0 && parentId !== firstSelectedUnitId) {
                return false;
              }
            }
          }
        }
        
        // Case 2: First selection is a mid-level unit (level 1 or 2)
        if (firstSelectedLevel >= 1) {
          // Get the ancestors of the selected unit
          const parentIds = getParentIds(processedItems, firstSelectedUnitId);
          
          // Always show parents of the selected unit
          if (parentIds.includes(itemIdStr)) {
            return true;
          }
          
          // If this is a top-level unit that's not an ancestor of the selected unit, hide it
          if (itemLevel === 0 && !parentIds.includes(itemIdStr) && itemIdStr !== firstSelectedUnitId) {
            return false;
          }
          
          // For level 1 units, only show the parent of the selected unit or the unit itself
          if (itemLevel === 1) {
            // Get the top-level ancestor of the selected unit
            const topLevelAncestor = parentIds.find(id => {
              const level = itemLevelMap.current.get(id) || 0;
              return level === 0;
            });
            
            // Find all level 1 units under the same top-level ancestor
            const isSiblingOrParent = parentIds.includes(itemIdStr) || 
                                     (topLevelAncestor && isDescendantOf(processedItems, itemIdStr, topLevelAncestor));
            
            // Hide level 1 units that aren't related to the selected unit
            if (!isSiblingOrParent && itemIdStr !== firstSelectedUnitId) {
              return false;
            }
          }
          
          // Check if this item is from an unrelated branch
          if (!isDescendantOf(processedItems, itemIdStr, firstSelectedUnitId) && 
              !isDescendantOf(processedItems, firstSelectedUnitId, itemIdStr) &&
              !parentIds.includes(itemIdStr) && 
              itemIdStr !== firstSelectedUnitId) {
              
            // Get the top-level ancestor of this item
            const itemTopLevelAncestors = getParentIds(processedItems, itemIdStr)
              .filter(id => {
                const level = itemLevelMap.current.get(id) || 0;
                return level === 0;
              });
              
            // Get the top-level ancestor of the selected item
            const selectedTopLevelAncestors = parentIds
              .filter(id => {
                const level = itemLevelMap.current.get(id) || 0;
                return level === 0;
              });
              
            // If they have different top-level ancestors, hide this item
            if (itemTopLevelAncestors.length > 0 && selectedTopLevelAncestors.length > 0) {
              const hasCommonAncestor = itemTopLevelAncestors.some(id => 
                selectedTopLevelAncestors.includes(id)
              );
              
              if (!hasCommonAncestor) {
                return false;
              }
            }
          }
        }
      }
      
      // Show descendants of selected items
      for (const selectedId of [...previousSelections, ...Array.from(selectedItems)]) {
        if (isDescendantOf(items, itemIdStr, selectedId)) {
          return true;
        }
        
        // Show parent items of any selected items
        if (getParentIds(items, selectedId).includes(itemIdStr)) {
          return true;
        }
      }
      
      // For second filter, if we have previous selections, show siblings of selected items
      if (previousSelections.length > 0) {
        const parentIds = getParentIds(items, itemIdStr);
        for (const parentId of parentIds) {
          if (selectedItems.has(parentId) || previousSelections.includes(parentId)) {
            return true;
          }
        }
      }
      
      // Default - for second filter, show items unless they're explicitly hidden above
      return filterLevel >= 2;
    };

    // Check if an item should be hidden
    const shouldHideItem = (itemId: string, isSelected: boolean): boolean => {
      // We don't want to hide disabled items if they're parents of selected items
      const item = findItem(processedItems, itemId);
      if (!item) return false;
      
      if (!hideUnselectedDisabledItems) return false;
      
      // If this is a parent of a selected item, always show it even if disabled
      if (previousSelections.length > 0) {
        for (const selectedId of previousSelections) {
          const parentIds = getParentIds(processedItems, selectedId);
          if (parentIds.includes(itemId)) {
            return false;  // Don't hide parent items of selections
          }
        }
      }
      
      return isItemDisabled(itemId) && !isSelected;
    };

    const renderItem = (
      item: TDashboardUnitFilter,
      level: number = 0,
      isLastItem: boolean = false,
    ) => {
      // Skip direct members items
      if (isDirectMembersItem(item)) {
        return null;
      }
      
      // Only render items that should be shown based on our filtering logic
      if (!shouldShowItem(item)) {
        return null;
      }
      
      // Continue with normal rendering
      return renderItemContent(item, level, isLastItem);
    };

    const renderItemContent = (
      item: TDashboardUnitFilter,
      level: number = 0,
      isLastItem: boolean = false,
    ) => {
      // Ensure item exists
      if (!item) {
        console.log("Attempted to render null/undefined item");
        return null;
      }

      // Double-check to skip direct members
      if (isDirectMembersItem(item)) {
        return null;
      }

      // Ensure string comparison for IDs
      const itemIdStr = item.id?.toString() || "";
      
      // Check if the item should be shown based on filter logic
      if (!shouldShowItem(item)) {
        return null;
      }
      
      // Find direct children based on parent_id relationship or children array
      let childrenArray: TDashboardUnitFilter[] = [];
      
      // Use children from the item if available
      if (item.children && Array.isArray(item.children) && item.children.length > 0) {
        childrenArray = [...item.children];
      } else if (item.children && typeof item.children === "object") {
        // Handle mobx or other observable objects
        try {
          // Try to convert to regular array and ensure each item is a TDashboardUnitFilter
          const asArray = Array.from(item.children as any);
          if (asArray.length > 0) {
            childrenArray = asArray as TDashboardUnitFilter[];
          }
        } catch (e) {
          console.log("Error converting children to array:", e);
        }
      }
            
      // Is this item expanded?
      const isExpanded = expandedItems.has(itemIdStr);
      
      // Check if this item has children - used to display expand button
      const hasChildren = childrenArray && childrenArray.length > 0;

      // An item is selected if it's in selectedItems OR in previousSelections
      const isInSelectedItems = selectedItems.has(itemIdStr);
      const isPreviousSelection = previousSelections.includes(itemIdStr);
      const isSelected = isInSelectedItems || isPreviousSelection;
      const isDisabled = isItemDisabled(itemIdStr);

      // If we're hiding disabled unselected items and this item matches that criteria, don't render it
      if (shouldHideItem(itemIdStr, isSelected)) {
        return null;
      }

      // If this is the previous selection, auto-expand it to show its children
      const shouldAutoExpand = isPreviousSelection && hasChildren;
      if (shouldAutoExpand && !isExpanded) {
        // Schedule this to happen after the current render cycle
        setTimeout(() => {
          const newExpanded = new Set(expandedItems);
          newExpanded.add(itemIdStr);
          setExpandedItems(newExpanded);
        }, 0);
      }

      return (
        <ItemContainer key={`item-${itemIdStr}-${renderKey}`} $level={level}>
          <ItemWrapper $isLastItem={isLastItem}>
            <ItemRow
              $isSelected={isSelected}
              $hasMatch={
                searchTerm ? item.label.toLowerCase().includes(searchTerm.toLowerCase()) : false
              }
              role="treeitem"
              tabIndex={-1}
              $isCurrentMatch={itemIdStr === currentMatchId}
              $isDisabled={isDisabled}
              $isPreviousSelection={isPreviousSelection}
              onClick={(e) => {
                if (!isDisabled) {
                  e.stopPropagation();
                  handleSelect(itemIdStr);
                } else {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <CheckboxLabel>
                <CheckboxContainer>
                  <div
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      opacity: isDisabled ? 0.6 : 1,
                    }}
                    onClick={(e) => {
                      if (!isDisabled) {
                        e.stopPropagation();
                        handleSelect(itemIdStr);
                      } else {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                  >
                    <Radio
                      checked={isSelected}
                      disabled={isDisabled}
                      size="small"
                      data-item-id={itemIdStr}
                      key={`radio-${itemIdStr}-${renderKey}`}
                    />
                  </div>
                </CheckboxContainer>
                <ItemText $isDisabled={isDisabled} $isSelected={isSelected}>
                  {renderHighlightedText(item.label, itemIdStr)}
                </ItemText>
                {hasChildren && (
                  <ExpandButton
                    onClick={(e) => toggleExpand(itemIdStr, e)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ExpandButton>
                )}
              </CheckboxLabel>
            </ItemRow>
          </ItemWrapper>
          {(isExpanded || shouldAutoExpand) && hasChildren && (
            <ChildrenWrapper $isLastItem={isLastItem}>
              <ChildrenContainer>
                {childrenArray
                  .filter(child => !isDirectMembersItem(child)) // Filter out direct members from children
                  .map((child, index, filteredChildren) => {
                    // Ensure we have the correct object not MobX proxy
                    const childObj = child as any;
                    const actualChild = childObj.toJSON ? childObj.toJSON() : child;
                    
                    return renderItem(
                      actualChild, 
                      level + 1, 
                      index === filteredChildren.length - 1
                    );
                  })}
              </ChildrenContainer>
            </ChildrenWrapper>
          )}
        </ItemContainer>
      );
    };

    // Helper to check if an item is a parent of any other item in the dataset
    const hasChildrenInDataset = (itemId: string): boolean => {
      const itemIdStr = itemId.toString();

      // Check if any item has this as a parent
      const findChildItems = (allItems: ExtendedDashboardUnitFilter[]): boolean => {
        for (const item of allItems) {
          if (item.parent_id && item.parent_id.toString() === itemIdStr) {
            return true;
          }
          if (item.children && findChildItems(item.children as ExtendedDashboardUnitFilter[])) {
            return true;
          }
        }
        return false;
      };

      return findChildItems(processedItems as ExtendedDashboardUnitFilter[]);
    };

    // Public API: Scroll to a match
    const scrollToMatch = (itemId: string) => {
      const element = itemRefs.current.get(itemId);
      if (element) {
        setCurrentMatchId(itemId);
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    // Public API: Clear selection
    const clearSelection = () => {
      // For second filter, we maintain previous selections from first filter
      if (previousSelections.length > 0) {
        const newSelections = new Set(previousSelections);
        setSelectedItems(newSelections);
        onSelect(Array.from(newSelections));
      } else {
        setSelectedItems(new Set());
        onSelect([]);
      }
    };

    // Public API: Update internal selection state
    const updateInternalSelection = (selections: string[]) => {
      // Convert all selections to strings for consistency
      const selectionsAsStrings = selections.map((id) => id.toString());

      // Create a new set that will hold all selections
      let finalSelections = new Set<string>();

      // Ensure we maintain first filter selections
      if (previousSelections.length > 0) {
        previousSelections.forEach((id) => {
          finalSelections.add(id.toString());
        });
      }

      // Add all new selections
      selectionsAsStrings.forEach((id) => {
        finalSelections.add(id);
      });

      const newSelectedItems = new Set(finalSelections);

      // Only update state if there's a change to avoid re-renders
      if (
        JSON.stringify(Array.from(selectedItems).sort()) !==
        JSON.stringify(Array.from(newSelectedItems).sort())
      ) {
        setSelectedItems(newSelectedItems);
        selectedItemsRef.current = Array.from(newSelectedItems);
      }

      // Expand parents of selected items for better UX
      const newExpanded = new Set(expandedItems);
      let hasExpandChanges = false;

      Array.from(finalSelections).forEach((id) => {
        // Also expand the item itself if it has children
        const item = findItem(processedItems, id);
        if (item && item.children && item.children.length > 0) {
          if (!newExpanded.has(id)) {
            newExpanded.add(id);
            hasExpandChanges = true;
          }
        }

        // Expand all parent items
        const parentIds = getParentIds(processedItems, id);
        parentIds.forEach((parentId) => {
          if (!newExpanded.has(parentId)) {
            newExpanded.add(parentId);
            hasExpandChanges = true;
          }
        });
      });

      if (hasExpandChanges) {
        setExpandedItems(newExpanded);
      }
    };

    // Update visual state of items based on selection
    const updateSelectionVisualState = (
      allItems: TDashboardUnitFilter[],
      selectedIds: string[],
    ) => {
      allItems.forEach((item) => {
        item.selected = selectedIds.includes(item.id);
        if (item.children) {
          updateSelectionVisualState(item.children, selectedIds);
        }
      });
    };

    // Public API: Reset component to initial state
    const resetComponent = () => {
      // Keep track of previous selections
      const allSelections = new Set<string>();

      // First add previous selections
      if (previousSelections && previousSelections.length > 0) {
        previousSelections.forEach((id) => allSelections.add(id.toString()));
      }

      // Then add value prop selections
      if (value && value.length > 0) {
        value.forEach((id) => allSelections.add(id.toString()));
      }

      // Get any items that were previously selected by the user
      if (selectedItemsRef.current && selectedItemsRef.current.length > 0) {
        selectedItemsRef.current.forEach((id) => allSelections.add(id.toString()));
      }

      // Also include current selected items
      Array.from(selectedItems).forEach((id) => {
        allSelections.add(id.toString());
      });

      // Update the state and force re-render
      setSelectedItems(allSelections);
      setRenderKey((prev) => prev + 1);
    };

    // Expose public API
    useImperativeHandle(ref, () => ({
      scrollToMatch,
      clearSelection,
      handleSelect,
      updateInternalSelection,
      resetComponent,
    }));

    // Filter items to show
    const items_to_render =
      !processedItems || processedItems.length === 0
        ? []
        : processedItems.filter((item) => shouldShowItem(item));

    // Debugging logs
    console.log("ALL PROCESSED ITEMS:", processedItems);
    console.log("ITEMS THAT PASSED FILTER:", items_to_render);
    console.log("FILTER LEVEL:", filterLevel);
    console.log("VALUE PROP:", value);
    console.log("PREVIOUS SELECTIONS:", previousSelections);

    // Print full tree structure for debugging
    const printTree = (items: TDashboardUnitFilter[], indent = "") => {
      items.forEach((item) => {
        const ext = item as ExtendedDashboardUnitFilter;
        console.log(
          `${indent}${item.label} (ID: ${item.id}, Level: ${ext.level}, Parent: ${ext.parent_id || "none"})`,
        );
        if (item.children && item.children.length > 0) {
          printTree(item.children, indent + "  ");
        }
      });
    };

    console.log("FULL TREE STRUCTURE:");
    printTree(processedItems);

    // Update selectedItems when value changes (for edit mode)
    useEffect(() => {
      if (value && value.length > 0) {
        const newSelectedItems = new Set<string>(selectedItems);
        value.forEach((id) => {
          newSelectedItems.add(id.toString());
        });

        if (
          JSON.stringify(Array.from(newSelectedItems).sort()) !==
          JSON.stringify(Array.from(selectedItems).sort())
        ) {
          setSelectedItems(newSelectedItems);
        }
      }
    }, [value]);

    // FALLBACK: If no items are visible, use the original items directly
    const items_to_use =
      items_to_render.length === 0 && processedItems.length === 0 && items.length > 0
        ? items
        : items_to_render;

    // For proper debugging
    console.log("FINAL ITEMS TO RENDER:", items_to_use);

    // Render the component
    return (
      <Container>
        <TreeSelectWrapper>
          <TreeSelectContainer>
            {!items || items.length === 0 ? (
              <div>No items to display</div>
            ) : items_to_use.length === 0 ? (
              <div>No matching items found. Please try a different selection.</div>
            ) : (
              <>
                {items_to_use.map((item, index) => {
                  console.log(`About to render item ${item.label} at index ${index}`);
                  return renderItem(item, 0, index === items_to_use.length - 1);
                })}

              </>
            )}
          </TreeSelectContainer>
        </TreeSelectWrapper>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 10px 0;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
  min-height: 200px;
  background: #fafafa;
`;

const TreeSelectContainer = styled.div`
  padding-top: 8px;
  min-width: fit-content;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const ItemContainer = styled.div<{ $level: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${(props) => props.$level * 24}px;
  padding-bottom: 2px;

  ${(props) =>
    props.$level === 0 &&
    `
    > ${ItemWrapper}:before,
    > ${ItemWrapper}:after,
    > ${ChildrenWrapper}:before {
      display: none;
    }
  `}
`;

const ItemWrapper = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: -24px;
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: var(--colorBrandForeground2);
  }

  &:after {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${(props) => (props.$isLastItem ? "50%" : "110%")};
    background-color: var(--colorBrandForeground2);
  }
`;

const ItemRow = styled.div<{
  $isSelected: boolean;
  $hasMatch: boolean;
  $isDisabled?: boolean;
  $isCurrentMatch?: boolean;
  $isPreviousSelection?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${(props) => {
    if (props.$isPreviousSelection || (props.$isSelected && props.$isDisabled))
      return "var(--colorNeutralBackground2)";
    if (props.$isSelected) return "var(--colorNeutralBackground2)";
    return "var(--colorNeutralBackground1)";
  }};
  margin: 2px 0;
  width: fit-content;
  border: 1px solid
    ${(props) => {
      if (props.$isCurrentMatch) return "#6B4EFF";
      if (props.$isPreviousSelection || (props.$isSelected && props.$isDisabled))
        return "var(--colorNeutralStroke1)";
      if (props.$isSelected) return "var(--colorBrandForeground3)";
      return "transparent";
    }};
  min-height: 36px;
  transition: all 0.3s;
  outline: none;
  box-shadow: ${(props) => (props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "none")};
  opacity: ${(props) =>
    props.$isDisabled && !props.$isSelected && !props.$isPreviousSelection ? 0.7 : 1};
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => {
      if (props.$isDisabled) {
        if (props.$isPreviousSelection || props.$isSelected)
          return "var(--colorNeutralBackground2)";
        return "var(--colorNeutralBackground1)";
      }
      if (props.$isSelected) return "var(--colorNeutralBackground2)";
      return "var(--colorNeutralBackground3)";
    }};
    transform: ${(props) => (props.$isDisabled ? "none" : "translateY(-1px)")};
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 2px 4px rgba(0, 0, 0, 0.1)"};
  }

  &:active {
    transform: ${(props) => (props.$isDisabled ? "none" : "translateY(0)")};
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 1px 2px rgba(0, 0, 0, 0.1)"};
  }

  &:focus {
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 0 0 2px #6B4EFF"};
  }
`;

const ItemText = styled.div<{ $isDisabled: boolean; $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => {
    if (props.$isDisabled && props.$isSelected) return "var(--colorNeutralForeground1)";
    if (props.$isDisabled) return "var(--colorNeutralForeground3)";
    if (props.$isSelected) return "var(--colorNeutralForeground1)";
    return "inherit";
  }};
  font-weight: ${(props) => (props.$isSelected ? "500" : "normal")};
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-height: 24px;
  cursor: pointer;

  span {
    user-select: none;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const ExpandButton = styled.button`
  border: none;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: var(--colorBrandForeground3);
  }

  &:active {
    background-color: var(--colorNeutralBackground1);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const ChildrenWrapper = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  display: flex;

  &:before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${(props) => (props.$isLastItem ? "0" : "calc(100% + 12px)")};
    background-color: var(--colorBrandForeground2);
  }
`;

const HighlightedText = styled.span<{ $isCurrent: boolean }>`
  color: #6b4eff;
  font-weight: 600;
  background-color: ${(props) => (props.$isCurrent ? "#F5F3FF" : "transparent")};
  padding: ${(props) => (props.$isCurrent ? "2px 4px" : "0")};
  border-radius: ${(props) => (props.$isCurrent ? "4px" : "0")};
  scroll-margin: 100px;
`;

const TreeSelectWrapper = styled.div.attrs({ className: "TreeSelectWrapper" })`
  flex: 1;
  overflow: auto;
  padding-bottom: 24px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;
