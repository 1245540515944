import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TDashboardUnitFilter } from 'types/dashboard';
import { getUnitName } from 'utils/dashboard/useGetSegmentName';

type ChipsUnitWidgetProps = {
  hideRemoveButton?: boolean;
  showLimit?: boolean;
  selectedItems: TDashboardUnitFilter[];
  onRemoveItem?: (id: string) => void;
  onClearAll?: () => void;
  fixedItemId?: string;
};

const ChipsUnitWidget: React.FC<ChipsUnitWidgetProps> = ({
  hideRemoveButton,
  showLimit,
  selectedItems,
  onRemoveItem,
  onClearAll,
  fixedItemId,
}) => {
  const [isChipsExpanded, setIsChipsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const chipsContainerRef = useRef<HTMLDivElement>(null);
  const maxLimitItems = 10;

  // Add debug logs to track props and state
  console.log('ChipsUnitWidget render:', {
    hasItems: selectedItems?.length > 0,
    itemCount: selectedItems?.length,
    hideRemoveButton,
    itemSample: selectedItems?.[0]
  });

  const toggleChipsContainer = () => {
    setIsChipsExpanded(!isChipsExpanded);
  };

  useEffect(() => {
    const container = chipsContainerRef.current;
    if (container && !hideRemoveButton) {
      setShowExpandButton(container.scrollHeight > container.clientHeight);
    }
  }, [selectedItems, hideRemoveButton]);

  const renderChips = () => {
    if (!showLimit || selectedItems.length <= maxLimitItems) {
      return selectedItems.map((item) => {
        const isFixed = item.id === fixedItemId || 
                      (fixedItemId && item.id.toString() === fixedItemId.toString());
        return (
          <StyledChip
            key={item.id}
            label={<span dangerouslySetInnerHTML={{ __html: getUnitName(item, false) }} />}
            onDelete={!isFixed && onRemoveItem ? () => onRemoveItem(item.id) : undefined}
            deleteIcon={!isFixed && onRemoveItem ? <CloseIcon style={{ fontSize: '16px' }} /> : undefined}
          />
        );
      });
    }

    const visibleItems = selectedItems.slice(0, maxLimitItems);
    const remainingCount = selectedItems.length - maxLimitItems;

    return (
      <>
        {visibleItems.map((item) => {
          const isFixed = item.id === fixedItemId || 
                        (fixedItemId && item.id.toString() === fixedItemId.toString());
          return (
            <StyledChip
              key={item.id}
              label={<span dangerouslySetInnerHTML={{ __html: getUnitName(item, false) }} />}
              onDelete={!isFixed && onRemoveItem ? () => onRemoveItem(item.id) : undefined}
              deleteIcon={!isFixed && onRemoveItem ? <CloseIcon style={{ fontSize: '16px' }} /> : undefined}
            />
          );
        })}
        <Text>
          {`+${remainingCount} more`}
        </Text>
      </>
    );
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <SelectedUnitsHeader 
        $hasSelectedItems={selectedItems.length > 0} 
        $hideRemoveButton={hideRemoveButton}
        style={{
          backgroundColor: selectedItems.length > 0 && !hideRemoveButton ? '#eeefff' : 'transparent'
        }}
      >
        {!hideRemoveButton && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <SelectedUnitText>
              Unit Selected: {selectedItems.length === 0 ? 'None' : selectedItems.length}
            </SelectedUnitText>
            {selectedItems.length > 0 && onClearAll && (
              <ClearAllButton onClick={onClearAll}>
                Clear all
              </ClearAllButton>
            )}
          </div>
        )}
        {showExpandButton && selectedItems.length > 0 && !hideRemoveButton && (
          <ExpandButton onClick={toggleChipsContainer}>
            {isChipsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ExpandButton>
        )}
      </SelectedUnitsHeader>
      {selectedItems.length > 0 && (
        <ChipsContainer 
          ref={chipsContainerRef}
          $isExpanded={isChipsExpanded}
          $hideRemoveButton={hideRemoveButton}
          style={{
            backgroundColor: !hideRemoveButton ? '#eeefff' : 'transparent'
          }}
        >
          {renderChips()}
        </ChipsContainer>
      )}
    </div>
  );
};

export default ChipsUnitWidget;

const SelectedUnitsHeader = styled.div<{ $hasSelectedItems: boolean, $hideRemoveButton: boolean }>`
  display: flex;
  justify-content: ${props => props.$hideRemoveButton ? 'flex-start' : 'space-between'};
  align-items: center;
  padding: ${props => {
    if (props.$hideRemoveButton) {
      return props.$hasSelectedItems ? '12px 0' : '12px 0';
    }
    return props.$hasSelectedItems ? '12px 24px' : '12px 24px 12px 24px';
  }};
  margin-top: ${props => props.$hasSelectedItems && !props.$hideRemoveButton && '12px' };
  background-color: ${props => props.$hasSelectedItems && !props.$hideRemoveButton ? 'var(--colorPalleteLightBlue)' : 'transparent'};
`;

const ChipsContainer = styled.div<{ $isExpanded: boolean, $hideRemoveButton: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${props => props.$hideRemoveButton ? 'transparent' : 'var(--colorPalleteLightBlue)'};
  padding: ${props => props.$hideRemoveButton ? '0' : '12px 24px 12px 24px'};
  max-height: ${props => {
    if (props.$hideRemoveButton) return 'none';
    return props.$isExpanded ? '200px' : '100px';
  }};
  overflow-y: ${props => props.$hideRemoveButton ? 'visible' : 'auto'};
  transition: max-height 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const StyledChip = styled(Chip)`
  background-color: var(--grenBackgroundColor3) !important;
  border: 1px solid var(--colorNeutralStroke1) !important;
  border-radius: 4px !important;
  color: var(--colorNeutralForeground1) !important;
  font-size: 12px !important;
  height: 24px !important;
  margin: 4px !important;

  .MuiChip-deleteIcon {
    color: var(--colorBrandForeground1) !important;
    margin-right: 4px !important;
  }

  &:hover {
    background-color: var(--colorNeutralBackground2) !important;
  }
`;

const SelectedUnitText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  color: var(--colorNeutralForeground5);
`;

const ClearAllButton = styled.button`
  background: none;
  border: none;
  color: var(--colorBrandForeground1);
  font-size: 14px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;

  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const ExpandButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background-color: white;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    font-size: 18px;
  }

  &:hover {
    background-color: var(--colorNeutralBackground2);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`; 

const Text = styled.p`
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;