import { FC, useState, useEffect } from "react";
import { observer } from "mobx-react";

import styled, { css } from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatSizeRounded from "@mui/icons-material/FormatSizeRounded";
import RemoveIcon from "@mui/icons-material/Remove";

import { CardLoader } from "features/Dashboard/CardLoader";
import store from "../../store";

type DimensionsCardProps = {};

const DimensionsCard: FC<DimensionsCardProps> = observer(() => {
  const { isDimensionsEmpty } = store;
  const [isTopBelowCT, setIsTopBelowCT] = useState(false);
  const [isBottomBelowCT, setIsBottomBelowCT] = useState(false);

  useEffect(() => {
    if (store.projectInfo?.dimensions) {
      setIsTopBelowCT(store.projectInfo.dimensions.top?.below_ct);
      setIsBottomBelowCT(store.projectInfo.dimensions.bottom?.below_ct);
    }
  }, [store.projectInfo?.dimensions]);

  const content = (
    <>
      <ContentRow>
        <StyledIcon src="/assets/green-round-like.png" />
        <DimensionInfoContainer>
          <Label>Top dimension</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && store.projectInfo?.dimensions && (
              <>
                <Text>
                  {isTopBelowCT ? 
                    store.projectInfo.dimensions.bottom?.name || "" :
                    store.projectInfo.dimensions.top?.name || ""}
                </Text>
                <TextGroup>
                  <Text>
                    {isTopBelowCT ? 
                      (store.projectInfo.dimensions.bottom?.percent ?? "") :
                      (store.projectInfo.dimensions.top?.percent ?? "")}
                    %
                  </Text>
                  {isTopBelowCT ? (
                    // Bottom dimension diff info when shown at top
                    <>
                      {store.projectInfo.dimensions.bottom?.hide_diff && <StyledRemoveIcon />}
                      {!store.projectInfo.dimensions.bottom?.hide_diff && 
                        typeof store.projectInfo.dimensions.bottom?.diff === 'number' && (
                        <>
                          {store.projectInfo.dimensions.bottom.diff < 0 ? (
                            <StyledArrowDropDownIcon />
                          ) : store.projectInfo.dimensions.bottom.diff > 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : <StyledEmptyIcon />}
                          <Text>
                            {Math.abs(store.projectInfo.dimensions.bottom.diff)}%
                          </Text>
                        </>
                      )}
                    </>
                  ) : (
                    // Top dimension diff info
                    <>
                      {store.projectInfo.dimensions.top?.hide_diff && <StyledRemoveIcon />}
                      {!store.projectInfo.dimensions.top?.hide_diff && 
                        typeof store.projectInfo.dimensions.top?.diff === 'number' && (
                        <>
                          {store.projectInfo.dimensions.top.diff < 0 ? (
                            <StyledArrowDropDownIcon />
                          ) : store.projectInfo.dimensions.top.diff > 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : <StyledEmptyIcon />}
                          <Text>
                            {Math.abs(store.projectInfo.dimensions.top.diff)}%
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </TextGroup>
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
      <ContentRow>
        <StyledIcon src="/assets/red-round-warning.png" />
        <DimensionInfoContainer>
          <Label>Bottom dimension</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && store.projectInfo?.dimensions && (
              <>
                <Text>
                  {!isBottomBelowCT && (store.projectInfo.dimensions.bottom?.name || "")}
                </Text>
                <TextGroup>
                {isBottomBelowCT ? (
                    <Text>%</Text>
                  ) : (
                    <>
                      <Text>
                        {store.projectInfo.dimensions.bottom?.percent ?? ""}
                        %
                      </Text>
                      {store.projectInfo.dimensions.bottom?.hide_diff && <StyledRemoveIcon />}
                      {!store.projectInfo.dimensions.bottom?.hide_diff && 
                        typeof store.projectInfo.dimensions.bottom?.diff === 'number' && (
                        <>
                          {store.projectInfo.dimensions.bottom.diff < 0 ? (
                            <StyledArrowDropDownIcon />
                          ) : store.projectInfo.dimensions.bottom.diff > 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : <StyledEmptyIcon />}
                          <Text>
                            {Math.abs(store.projectInfo.dimensions.bottom.diff)}%
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </TextGroup>
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
      <ContentRow>
        <StyledIcon src="/assets/green-round-like.png" />
        <DimensionInfoContainer>
          <Label>Biggest improvement</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && (
              <>
                <Text>
                  {store.projectInfo?.dimensions &&
                    store.projectInfo?.dimensions.biggestImprovement?.name}
                </Text>
                <TextGroup>
                  <Text>
                    {(store.projectInfo?.dimensions &&
                      store.projectInfo?.dimensions.biggestImprovement?.percent !== null
                        ? store.projectInfo?.dimensions.biggestImprovement?.percent
                        : "")}
                    %
                  </Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.biggestImprovement?.hide_diff && <StyledRemoveIcon />}
                  {store.projectInfo?.dimensions && !store.projectInfo?.dimensions.biggestImprovement?.hide_diff &&
                    typeof store.projectInfo?.dimensions.biggestImprovement?.diff === 'number' ? (
                    <>
                      {store.projectInfo?.dimensions &&
                        (store.projectInfo?.dimensions.biggestImprovement?.diff < 0 ? (
                        <StyledArrowDropDownIcon />
                      ) : store.projectInfo?.dimensions.biggestImprovement?.diff > 0 ? (
                        <StyledArrowDropUpIcon />
                      ) : <StyledEmptyIcon />)}
                      <Text>
                        {store.projectInfo?.dimensions &&
                          typeof store.projectInfo?.dimensions.biggestImprovement?.diff === 'number' ?
                          Math.abs(store.projectInfo?.dimensions.biggestImprovement?.diff) + "%" : ""}
                      </Text>
                    </>
                  ) : null}
                </TextGroup>
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
      <ContentRow>
        <StyledIcon src="/assets/red-round-warning.png" />
        <DimensionInfoContainer>
          <Label>Biggest drop</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && (
              <>
                <Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.biggestDrop?.name}
                </Text>
                <TextGroup>
                  <Text>
                    {(store.projectInfo?.dimensions &&
                      store.projectInfo?.dimensions.biggestDrop?.percent !== null
                        ? store.projectInfo?.dimensions.biggestDrop?.percent
                        : "")}
                    %
                  </Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.biggestDrop?.hide_diff && <StyledRemoveIcon />}
                  {store.projectInfo?.dimensions && !store.projectInfo?.dimensions.biggestDrop?.hide_diff && 
                    typeof store.projectInfo?.dimensions.biggestDrop?.diff === 'number' ? (
                    <>
                      {store.projectInfo?.dimensions && 
                        (store.projectInfo?.dimensions.biggestDrop?.diff < 0 ? (
                        <StyledArrowDropDownIcon />
                      ) : store.projectInfo?.dimensions.biggestDrop?.diff > 0 ? (
                        <StyledArrowDropUpIcon />
                      ) : <StyledEmptyIcon />)}
                      <Text>
                        {store.projectInfo?.dimensions &&
                          typeof store.projectInfo?.dimensions.biggestDrop?.diff === 'number' ?
                          Math.abs(store.projectInfo?.dimensions.biggestDrop?.diff) + "%" : ""}
                      </Text>
                    </>
                  ) : null}
                </TextGroup> 
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
    </>
  )

  const isPending = store.isDimensionsPending || !store.projectInfo

  return (
    <Card>
      <TitleRow>
        <Title>Dimensions</Title>
        {isDimensionsEmpty && <EmptyStateTitle>Overall score is not available for this survey.</EmptyStateTitle>}
      </TitleRow>
      <Content isEmpty={isDimensionsEmpty}>
        {isPending ? <CardLoader contentHeight={172} /> : content}
      </Content>
    </Card>
  );
});

export default DimensionsCard;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
  margin-bottom: 16px;
`;

const EmptyStateTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
  margin-left: auto;
  line-height: 16px;
`;

type ContentProps = {
  isEmpty?: boolean;
};

const Content = styled.div<ContentProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 38px;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }

  ${(props) => Boolean(props.isEmpty) && css`
    opacity: 0.4;
    pointer-events: none;
  `}
`;

const ContentRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 26px 1fr;
  column-gap: 12px;
  margin-top: 24px;
`;

const DimensionInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 0px 0px 8px 0px;
`;

const Label = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-bottom: 8px;
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const StyledIcon = styled.img`
  width: 26px;
  height: 26px;
`;

const TextGroup = styled.span`
  display: flex;
  align-items: center;
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: var(--colorPaletteGreenBackground2);
  font-size: 24px !important;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: var(--colorPaletteRedForeground1);
  font-size: 24px !important;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  color: var(--colorNeutralForeground5);
  font-size: 18px !important;
  margin-left: 8px;
`;

const StyledEmptyIcon = styled(FormatSizeRounded)`
    color: transparent;
    font-size: 18px !important;
`;