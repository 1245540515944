import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { ExpandLess, ExpandMore, CheckCircle } from "@mui/icons-material";
import styled from "styled-components";
import { TDashboardUnitFilter } from "types/dashboard";
import { Radio } from "@mui/material";
import store from "features/Dashboard/hotspots/store";

interface SingleTreeThirdOnwardFilterProps {
  items: TDashboardUnitFilter[];
  onSelect: (selectedIds: string[]) => void;
  searchTerm?: string;
  currentMatchIndex?: number;
  hideUnselectedDisabledItems?: boolean;
  value?: string[];
  previousSelections?: string[];
  filterLevel: number; // 3 for 3rd filter, 4 for 4th filter, etc.
}

interface MatchInfo {
  id: string;
  label: string;
}

const getInitialSelectedItems = (items: TDashboardUnitFilter[], value?: string[]): string[] => {
  if (!value || value.length === 0) {
    return [];
  }

  const selectedIds: string[] = [];

  const findItemsById = (allItems: TDashboardUnitFilter[], ids: string[]) => {
    for (const item of allItems) {
      if (ids.includes(item.id)) {
        selectedIds.push(item.id);
      }
      if (item.children) {
        findItemsById(item.children, ids);
      }
    }
  };

  findItemsById(items, value);
  return selectedIds;
};

const isDescendantOf = (
  items: TDashboardUnitFilter[],
  itemId: string,
  potentialAncestorId: string,
): boolean => {
  // Ensure string comparison for IDs
  const itemIdStr = itemId.toString();
  const ancestorIdStr = potentialAncestorId.toString();

  const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id.toString() === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const item = findItem(items, itemIdStr);
  if (!item) return false;

  let currentItem = item;
  const findParent = (
    items: TDashboardUnitFilter[],
    childId: string,
  ): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some((child) => child.id.toString() === childId)) {
        return item;
      }
      if (item.children) {
        const found = findParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  while (true) {
    const parent = findParent(items, currentItem.id.toString());
    if (!parent) break;
    if (parent.id.toString() === ancestorIdStr) return true;
    currentItem = parent;
  }

  return false;
};

const filterItemsByPreviousUnit = (
  items: TDashboardUnitFilter[],
  previousUnitId: string | null,
): TDashboardUnitFilter[] => {
  if (!previousUnitId) return items;

  const findUnit = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findUnit(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const previousUnit = findUnit(items, previousUnitId);
  if (!previousUnit) return [];

  if (previousUnit.children && previousUnit.children.length > 0) {
    return previousUnit.children;
  }

  return [];
};

const findParentUnits = (allItems: TDashboardUnitFilter[], selectedIds: string[]): string[] => {
  const parentUnits = new Set<string>();

  const findDirectParent = (
    items: TDashboardUnitFilter[],
    childId: string,
  ): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some((child) => child.id === childId)) {
        return item;
      }
      if (item.children) {
        const found = findDirectParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  selectedIds.forEach((id) => {
    let currentId = id;
    while (true) {
      const parent = findDirectParent(allItems, currentId);
      if (!parent) break;
      parentUnits.add(parent.id);
      currentId = parent.id;
    }
  });

  return Array.from(parentUnits);
};

export const SingleTreeThirdOnwardFilter = forwardRef<
  {
    scrollToMatch: (itemId: string) => void;
    clearSelection: () => void;
    handleSelect: (id: string) => void;
    updateInternalSelection: (selections: string[]) => void;
    resetComponent: () => void;
  },
  SingleTreeThirdOnwardFilterProps
>(
  (
    {
      items,
      onSelect,
      searchTerm,
      currentMatchIndex = 0,
      hideUnselectedDisabledItems = false,
      value,
      previousSelections = [],
      filterLevel = 3, // Default to 3rd filter
    },
    ref,
  ) => {
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
    const [matches, setMatches] = useState<MatchInfo[]>([]);
    const [currentMatchId, setCurrentMatchId] = useState<string>("");
    const [renderKey, setRenderKey] = useState<number>(0);
    const itemRefs = useRef<Map<string, HTMLElement>>(new Map());
    const [previousUnitId, setPreviousUnitId] = useState<string | null>(null);
    const [previouslySelectedItems, setPreviouslySelectedItems] = useState<Set<string>>(
      new Set(previousSelections),
    );

    const [isSelectionMode, setIsSelectionMode] = useState<boolean>(
      previousSelections.length === 0,
    );

    const itemLevelMap = useRef<Map<string, number>>(new Map());
    const isFirstRenderRef = useRef(true);

    useEffect(() => {
      itemLevelMap.current.clear();

      const buildLevelMap = (itemsList: TDashboardUnitFilter[], level: number) => {
        itemsList.forEach((item) => {
          itemLevelMap.current.set(item.id, level);

          if (item.children && item.children.length > 0) {
            buildLevelMap(item.children, level + 1);
          }
        });
      };

      buildLevelMap(items, 0);
    }, [items]);

    useEffect(() => {
      const currentPrevSelected = Array.from(previouslySelectedItems);
      const newPrevSelected = Array.from(new Set(previousSelections));

      const prevSelectionsChanged =
        JSON.stringify(currentPrevSelected.sort()) !== JSON.stringify(newPrevSelected.sort());

      if (prevSelectionsChanged) {
        setPreviouslySelectedItems(new Set(previousSelections));
      }

      if (previousSelections.length > 0) {
        if (isSelectionMode) {
          setIsSelectionMode(false);
        }
      }
    }, [previousSelections, isSelectionMode, previouslySelectedItems]);

    useEffect(() => {
      // Mark all items in previousSelections as selected by default
      const updatePreviousSelectionsAsSelected = () => {
        if (previousSelections.length > 0) {
          const newSelectedItems = new Set(selectedItems);
          previousSelections.forEach((id) => {
            newSelectedItems.add(id);
          });

          if (
            JSON.stringify(Array.from(newSelectedItems).sort()) !==
            JSON.stringify(Array.from(selectedItems).sort())
          ) {
            setSelectedItems(newSelectedItems);
          }
        }
      };

      // Call this once on mount
      updatePreviousSelectionsAsSelected();
    }, [previousSelections]); // Only dependency is previousSelections

    // Ensure previousSelections are maintained
    useEffect(() => {
      if (value && previousSelections.length > 0) {
        const valueArray = Array.isArray(value) ? value : value ? [value] : [];
        const combinedSelections = new Set([...previousSelections, ...valueArray]);

        if (
          JSON.stringify(Array.from(combinedSelections).sort()) !==
          JSON.stringify(Array.from(selectedItems).sort())
        ) {
          setSelectedItems(combinedSelections);
        }
      }
    }, [value, previousSelections]);

    // For third filter logic
    useEffect(() => {
      if (filterLevel === 3 && previousSelections.length > 0) {
        // For third filter, ensure we have both the first and second filter selections
        const prevSelectionsSet = new Set(previousSelections);
        if (
          JSON.stringify(Array.from(prevSelectionsSet).sort()) !==
          JSON.stringify(Array.from(selectedItems).sort())
        ) {
          setSelectedItems(prevSelectionsSet);
        }
      }
    }, [filterLevel, previousSelections]);

    // Handle auto-expansion on mount
    useEffect(() => {
      // Auto-expand logic - expand ALL items with children
      const newExpandedItems = new Set<string>();

      // Function to recursively find and expand all items with children
      const expandAllItemsWithChildren = (itemsList: TDashboardUnitFilter[]) => {
        itemsList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            newExpandedItems.add(item.id.toString());
            // Recursively expand children that have their own children
            expandAllItemsWithChildren(item.children);
          }
        });
      };

      // Expand path to any selected items
      const expandPathToSelectedItems = (selectedIds: string[]) => {
        selectedIds.forEach((id) => {
          const parentIds = getParentIds(items, id.toString());
          parentIds.forEach((parentId) => {
            newExpandedItems.add(parentId);
          });

          // Also expand the selected item if it has children
          const item = findItem(items, id);
          if (item && item.children && item.children.length > 0) {
            newExpandedItems.add(id.toString());
          }
        });
      };

      // Determine how much to expand based on filter level
      if (filterLevel >= 3) {
        // For higher filter levels, be more selective - only expand relevant paths
        // Get all items that should be expanded
        const relevantItems: string[] = [];

        // Include all previous selections
        if (previousSelections.length > 0) {
          relevantItems.push(...previousSelections);
        }

        // Include current value
        if (value && value.length > 0) {
          value.forEach((v) => {
            if (!relevantItems.includes(v)) {
              relevantItems.push(v);
            }
          });
        }

        // Expand paths to all relevant items
        expandPathToSelectedItems(relevantItems);

        // Also expand descendant paths of the last selection
        if (previousSelections.length > 0) {
          const lastSelection = previousSelections[previousSelections.length - 1];
          const lastSelectedItem = findItem(items, lastSelection);

          if (lastSelectedItem && lastSelectedItem.children) {
            // Recursively expand the children of the last selection
            expandAllItemsWithChildren([lastSelectedItem]);
          }
        }
      } else {
        // For lower filter levels, expand everything for better navigation
        expandAllItemsWithChildren(items);
      }

      setExpandedItems(newExpandedItems);
    }, [items, filterLevel]); // Only run when items or filter level changes

    // Ensure previously selected items and current value are properly maintained in selectedItems state
    useEffect(() => {
      const newSelectedItems = new Set<string>();

      // Add previous selections first
      if (previousSelections && previousSelections.length > 0) {
        previousSelections.forEach((id) => newSelectedItems.add(id.toString()));
      }

      // Add current value selections
      if (value && value.length > 0) {
        value.forEach((id) => newSelectedItems.add(id.toString()));
      }

      // If selections have changed, update state
      if (
        JSON.stringify(Array.from(newSelectedItems).sort()) !==
        JSON.stringify(Array.from(selectedItems).sort())
      ) {
        setSelectedItems(newSelectedItems);
        setRenderKey((prev) => prev + 1); // Force re-render to update UI
      }
    }, [value, previousSelections, items]);

    // For third filter logic
    useEffect(() => {
      // Skip this effect when expandedItems changes to prevent loops
      if (!isFirstRenderRef.current) return;

      let targetState: Set<string>;

      // For third filter and beyond, always include all previous selections
      if (filterLevel >= 3) {
        // Start with all previous selections as strings
        targetState = new Set(previousSelections.map((id) => id.toString()));

        // Add value if provided
        if (value) {
          const valueArray = Array.isArray(value) ? value : [value];
          valueArray.forEach((v) => {
            if (v !== null && v !== undefined) {
              targetState.add(v.toString());
            }
          });
        }
      } else {
        // For first or second filter, handle normally
        const valueArray = Array.isArray(value) ? value : value ? [value] : [];
        const validIds = getInitialSelectedItems(items, valueArray);

        if (validIds.length > 0) {
          // Include all previous selections and add the new selection
          const selectedAsStrings = [...previousSelections, ...validIds].map((id) => id.toString());
          targetState = new Set(selectedAsStrings);

          // Store the last selected unit
          const lastSelectedId = validIds[validIds.length - 1];
          const selectedItem = findItem(items, lastSelectedId);
          if (selectedItem && store && typeof store.storeSelectedUnit === "function") {
            storeSelectedUnit(selectedItem);
          }
        } else {
          targetState = new Set(previousSelections.map((id) => id.toString()));
        }
      }

      const currentSelectedArraySorted = Array.from(selectedItems).sort();
      const targetStateArraySorted = Array.from(targetState).sort();

      if (JSON.stringify(currentSelectedArraySorted) !== JSON.stringify(targetStateArraySorted)) {
        setSelectedItems(targetState);
      }

      // Auto-expand items
      const newExpandedItems = new Set<string>();
      let itemsToExpand: string[] = [];

      // Add all parent items to expanded set
      const expandAllParents = (itemsList: TDashboardUnitFilter[]) => {
        itemsList.forEach((item) => {
          // If this item has children, expand it
          if (item.children && item.children.length > 0) {
            newExpandedItems.add(item.id.toString());

            // Recursively process children
            expandAllParents(item.children);
          }
        });
      };

      // Collect parent items of selected/previous items
      const collectParents = (selectedIds: string[]) => {
        selectedIds.forEach((id) => {
          const parentIds = getParentIds(items, id);
          parentIds.forEach((parentId) => {
            if (!newExpandedItems.has(parentId)) {
              newExpandedItems.add(parentId);
              itemsToExpand.push(parentId);
            }
          });

          // Also expand the selected item if it has children
          const item = findItem(items, id);
          if (item && item.children && item.children.length > 0) {
            newExpandedItems.add(id.toString());
            itemsToExpand.push(id.toString());
          }
        });
      };

      // For third filter and beyond, just expand the path to selected items
      if (filterLevel >= 3) {
        collectParents([...previousSelections, ...Array.from(targetState)]);
      }
      // For the base case, expand all top-level items
      else {
        expandAllParents(items);
      }

      // Ensure expanded state is updated
      setExpandedItems(newExpandedItems);

      // Mark this as not the first render anymore
      isFirstRenderRef.current = false;
    }, [items, value, filterLevel, previousSelections]);

    const toggleExpand = (itemId: string, event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const item = findItem(items, itemId);
      if (!item) {
        return;
      }

      const newExpanded = new Set(expandedItems);
      const itemIdStr = itemId.toString();

      if (newExpanded.has(itemIdStr)) {
        const descendantIds = getAllDescendantIds(item);
        newExpanded.delete(itemIdStr);

        // Only collapse direct descendants, not all descendants
        if (item.children) {
          item.children.forEach((child) => {
            newExpanded.delete(child.id.toString());
          });
        }
      } else {
        newExpanded.add(itemIdStr);

        // For efficiency, only auto-expand one level at a time
        if (item.children) {
          // For items that are descendants of a selected item, expand them too
          const isDescendantOfSelection = previousSelections.some((selectionId) =>
            isDescendantOf(items, itemIdStr, selectionId),
          );

          if (isDescendantOfSelection) {
            item.children.forEach((child) => {
              if (child.children && child.children.length > 0) {
                newExpanded.add(child.id.toString());
              }
            });
          }
        }
      }

      setExpandedItems(newExpanded);
    };

    const handleSelect = (id: string) => {
      const item = findItem(items, id);
      if (!item) {
        return;
      }

      // Convert ID to string
      const idStr = id.toString();

      // For third filter and beyond, we maintain a path of selections
      // by keeping all previous selections
      let newSelected = new Set<string>(previousSelections.map((id) => id.toString()));

      // Special handling for "direct members" item selection
      const isDirectMember = isDirectMembersItem(item);

      if (isDirectMember) {
        // For direct members, add it to the existing selections
        newSelected.add(idStr);
      } else {
        // Check if this item is already in the previous selections
        // If it is, we don't want to add it again
        if (!Array.from(newSelected).includes(idStr)) {
          newSelected.add(idStr);
        }
      }

      // In both cases, update the selected state
      setSelectedItems(newSelected);
      onSelect(Array.from(newSelected));

      // Store the selected unit with its details
      storeSelectedUnit(item);

      // Expand any necessary items
      const newExpanded = new Set(expandedItems);
      let hasExpandChanges = false;

      const parentIds = getParentIds(items, idStr);
      parentIds.forEach((parentId) => {
        if (!newExpanded.has(parentId)) {
          newExpanded.add(parentId);
          hasExpandChanges = true;
        }
      });

      // If selecting an item with children, expand it
      if (item.children && item.children.length > 0 && !newExpanded.has(idStr)) {
        newExpanded.add(idStr);
        hasExpandChanges = true;
      }

      if (hasExpandChanges) {
        setExpandedItems(newExpanded);
      }

      // Update the previous unit ID for context
      setPreviousUnitId(idStr);
      setIsSelectionMode(false);
      setRenderKey((prevKey) => prevKey + 1);
    };

    // New function to store the selected unit in the store
    const storeSelectedUnit = (item: TDashboardUnitFilter) => {
      // Check if item has the necessary properties for a unit
      if (!item) return;

      // Get the item level from the map
      const level = itemLevelMap.current.get(item.id) || 0;

      // Get parent unit
      const parentUnit = getParentUnit(item);
      const parentId = parentUnit?.id;

      // Create unit data object with all available information
      const unitData = {
        id: item.id,
        name: item.label,
        level: level,
        parent_id: parentId,
        // Only include unitHeadEmails if it exists on the item
        ...(item.unitHeadEmails !== undefined && { unitHeadEmails: item.unitHeadEmails }),
      };

      // Store the unit data in the store
      if (store && typeof store.storeSelectedUnit === "function") {
        store.storeSelectedUnit(unitData);
      }
    };

    // Helper function to get the parent unit
    const getParentUnit = (item: TDashboardUnitFilter): TDashboardUnitFilter | null => {
      const parentIds = getParentIds(items, item.id);
      if (parentIds.length === 0) return null;

      const parentId = parentIds[parentIds.length - 1];
      return findItem(items, parentId);
    };

    const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
      // Ensure string comparison for IDs
      const idToFind = id.toString();

      for (const item of items) {
        const itemId = item.id.toString();
        if (itemId === idToFind) return item;
        if (item.children) {
          const found = findItem(item.children, idToFind);
          if (found) return found;
        }
      }
      return null;
    };

    const getAllDescendantIds = (item: TDashboardUnitFilter): string[] => {
      const ids: string[] = [];
      if (item.children) {
        for (const child of item.children) {
          // Skip direct members when collecting descendant IDs
          if (isDirectMembersItem(child)) {
            continue;
          }
          ids.push(child.id);
          ids.push(...getAllDescendantIds(child));
        }
      }
      return ids;
    };

    const getParentIds = (
      items: TDashboardUnitFilter[],
      targetId: string,
      path: string[] = [],
    ): string[] => {
      // Ensure string comparison
      const targetIdStr = targetId.toString();

      for (const item of items) {
        if (item.id.toString() === targetIdStr) {
          return path;
        }
        if (item.children) {
          const found = getParentIds(item.children, targetIdStr, [...path, item.id.toString()]);
          if (found.length) return found;
        }
      }
      return [];
    };

    const findAllMatches = (items: TDashboardUnitFilter[]): MatchInfo[] => {
      const matches: MatchInfo[] = [];

      const searchItems = (item: TDashboardUnitFilter) => {
        if (item.label.toLowerCase().includes(searchTerm?.toLowerCase() || "")) {
          matches.push({ id: item.id, label: item.label });
        }

        if (item.children) {
          item.children.forEach(searchItems);
        }
      };

      items.forEach(searchItems);
      return matches;
    };

    useEffect(() => {
      if (searchTerm) {
        const foundMatches = findAllMatches(items);
        setMatches(foundMatches);

        if (foundMatches.length > 0) {
          const matchIndex = Math.min(currentMatchIndex, foundMatches.length) - 1;
          setCurrentMatchId(foundMatches[matchIndex]?.id || "");
        } else {
          setCurrentMatchId("");
        }

        const nodesToExpand = new Set<string>(expandedItems);
        let hasChanges = false;

        foundMatches.forEach((match) => {
          if (!nodesToExpand.has(match.id)) {
            nodesToExpand.add(match.id);
            hasChanges = true;
          }

          const parentIds = getParentIds(items, match.id);
          parentIds.forEach((id) => {
            if (!nodesToExpand.has(id)) {
              nodesToExpand.add(id);
              hasChanges = true;
            }
          });
        });

        if (hasChanges) {
          setExpandedItems(nodesToExpand);
        }
      } else {
        setMatches([]);
        setCurrentMatchId("");
      }
    }, [searchTerm, items, currentMatchIndex]);

    const renderHighlightedText = (text: string, itemId: string) => {
      if (!searchTerm) return text;

      const matchIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());
      if (matchIndex === -1) return text;

      const isCurrentMatch = itemId === currentMatchId;

      return (
        <span>
          {text.slice(0, matchIndex)}
          <HighlightedText
            $isCurrent={isCurrentMatch}
            ref={(el) => {
              if (el) {
                itemRefs.current.set(itemId, el);
              }
            }}
          >
            {text.slice(matchIndex, matchIndex + searchTerm.length)}
          </HighlightedText>
          {text.slice(matchIndex + searchTerm.length)}
        </span>
      );
    };

    const isDirectMembersItem = (item: TDashboardUnitFilter): boolean => {
      const label = item.label?.toLowerCase() || '';
      const id = item.id?.toString()?.toLowerCase() || '';
      
      return (
        // Check label patterns
        label.includes("direct members") ||
        label.includes("direct member") ||
        
        // Check ID patterns
        id.includes("direct_members") ||
        id.includes("direct-members") ||
        id.includes("-direct") ||
        id.includes("_direct") ||
        
        // Check for labels that end with direct members/member
        label.endsWith("direct members") ||
        label.endsWith("direct member")
      );
    };

    const getParentOfDirectMembers = (directMembersItem: TDashboardUnitFilter): string | null => {
      const parentIds = getParentIds(items, directMembersItem.id);
      return parentIds.length > 0 ? parentIds[parentIds.length - 1] : null;
    };

    // For third filter and beyond, items are considered disabled if they're already selected
    // in the previous filters
    const isItemDisabled = (itemId: string): boolean => {
      // Get the item to check its level
      const item = findItem(items, itemId);
      if (!item) return false;

      // Ensure string comparison for IDs
      const itemIdStr = itemId.toString();

      // Get the item's level from the level map
      const itemLevel = itemLevelMap.current.get(itemId) || 0;

      // Check if this is a previously selected item
      if (previousSelections.includes(itemIdStr)) {
        return true;
      }

      // If we're at the second filter (filterLevel = 2) or beyond
      if (filterLevel >= 2 && previousSelections.length > 0) {
        // Get the previously selected unit from the first filter
        const firstFilterSelection = previousSelections[0];
        const firstFilterItem = findItem(items, firstFilterSelection);

        if (firstFilterItem) {
          const firstFilterLevel = itemLevelMap.current.get(firstFilterSelection) || 0;

          // If the first filter selected a level 0 unit
          if (firstFilterLevel === 0) {
            // Check if this item is a descendant of a different level 0 unit
            const isDescendantOfDifferentLevel0 = (itemToCheck: TDashboardUnitFilter): boolean => {
              // Get all parents of this item
              const parentIds = getParentIds(items, itemToCheck.id.toString());

              // Skip this check if the item is a descendant of the first selection
              if (isDescendantOf(items, itemToCheck.id.toString(), firstFilterSelection)) {
                return false;
              }

              // Check if any parent is a level 0 unit different from the selected one
              for (const parentId of parentIds) {
                const parentLevel = itemLevelMap.current.get(parentId) || 0;
                if (parentLevel === 0 && parentId !== firstFilterSelection) {
                  console.log(
                    `[SingleTreeThirdOnwardFilter] ${itemToCheck.label} is disabled because it's a descendant of a different level 0 unit`,
                  );
                  return true;
                }
              }

              return false;
            };

            // If this is a different level 0 unit, disable it
            if (itemLevel === 0 && itemIdStr !== firstFilterSelection) {
              return true;
            }

            // If this is a descendant of a different level 0 unit, disable it
            if (isDescendantOfDifferentLevel0(item)) {
              return true;
            }
          }
        }
      }

      // If we're at the third filter (filterLevel = 3) or beyond
      if (previousSelections.length >= filterLevel - 1) {
        // Previous selections (excluding the current filter level)
        const earlierSelections = previousSelections.slice(0, filterLevel - 1);

        // Check if this item is already selected in a previous filter
        if (earlierSelections.includes(itemIdStr)) {
          return true;
        }

        // Check if this item is a parent of any previously selected item
        for (const selectedId of earlierSelections) {
          const parentIds = getParentIds(items, selectedId);
          if (parentIds.includes(itemIdStr)) {
            return true;
          }
        }

        // For third filter, disable all items at level 0 and level 1 except the already selected ones
        if (filterLevel === 3 && (itemLevel === 0 || itemLevel === 1)) {
          // Only allow the previously selected items at these levels
          const isDisabled = !earlierSelections.includes(itemIdStr);
          return isDisabled;
        }

        // For fourth filter, disable all items at level 0, 1, and 2 except the already selected ones
        if (filterLevel === 4 && (itemLevel === 0 || itemLevel === 1 || itemLevel === 2)) {
          // Only allow the previously selected items at these levels
          const isDisabled = !earlierSelections.includes(itemIdStr);
          return isDisabled;
        }
      }

      return false;
    };

    const shouldHideItem = (itemId: string, isSelected: boolean): boolean => {
      if (!hideUnselectedDisabledItems) return false;
      return isItemDisabled(itemId) && !isSelected;
    };

    // Enhanced shouldShowItem function to specifically handle items based on level structure
    const shouldShowItem = (item: TDashboardUnitFilter): boolean => {
      // Skip direct members items
      if (isDirectMembersItem(item)) {
        return false;
      }
      
      // Ensure we compare strings for IDs
      const itemIdStr = item.id.toString();

      // Get the item's level
      const itemLevel = itemLevelMap.current.get(item.id) || 0;

      // Always show previously selected items
      if (previousSelections.includes(itemIdStr)) {
        return true;
      }

      // Always show selected items
      if (selectedItems.has(itemIdStr) || Array.from(selectedItems).includes(itemIdStr)) {
        return true;
      }

      // If filtering isn't applied yet, show all items
      if (previousSelections.length < filterLevel - 1) {
        return true;
      }

      // When in second filter or beyond, check if we should hide items based on level 0 selection
      if (filterLevel >= 2 && previousSelections.length > 0) {
        const firstFilterId = previousSelections[0];
        const firstFilterItem = findItem(items, firstFilterId);

        if (!firstFilterItem) {
          return true; // If we can't find the first selection, show everything
        }

        // Get the level of the first filter selection
        const firstFilterLevel = itemLevelMap.current.get(firstFilterId) || 0;

        // If first filter selected a level 0 unit, hide other level 0 units and their descendants
        if (firstFilterLevel === 0) {
          // If this is a different level 0 unit, hide it
          if (itemLevel === 0 && itemIdStr !== firstFilterId) {
            return false;
          }

          // Check if this item is a descendant of a different level 0 unit
          const isDescendantOfDifferentLevel0 = (): boolean => {
            // Skip this check if the item is a descendant of the first selection
            if (isDescendantOf(items, itemIdStr, firstFilterId)) {
              return false;
            }

            // Get all parents of this item
            const parentIds = getParentIds(items, itemIdStr);

            // Check if any parent is a level 0 unit different from the selected one
            for (const parentId of parentIds) {
              const parentLevel = itemLevelMap.current.get(parentId) || 0;
              if (parentLevel === 0 && parentId !== firstFilterId) {
                return true;
              }
            }

            return false;
          };

          // If this is a descendant of a different level 0 unit, hide it
          if (isDescendantOfDifferentLevel0()) {
            return false;
          }

          // Show descendants of the first selection
          if (isDescendantOf(items, itemIdStr, firstFilterId)) {
            return true;
          }

          // Also show parent of the first selection
          const parentIds = getParentIds(items, firstFilterId);
          const isParent = parentIds.includes(itemIdStr);
          if (isParent) {
            return true;
          }

          return false;
        }
      }

      // For the third filter and beyond, we need more specific rules

      // Get the last selected item from previous filter
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      const lastSelectedItem = findItem(items, lastSelectedId);

      if (!lastSelectedItem) {
        return true; // If we can't find the last selection, show everything
      }

      // Get level of last selection
      const lastSelectionLevel = itemLevelMap.current.get(lastSelectedId) || 0;

      // In third filter, hide units at level 0/1 that weren't previously selected
      if (filterLevel === 3) {
        if (itemLevel === 0 || itemLevel === 1) {
          // Only show previously selected items at this level
          const shouldShow = previousSelections.includes(itemIdStr);
          if (!shouldShow) {
            console.log(
              `[SingleTreeThirdOnwardFilter] Hiding ${item.label} - level ${itemLevel} item in filter 3 not in previous selections`,
            );
          }
          return shouldShow;
        }

        // For level 2+ items in filter 3, they should be descendants of selected items
        if (itemLevel >= 2) {
          // Check if this item is a descendant of any previous selection
          for (const selectionId of previousSelections) {
            if (isDescendantOf(items, itemIdStr, selectionId)) {
              return true;
            }
          }

          // If item is at the next level after the last selection, show it
          if (itemLevel === lastSelectionLevel + 1) {
            const itemParentIds = getParentIds(items, itemIdStr);
            if (itemParentIds.includes(lastSelectedId)) {
              return true;
            }
          }
        }
      }

      // Check if this item is a descendant of any item in the previous selections
      for (const selectionId of previousSelections) {
        if (isDescendantOf(items, itemIdStr, selectionId)) {
          return true;
        }
      }

      // Show siblings of the last selected item
      const parentIds = getParentIds(items, lastSelectedId);
      if (parentIds.length > 0) {
        const immediateParentId = parentIds[parentIds.length - 1];
        const parentItem = findItem(items, immediateParentId);

        if (parentItem && parentItem.children) {
          if (parentItem.children.some((child) => child.id.toString() === itemIdStr)) {
            return true;
          }
        }
      }

      // Also show the selected item itself and its parents
      if (itemIdStr === lastSelectedId || parentIds.includes(itemIdStr)) {
        return true;
      }

      return false;
    };

    const renderItem = (
      item: TDashboardUnitFilter,
      level: number = 0,
      isLastItem: boolean = false,
    ) => {
      // Skip direct members items
      if (isDirectMembersItem(item)) {
        return null;
      }
      
      // Only render items that should be shown
      if (!shouldShowItem(item)) {
        return null;
      }

      return renderItemContent(item, level, isLastItem);
    };

    const renderItemContent = (
      item: TDashboardUnitFilter,
      level: number = 0,
      isLastItem: boolean = false,
    ) => {
      // Double-check to skip direct members
      if (isDirectMembersItem(item)) {
        return null;
      }

      const hasChildren = item.children && item.children.length > 0;
      const isExpanded = expandedItems.has(item.id) || expandedItems.has(item.id.toString());

      // Ensure string comparison for IDs
      const itemIdStr = item.id.toString();
      const isSelected =
        selectedItems.has(item.id) || Array.from(selectedItems).includes(itemIdStr);
      const isDisabled = isItemDisabled(item.id);
      const isPreviousSelection = previousSelections.includes(itemIdStr);

      // If we're hiding disabled unselected items and this item matches that criteria, don't render it
      if (shouldHideItem(item.id, isSelected)) {
        return null;
      }

      // Check if this item is a descendant of the filter selections
      const isDescendantOfSelections = previousSelections.some((selectionId) =>
        isDescendantOf(items, itemIdStr, selectionId),
      );

      // Collect all children that need to be displayed
      let childrenToRender: TDashboardUnitFilter[] = [];
      if (hasChildren && item.children) {
        // Filter out direct members from children array
        childrenToRender = item.children.filter(child => !isDirectMembersItem(child));
      }

      return (
        <ItemContainer key={`item-${item.id}-${renderKey}`} $level={level}>
          <ItemWrapper $isLastItem={isLastItem}>
            <ItemRow
              $isSelected={isSelected || isPreviousSelection}
              $hasMatch={
                searchTerm ? item.label.toLowerCase().includes(searchTerm.toLowerCase()) : false
              }
              role="treeitem"
              tabIndex={-1}
              $isCurrentMatch={item.id === currentMatchId}
              $isDisabled={isDisabled}
              $isPreviousSelection={isPreviousSelection}
              onClick={(e) => {
                if (!isDisabled) {
                  e.stopPropagation();
                  handleSelect(item.id);
                }
              }}
            >
              <CheckboxLabel>
                <CheckboxContainer>
                  <div
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      opacity: isDisabled ? 0.6 : 1,
                    }}
                    onClick={(e) => {
                      if (!isDisabled) {
                        e.stopPropagation();
                        handleSelect(item.id);
                      }
                    }}
                  >
                    <Radio
                      checked={isSelected || isPreviousSelection}
                      disabled={isDisabled}
                      size="small"
                      data-item-id={item.id}
                      key={`radio-${item.id}-${renderKey}`}
                    />
                  </div>
                </CheckboxContainer>
                <ItemText $isDisabled={isDisabled} $isSelected={isSelected || isPreviousSelection}>
                  {renderHighlightedText(item.label, item.id)}
                </ItemText>
                {hasChildren && (
                  <ExpandButton
                    onClick={(e) => toggleExpand(item.id, e)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ExpandButton>
                )}
              </CheckboxLabel>
            </ItemRow>
          </ItemWrapper>
          {hasChildren && isExpanded && (
            <ChildrenWrapper $isLastItem={isLastItem}>
              <ChildrenContainer>
                {childrenToRender.map((child, index) => {
                  // For descendants of the selected items, always show children when expanded
                  if (isDescendantOfSelections || isSelected || isPreviousSelection) {
                    return renderItem(child, level + 1, index === childrenToRender.length - 1);
                  }

                  // Otherwise, apply normal filtering logic
                  if (shouldShowItem(child)) {
                    return renderItem(child, level + 1, index === childrenToRender.length - 1);
                  }

                  return null;
                })}
              </ChildrenContainer>
            </ChildrenWrapper>
          )}
        </ItemContainer>
      );
    };

    const scrollToMatch = (itemId: string) => {
      const element = itemRefs.current.get(itemId);
      if (element) {
        setCurrentMatchId(itemId);
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    const clearSelection = () => {
      // For third filter and beyond, we only clear the current filter's selection
      // while maintaining previous selections
      if (previousSelections.length >= filterLevel - 1) {
        const newSelections = new Set(previousSelections.slice(0, filterLevel - 1));
        setSelectedItems(newSelections);
        onSelect(Array.from(newSelections));
      } else {
        setSelectedItems(new Set());
        onSelect([]);
      }
    };

    const updateInternalSelection = (selections: string[]) => {
      let finalSelections = selections;

      // If this is the third filter or beyond, make sure all previous selections are included
      if (filterLevel >= 3 && previousSelections.length > 0) {
        const combined = new Set([...previousSelections, ...selections]);
        finalSelections = Array.from(combined);
      }

      const newSelectedItems = new Set(finalSelections);

      const currentSelectionArray = Array.from(selectedItems);
      const newSelectionArray = Array.from(newSelectedItems);

      if (
        JSON.stringify(currentSelectionArray.sort()) !== JSON.stringify(newSelectionArray.sort())
      ) {
        setSelectedItems(newSelectedItems);
      }

      const newExpanded = new Set(expandedItems);
      let hasExpandChanges = false;

      finalSelections.forEach((id) => {
        const parentIds = getParentIds(items, id);
        parentIds.forEach((parentId) => {
          if (!newExpanded.has(parentId)) {
            newExpanded.add(parentId);
            hasExpandChanges = true;
          }
        });
      });

      if (hasExpandChanges) {
        setExpandedItems(newExpanded);
      }

      const updatedItems = [...items];
      updateSelectionVisualState(updatedItems, finalSelections);
    };

    const updateSelectionVisualState = (
      allItems: TDashboardUnitFilter[],
      selectedIds: string[],
    ) => {
      allItems.forEach((item) => {
        item.selected = selectedIds.includes(item.id);
        if (item.children) {
          updateSelectionVisualState(item.children, selectedIds);
        }
      });
    };

    const resetComponent = () => {
      // Collect all selections from all sources
      const allSelections = new Set<string>();

      // Add previous selections
      if (previousSelections && previousSelections.length > 0) {
        previousSelections.forEach((id) => allSelections.add(id.toString()));
      }

      // Add value selections
      if (value && value.length > 0) {
        value.forEach((id) => allSelections.add(id.toString()));
      }

      // Update state and force re-render
      setSelectedItems(allSelections);
      setRenderKey((prev) => prev + 1);
    };

    useImperativeHandle(ref, () => ({
      scrollToMatch,
      clearSelection,
      handleSelect,
      updateInternalSelection,
      resetComponent,
    }));

    // Filter items to show based on the filter level context
    const items_to_render = !items || items.length === 0 ? [] : items.filter(shouldShowItem);

    return (
      <Container>
        <TreeSelectWrapper>
          <TreeSelectContainer>
            {!items || items.length === 0 ? (
              <div>No items to display</div>
            ) : items_to_render.length === 0 ? (
              <div>No matching items found. Please try a different selection.</div>
            ) : (
              <>
                {items_to_render.map((item, index) =>
                  renderItem(item, 0, index === items_to_render.length - 1),
                )}
              </>
            )}
          </TreeSelectContainer>
        </TreeSelectWrapper>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 10px 0;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
  min-height: 200px;
  background: #fafafa;
`;

const TreeSelectContainer = styled.div`
  padding-top: 8px;
  min-width: fit-content;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const ItemContainer = styled.div<{ $level: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${(props) => props.$level * 24}px;
  padding-bottom: 2px;

  ${(props) =>
    props.$level === 0 &&
    `
    > ${ItemWrapper}:before,
    > ${ItemWrapper}:after,
    > ${ChildrenWrapper}:before {
      display: none;
    }
  `}
`;

const ItemWrapper = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: -24px;
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: var(--colorBrandForeground2);
  }

  &:after {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${(props) => (props.$isLastItem ? "50%" : "110%")};
    background-color: var(--colorBrandForeground2);
  }
`;

const ItemRow = styled.div<{
  $isSelected: boolean;
  $hasMatch: boolean;
  $isDisabled?: boolean;
  $isCurrentMatch?: boolean;
  $isPreviousSelection?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${(props) => {
    if (props.$isPreviousSelection || (props.$isSelected && props.$isDisabled))
      return "var(--colorNeutralBackground2)";
    if (props.$isSelected) return "var(--colorNeutralBackground2)";
    return "var(--colorNeutralBackground1)";
  }};
  margin: 2px 0;
  width: fit-content;
  border: 1px solid
    ${(props) => {
      if (props.$isCurrentMatch) return "#6B4EFF";
      if (props.$isPreviousSelection || (props.$isSelected && props.$isDisabled))
        return "var(--colorNeutralStroke1)";
      if (props.$isSelected) return "var(--colorBrandForeground3)";
      return "transparent";
    }};
  min-height: 36px;
  transition: all 0.3s;
  outline: none;
  box-shadow: ${(props) => (props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "none")};
  opacity: ${(props) =>
    props.$isDisabled && !props.$isSelected && !props.$isPreviousSelection ? 0.7 : 1};
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => {
      if (props.$isDisabled) {
        if (props.$isPreviousSelection || props.$isSelected)
          return "var(--colorNeutralBackground2)";
        return "var(--colorNeutralBackground1)";
      }
      if (props.$isSelected) return "var(--colorNeutralBackground2)";
      return "var(--colorNeutralBackground3)";
    }};
    transform: ${(props) => (props.$isDisabled ? "none" : "translateY(-1px)")};
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 2px 4px rgba(0, 0, 0, 0.1)"};
  }

  &:active {
    transform: ${(props) => (props.$isDisabled ? "none" : "translateY(0)")};
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 1px 2px rgba(0, 0, 0, 0.1)"};
  }

  &:focus {
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 0 0 2px #6B4EFF"};
  }
`;

const ItemText = styled.div<{ $isDisabled: boolean; $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => {
    if (props.$isDisabled && props.$isSelected) return "var(--colorNeutralForeground1)";
    if (props.$isDisabled) return "var(--colorNeutralForeground3)";
    if (props.$isSelected) return "var(--colorNeutralForeground1)";
    return "inherit";
  }};
  font-weight: ${(props) => (props.$isSelected ? "500" : "normal")};
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-height: 24px;
  cursor: pointer;

  span {
    user-select: none;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const ExpandButton = styled.button`
  border: none;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: var(--colorBrandForeground3);
  }

  &:active {
    background-color: var(--colorNeutralBackground1);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const ChildrenWrapper = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  display: flex;

  &:before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${(props) => (props.$isLastItem ? "0" : "calc(100% + 12px)")};
    background-color: var(--colorBrandForeground2);
  }
`;

const HighlightedText = styled.span<{ $isCurrent: boolean }>`
  color: #6b4eff;
  font-weight: 600;
  background-color: ${(props) => (props.$isCurrent ? "#F5F3FF" : "transparent")};
  padding: ${(props) => (props.$isCurrent ? "2px 4px" : "0")};
  border-radius: ${(props) => (props.$isCurrent ? "4px" : "0")};
  scroll-margin: 100px;
`;

const TreeSelectWrapper = styled.div.attrs({ className: "TreeSelectWrapper" })`
  flex: 1;
  overflow: auto;
  padding-bottom: 24px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;
