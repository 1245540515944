import { FC, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  IconButton,
  SelectChangeEvent,
  MenuItem,
  Select,
  InputAdornment,
  TextField,
  ListSubheader,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import store from "../store";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { decodeUnicode } from "utils/decodeUnicode";
import UnitSelectionPopup from "../../UnitSelectionPopup";
import { TDashboardUnitFilter } from "types/dashboard";
import topStore  from "features/Dashboard/store";
import SingleSelectMenuItem from "./SingleSelectMenuItem";
import MultipleSelectMenuItem from "./MultipleSelectMenuItem";

type SettingsListProps = {
  localSelectedSegments: { attribute: string; mode: string; value: string | string[] }[];
  handleModeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  handleAttributeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  changeSegments: (changedIndex: number, field: string, value: any) => void;
  clearLastAttribute: (changedIndex: number) => void;
  removeFilter: (index: number) => void;
  clearSubsequentFilters: (fromIndex: number) => void;
  isEditMode?: boolean;
};

const SettingsList: FC<SettingsListProps> = observer((props) => {
  const [openSelects, setOpenSelects] = useState<{ [key: number]: boolean }>({});
  const [showUnitPopup, setShowUnitPopup] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);

  const handleSelectOpen = (index: number) => {
    if (topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp) return;
    setOpenSelects((prev) => ({ ...prev, [index]: true }));
  };

  const handleSelectClose = (index: number) => {
    setOpenSelects((prev) => ({ ...prev, [index]: false }));
    const areAllSelectsClosed = Object.values({ ...openSelects, [index]: false }).every(
      (isOpen) => !isOpen
    );
    if (areAllSelectsClosed && (!topStore.isDashboardUnitFilterEnabled || !store.hasClosedPopUp)) {
      store.setData("", "segmentSearchValue");
    }
  };

  const handleRemoveFilter = (index: number) => {
    if (topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp) return;
    
    props.removeFilter(index);
    props.clearSubsequentFilters(index);
    
    setOpenSelects(prev => ({...prev}));
    store.setData("", "segmentSearchValue");
  };

  const getSegmentsList = (filter: any, rowIndex: number) => {
    if (filter.attribute === "Unit") {
      const previousRow = props.localSelectedSegments[rowIndex - 1];
      const previousUnitId = previousRow?.attribute === "Unit" 
        ? (typeof previousRow.value === 'string' 
            ? previousRow.value 
            : Array.isArray(previousRow.value) && previousRow.value.length === 1 
              ? previousRow.value[0] 
              : null)
        : null;

      const allUnits = store.attributeList
        .find((el) => String(el.label) === "Unit")
        ?.list || [];

      if (rowIndex === 0) {
        const firstLevelUnits = allUnits.map((item) => ({
          value: item.id.toString(),
          label: `${item?.name} (Level ${item.level})`,
        }));
        return firstLevelUnits;
      }

      if (!previousUnitId) {
        return [];
      }

      const previousUnit = allUnits.find(item => item.id.toString() === previousUnitId);
      
      if (!previousUnit) {
        return [];
      }

      const childUnits = allUnits.filter(unit => 
        unit.parent_id === previousUnit.id && unit.id !== previousUnit.id
      );
      const parentUnit = allUnits.find(unit => unit.id === previousUnit.parent_id);
      const siblingUnits = parentUnit 
        ? allUnits.filter(unit => 
            unit.parent_id === parentUnit.id && 
            unit.id !== previousUnit.id
          )
        : [];
      
      const sameLevel = allUnits.filter(unit => 
        unit.level === previousUnit.level && 
        unit.id !== previousUnit.id
      );
      
      let unitsToShow = childUnits.length > 0 
        ? childUnits 
        : siblingUnits.length > 0 
          ? siblingUnits 
          : sameLevel;
      
      if (unitsToShow.length === 0) {
        unitsToShow = allUnits.filter(unit => unit.id !== previousUnit.id);
      }
      
      return unitsToShow.map((item) => ({
        value: item.id.toString(),
        label: `${item?.name} (Level ${item.level})`,
      }));
    }

    return (
      store.attributeList
      .find((el) => String(el.label) === String(filter.attribute))
        ?.list.filter((item) => typeof item.value !== "undefined")
      .map((item) => item.value.toString()) || []
    );
  };

  const renderValue = (row: { attribute: string; mode: string; value: string | string[] }, rowIndex: number) => {
    if (row.attribute === "Unit") {
      const previousRow = props.localSelectedSegments[rowIndex - 1];
      const previousUnitId = previousRow?.attribute === "Unit"
        ? (typeof previousRow.value === 'string'
            ? previousRow.value
            : Array.isArray(previousRow.value) && previousRow.value.length === 1
              ? previousRow.value[0]
            : null)
        : null;

      if (rowIndex === 0 || !previousUnitId) {
        const allUnits = store.attributeList
          .find((el) => String(el.label) === "Unit")
          ?.list.map((item, index) => (
            <MenuItem key={item.id.toString() + index} value={item.id.toString()}>
              {`${item?.name} (Level ${item.level})`}
            </MenuItem>
          )) || [];
        
        return allUnits;
      }

      const allUnitsList = store.attributeList
        .find((el) => String(el.label) === "Unit")
        ?.list || [];
        
      const previousUnit = allUnitsList.find(item => item.id.toString() === previousUnitId);
      
      if (!previousUnit) return [];

      const childUnits = allUnitsList.filter(unit => 
        unit.parent_id === previousUnit.id && unit.id !== previousUnit.id
      );
      
      const parentUnit = allUnitsList.find(unit => unit.id === previousUnit.parent_id);
      
      const siblingUnits = parentUnit 
        ? allUnitsList.filter(unit => 
            unit.parent_id === parentUnit.id && 
            unit.id !== previousUnit.id
          )
        : [];
      
      const sameLevelUnits = allUnitsList.filter(unit => 
        unit.level === previousUnit.level && 
        unit.id !== previousUnit.id
      );
      
      let unitsToShow = childUnits.length > 0 
        ? childUnits 
        : siblingUnits.length > 0 
          ? siblingUnits 
          : sameLevelUnits.length > 0
            ? sameLevelUnits
            : allUnitsList.filter(unit => unit.id !== previousUnit.id);

      return unitsToShow.map((item, index) => (
        <MenuItem key={item.id.toString() + index} value={item.id.toString()}>
          {`${item?.name} (Level ${item.level})`}
        </MenuItem>
      ));
    }

    const items =
      store.attributeList
        .find((el) => String(el.label) === String(row.attribute))
        ?.list.filter((item) => {
          if (!item?.value) return false;
          if (store.segmentSearchValue && Object.values(openSelects).some((isOpen) => isOpen)) {
            return item.value.toLowerCase().includes(store.segmentSearchValue.toLowerCase());
          }
          return true;
        })
        .map((item, index) => (
          <MenuItem key={item.value + index} value={item.value}>
            {item.value}
          </MenuItem>
        )) || [];

    return items;
  };

  const handleValueChange = (rowIndex: number, value: string | string[]) => {
    if (topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp) return;
    
    props.changeSegments(rowIndex, "value", value);
    
    const currentRow = props.localSelectedSegments[rowIndex];
    if (currentRow.attribute === "Unit" && rowIndex < props.localSelectedSegments.length - 1) {
      props.clearSubsequentFilters(rowIndex + 1);
      setOpenSelects({});
    }
  };

  const handleModeSelect = (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => {
    if (row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled && !store.hasClosedPopUp) {
      
      setActiveRowIndex(rowIndex);
      setShowUnitPopup(true);
      
      clearAllUnitSelections(store.filterUnitList);
      
      if (rowIndex > 0) {
        const parentUnit = props.localSelectedSegments[rowIndex - 1];
        if (parentUnit?.attribute === "Unit" && parentUnit.value) {
          const parentUnitId = Array.isArray(parentUnit.value) 
            ? parentUnit.value[0].toString() 
            : parentUnit.value.toString();
            
          markUnitAsSelected(store.filterUnitList, parentUnitId);
        }
      }
      
      const currentRow = props.localSelectedSegments[rowIndex];
      if (currentRow?.attribute === "Unit" && currentRow.value) {
        const currentValue = currentRow.value;
        
        if (Array.isArray(currentValue)) {
          currentValue.forEach(id => {
            markUnitAsSelected(store.filterUnitList, id.toString());
          });
        } else {
          markUnitAsSelected(store.filterUnitList, currentValue.toString());
        }
      }
    }
    if (!topStore.isDashboardUnitFilterEnabled || !store.hasClosedPopUp) {
      props.handleModeSelect(e, row, rowIndex);
    }
  };
  
  const clearAllUnitSelections = (units: TDashboardUnitFilter[]) => {
    units.forEach(unit => {
      unit.selected = false;
      if (unit.children) {
        clearAllUnitSelections(unit.children);
      }
    });
  };
  
  const markUnitAsSelected = (units: TDashboardUnitFilter[], unitId: string) => {
    for (const unit of units) {
      if (unit.id.toString() === unitId) {
        unit.selected = true;
        return true;
      }
      if (unit.children) {
        if (markUnitAsSelected(unit.children, unitId)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleUnitSelect = (selectedUnits: (string | number)[]) => {
    if (topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp) {
      setShowUnitPopup(false);
      setActiveRowIndex(null);
      return;
    }
    
    if (activeRowIndex !== null) {
      if (selectedUnits.length === 0) {
        props.clearLastAttribute(activeRowIndex);
        setShowUnitPopup(false);
        setActiveRowIndex(null);
        return;
      }
      
      const normalizedSelectedUnits = selectedUnits.map(unit => unit.toString());
      
      const isSingleSelectMode = props.localSelectedSegments[activeRowIndex]?.mode === "one";
      
      let finalSelectedUnits: string[] = [];
      
      const previousSelectionsByFilter: string[] = [];
      for (let i = 0; i < activeRowIndex; i++) {
        const prevRow = props.localSelectedSegments[i];
        if (prevRow?.attribute === "Unit" && prevRow.value) {
          if (Array.isArray(prevRow.value)) {
            previousSelectionsByFilter.push(...prevRow.value.map(v => v.toString()));
          } else if (prevRow.value) {
            previousSelectionsByFilter.push(prevRow.value.toString());
          }
        }
      }
      
      if (activeRowIndex >= 2) {
        const previousSelectionsSet = new Set(previousSelectionsByFilter.map(id => id.toString()));
        const newlySelectedUnits = normalizedSelectedUnits.filter(id => !previousSelectionsSet.has(id));
        
        if (newlySelectedUnits.length > 0) {
          finalSelectedUnits = isSingleSelectMode ? [newlySelectedUnits[0]] : newlySelectedUnits;
        } else {
          const lastSelected = normalizedSelectedUnits.find(id => !previousSelectionsSet.has(id));
          if (lastSelected) {
            finalSelectedUnits = [lastSelected];
          } else if (normalizedSelectedUnits.length > 0) {
            finalSelectedUnits = [normalizedSelectedUnits[normalizedSelectedUnits.length - 1]];
          }
        }

        handleValueChange(activeRowIndex, isSingleSelectMode ? finalSelectedUnits[0] : finalSelectedUnits);
      } else if (activeRowIndex === 1) {
        const parentUnit = props.localSelectedSegments[activeRowIndex - 1];
        if (parentUnit?.attribute === "Unit" && parentUnit.value) {
          const parentUnitId = Array.isArray(parentUnit.value) 
            ? parentUnit.value[0].toString() 
            : parentUnit.value.toString();
          
          const hasParentInSelected = normalizedSelectedUnits.includes(parentUnitId);
          
          const descendantSelections = normalizedSelectedUnits.filter(id => id !== parentUnitId);
          
          let selectedDescendants = descendantSelections;
          if (isSingleSelectMode && descendantSelections.length > 0) {
            selectedDescendants = [descendantSelections[0]];
          }
          
          finalSelectedUnits = hasParentInSelected 
            ? [parentUnitId, ...selectedDescendants]
            : [parentUnitId, ...selectedDescendants];
        } else {
          finalSelectedUnits = isSingleSelectMode && normalizedSelectedUnits.length > 0
            ? [normalizedSelectedUnits[0]]
            : normalizedSelectedUnits;
        }
      } else if (activeRowIndex > 0) {
        const parentUnit = props.localSelectedSegments[activeRowIndex - 1];
        if (parentUnit?.attribute === "Unit" && parentUnit.value) {
          const parentUnitId = Array.isArray(parentUnit.value) 
            ? parentUnit.value[0].toString() 
            : parentUnit.value.toString();
          
          const hasParentInSelected = normalizedSelectedUnits.includes(parentUnitId);
          
          const descendantSelections = normalizedSelectedUnits.filter(id => id !== parentUnitId);
          
          let selectedDescendants = descendantSelections;
          if (isSingleSelectMode && descendantSelections.length > 0) {
            selectedDescendants = [descendantSelections[0]];
          }
          
          finalSelectedUnits = hasParentInSelected 
            ? [parentUnitId, ...selectedDescendants]
            : [parentUnitId, ...selectedDescendants];
        } else {
          finalSelectedUnits = isSingleSelectMode && normalizedSelectedUnits.length > 0
            ? [normalizedSelectedUnits[0]]
            : normalizedSelectedUnits;
        }
      } else {
        finalSelectedUnits = isSingleSelectMode && normalizedSelectedUnits.length > 0
          ? [normalizedSelectedUnits[0]]
          : normalizedSelectedUnits;
      }
      
      clearAllUnitSelections(store.filterUnitList);
      finalSelectedUnits.forEach(unitId => {
        markUnitAsSelected(store.filterUnitList, unitId);
      });
      
      if (activeRowIndex > 0 && finalSelectedUnits.length > 1) {
        const parentUnit = props.localSelectedSegments[activeRowIndex - 1];
        if (parentUnit?.attribute === "Unit" && parentUnit.value) {
          const parentUnitId = Array.isArray(parentUnit.value) 
            ? parentUnit.value[0].toString() 
            : parentUnit.value.toString();
          
          const currentRowValue = finalSelectedUnits.filter(id => id !== parentUnitId);

          const valueToSet = isSingleSelectMode && currentRowValue.length > 0
            ? currentRowValue[0] 
            : currentRowValue;
            
          handleValueChange(activeRowIndex, valueToSet);
        } else {
          handleValueChange(
            activeRowIndex,
            isSingleSelectMode && finalSelectedUnits.length > 0
              ? finalSelectedUnits[0]
              : finalSelectedUnits
          );
        }
      } else {
        handleValueChange(
          activeRowIndex,
          isSingleSelectMode && finalSelectedUnits.length > 0
            ? finalSelectedUnits[0]
            : finalSelectedUnits
        );
      }
    }
    
    setShowUnitPopup(false);
    setActiveRowIndex(null);
  };

  const isDirectMembersItem = (item: TDashboardUnitFilter): boolean => {
    return (
      item.label.toLowerCase().includes("direct members") ||
      item.id.toString().includes("direct_members")
    );
  };

  const getLabelById = (id: string, items: TDashboardUnitFilter[], filterIndex: number): string => {
    const unitList = store.attributeList
      .find((el) => String(el.label) === "Unit")
      ?.list || [];
      
    const unit = unitList.find(item => item.id.toString() === id);
    
    if (!unit) {
      const findItemRecursively = (units: TDashboardUnitFilter[], targetId: string): TDashboardUnitFilter | null => {
        for (const unit of units) {
          if (unit.id.toString() === targetId) {
            return unit;
          }
          if (unit.children) {
            const found = findItemRecursively(unit.children, targetId);
            if (found) return found;
          }
        }
        return null;
      };
      
      const itemFromHierarchy = findItemRecursively(items, id);
      if (itemFromHierarchy) {
        return itemFromHierarchy.label;
      }
      
      return id;
    }
    
    const buildFullPath = (targetUnit: any): string[] => {
      const path = [targetUnit.name];
      let currentUnit = targetUnit;
      let visited = new Set([targetUnit.id]);
      
      while (currentUnit && currentUnit.parent_id) {
        if (visited.has(currentUnit.parent_id)) break;
        
        const parentUnit = unitList.find(u => u.id === currentUnit.parent_id);
        if (!parentUnit) break;
        
        path.unshift(parentUnit.name);
        visited.add(parentUnit.id);
        currentUnit = parentUnit;
      }
      
      return path;
    };
    
    const fullPath = buildFullPath(unit);
    
    if (filterIndex === 0) {
      return unit.name;
    }
    
    if (filterIndex === 1) {
      if (fullPath.length >= 2) {
        return `${fullPath[0]} > ${fullPath[fullPath.length - 1]}`;
      }
      return unit.name;
    }
    
    if (filterIndex >= 2) {
      if (fullPath.length >= 2) {
        return fullPath.join(" > ");
      }
    }
    
    return unit.name;
  };

  return (
    <>
      <Container>
        {props.localSelectedSegments.map((row, rowIndex, arraySegments) => (
          <FilterContainer
            $isLast={Boolean(rowIndex + 1 === props.localSelectedSegments.length)}
            key={rowIndex}
          >
            <FilterRow>
              <FilterRowContent>
                <LabelsRow>
                  {(() => {
                    const rowValue = arraySegments[rowIndex];
                    
                    if (Array.isArray(rowValue.value) && rowValue.value.length > 0) {
                      return (
                        <LabelGroup>
                          {rowValue.value.slice(0, 10).map((el, indexLabel) => (
                            <Label key={indexLabel}>
                              <span dangerouslySetInnerHTML={{ __html:  rowValue.attribute === "Unit"
                                ? getLabelById(el.toString(), store.filterUnitList, rowIndex)
                                : typeof el === 'string' ? decodeUnicode(el) : el}} />
                            </Label>
                          ))}
                          {rowValue.value.length > 10 && (
                            <CounterLabels>+ {rowValue.value.length - 10} more</CounterLabels>
                          )}
                          <ClearButon
                            variant="text"
                            onClick={() => props.clearLastAttribute(rowIndex)}
                          >
                            Clear all
                          </ClearButon>
                        </LabelGroup>
                      );
                    }
                    
                    if (rowValue.value) {
                      const isLastSelectedFilter = rowIndex === arraySegments.length - 1 || 
                        !arraySegments[rowIndex + 1].value;
                      
                      const valueStr = rowValue.value.toString();
                      
                      return (
                        <LabelGroup>
                          <Label>
                            <span dangerouslySetInnerHTML={{ __html: rowValue.attribute === "Unit"
                              ? getLabelById(valueStr, store.filterUnitList, rowIndex)
                              : decodeUnicode(valueStr)}} />
                          </Label>
                          {!isLastSelectedFilter && <ArrowForwardIosIcon />}
                        </LabelGroup>
                      );
                    }

                    return (
                      <LabelGroup>
                        <SelectLabel>Select segment</SelectLabel>
                      </LabelGroup>
                    );
                  })()}
                </LabelsRow>
                {rowIndex > 0 && (
                  <RemoveFilterButton
                    onClick={() => {
                      handleRemoveFilter(rowIndex);
                    }}
                    aria-label="remove filter"
                    disabled={topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp}
                  >
                    <CloseIcon />
                  </RemoveFilterButton>
                )}
              </FilterRowContent>
            </FilterRow>
            <FilterRow>
              <StyledSelectAttribute
                id="unit_or_attribute"
                value={row.attribute}
                onChange={(e: SelectChangeEvent<string>) => {
                  if (!topStore.isDashboardUnitFilterEnabled || !store.hasClosedPopUp) {
                    props.handleAttributeSelect(e, row, rowIndex);
                  }
                }}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
                disabled={topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp}
                renderValue={
                  row.attribute
                    ? () => row.attribute
                    : () => <Placeholder>Select attribute</Placeholder>
                }
              >
                {store.attributeList
                  .filter((el) => {
                    if (el.label === "Unit") {
                      const allUnits = store.attributeList
                        .find(attr => attr.label === "Unit")
                        ?.list || [];

                      for (let i = 0; i < rowIndex; i++) {
                        const prevRow = props.localSelectedSegments[i];
                        if (prevRow?.attribute === "Unit" && prevRow.value) {
                          const unitId = Array.isArray(prevRow.value) 
                            ? prevRow.value[0] 
                            : prevRow.value;
                          
                          const selectedUnit = allUnits.find(unit => unit.id.toString() === unitId);
                          
                          const hasChildren = selectedUnit && allUnits.some(unit => 
                            unit.parent_id === selectedUnit.id
                          );
                          
                          if (!hasChildren) return false;
                        }
                      }

                      return true;
                    }
                    return !props.localSelectedSegments.find((item) => item.attribute === el.label);
                  })
                  .map((attribute, index) => (
                    <MenuItem key={attribute.value + index} value={attribute.label}>
                      {attribute.label}
                    </MenuItem>
                  ))}
              </StyledSelectAttribute>
              <StyledSelectModeValue
                id="mode"
                value={row.mode}
                onChange={(e: SelectChangeEvent<string>) => handleModeSelect(e, row, rowIndex)}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                inputProps={{ "aria-label": "Without label" }}
                key={`mode-select-${rowIndex}-${row.mode}`}
                disabled={topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp}
                onClick={() => {
                  if (row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled && 
                      !(topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp)) {
                    setActiveRowIndex(rowIndex);
                    setShowUnitPopup(true);
                  }
                }}
                renderValue={
                  row.mode !== ""
                    ? undefined
                    : () => <Placeholder>Select mode of analysis</Placeholder>
                }
              >
                <MenuItem value={"multiple"}>Analyze multiple segments</MenuItem>
                <MenuItem value={"one"}>Select 1 segment to analyze deeper</MenuItem>
              </StyledSelectModeValue>
            </FilterRow>
            {row.mode && row.attribute && !(row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) && (
              <FilterSecondaryRow>
                {row.mode === "one" ? (
                  topStore.isDashboardUnitFilterEnabled ? (
                  <SingleSelectMenuItem
                    row={row}
                    rowIndex={rowIndex}
                    openSelects={openSelects}
                    handleSelectOpen={handleSelectOpen}
                    handleSelectClose={handleSelectClose}
                    handleValueChange={handleValueChange}
                    segmentSearchValue={store.segmentSearchValue}
                    setSegmentSearchValue={(value) => store.setData(value, "segmentSearchValue")}
                    attributeList={store.attributeList}
                    renderValue={renderValue}
                    disabled={topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp}
                  />
                  ) : (
                  <StyledSelectModeValue
                    id="value"
                    value={row.value || ""}
                    open={openSelects[rowIndex]}
                    onOpen={() => handleSelectOpen(rowIndex)}
                    onClose={() => handleSelectClose(rowIndex)}
                    onChange={(e: SelectChangeEvent<string>) => {
                      handleValueChange(rowIndex, e.target.value);
                    }}
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 220 } },
                      autoFocus: false,
                      disableAutoFocusItem: true,
                    }}
                    renderValue={
                      row.value !== ""
                        ? (value) => {
                            if (row.attribute === "Unit") {
                              const item = store.attributeList
                                .find((el) => String(el.label) === "Unit")
                                ?.list.find((item) => item.id.toString() === value);
                              return item ? `${item.name} (Level ${item.level})` : "";
                            }
                            return value as string;
                          }
                        : () => <Placeholder>Select segments</Placeholder>
                    }
                  >
                    <StyledListSubheader>
                      <StyledSearchInput
                        placeholder="Search for segment"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // Force rerender by toggling the select
                                  setOpenSelects(prev => {
                                    const newState = {...prev};
                                    return newState;
                                  });
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={store.segmentSearchValue || ""}
                        onChange={(e) => {
                          store.setData(e.target.value, "segmentSearchValue");
                          // Also trigger a rerender on text change
                          setOpenSelects(prev => ({...prev}));
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                          if (e.key === 'Enter') {
                            // Force rerender when Enter is pressed
                            setOpenSelects(prev => ({...prev}));
                          }
                        }}
                        autoComplete="off"
                      />
                    </StyledListSubheader>
                    {renderValue(row, rowIndex)}
                  </StyledSelectModeValue>
                  )
                ) : (
                  <MultipleSelectMenuItem
                    row={row}
                    rowIndex={rowIndex}
                    handleValueChange={handleValueChange}
                    getSegmentsList={getSegmentsList}
                    disabled={topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp}
                  />
                )}
              </FilterSecondaryRow>
            )}
          </FilterContainer>
        ))}
      </Container>

      {showUnitPopup && topStore.isDashboardUnitFilterEnabled && !(topStore.isDashboardUnitFilterEnabled && store.hasClosedPopUp) && (() => {
        let previousSelections: string[] = [];
        
        if (activeRowIndex !== null) {
            for (let i = 0; i < activeRowIndex; i++) {
            const prevRow = props.localSelectedSegments[i];
            if (prevRow?.attribute === "Unit" && prevRow.value) {
              if (Array.isArray(prevRow.value)) {
                previousSelections = [...previousSelections, ...prevRow.value.map(v => v.toString())];
              } else if (prevRow.value) {
                previousSelections = [...previousSelections, prevRow.value.toString()];
              }
            }
          }
        }
        
        const filterLevel = activeRowIndex !== null ? activeRowIndex + 1 : 1;
        const actualFilterLevel = filterLevel === 2 ? 2 : filterLevel;
        
        
        return (
          <UnitSelectionPopup
            open={showUnitPopup}
            onClose={() => setShowUnitPopup(false)}
            onSelect={handleUnitSelect}
            isSingleSelect={props.localSelectedSegments[activeRowIndex!]?.mode === "one"}
            units={store.filterUnitList}
            isEditMode={activeRowIndex !== 0 && props.localSelectedSegments[activeRowIndex!]?.value && 
                      (Array.isArray(props.localSelectedSegments[activeRowIndex!]?.value) 
                        ? props.localSelectedSegments[activeRowIndex!]?.value.length > 0 
                        : !!props.localSelectedSegments[activeRowIndex!]?.value)}
            isFirstFilter={activeRowIndex === 0}
            previousSelections={previousSelections}
            filterLevel={actualFilterLevel}
          />
        );
      })()}
    </>
  );
});

export default SettingsList;

const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorPaletteGrayBackground1);
  margin-top: 24px;
  border-radius: 10px;
`;

const FilterContainer = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  border-bottom: ${(props) =>
    props.$isLast ? "none" : "1px solid var(--colorPaletteBlueBackground1)"};
`;

const FilterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FilterSecondaryRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

const StyledSelectAttribute = styled(Select)`
  height: 39px;
  width: 170px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 8px;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const StyledSelectModeValue = styled(Select)`
  height: 39px;
  width: calc(100% - 178px) !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const LabelsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const Label = styled.span`
  padding: 2px 8px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorPaletteGreenBackground4);
  margin: 0px 4px 4px 0px;
`;

const Placeholder = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
`;

const ClearButon = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
`;

const LabelGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    font-size: 12px;
    margin-bottom: 4px;
    color: var(--colorNeutralForeground2);
  }
`;

const SelectLabel = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const CounterLabels = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  margin-bottom: 4px;
`;

const FilterRowContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const RemoveFilterButton = styled(IconButton)`
  && {
    padding: 4px;
    margin-left: 8px;
    color: var(--colorNeutralForeground3);
    
    &:hover {
      color: var(--colorNeutralForeground1);
      background-color: var(--colorNeutralBackground3);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    svg {
      font-size: 18px;
    }
  }
`;

const StyledListSubheader = styled(ListSubheader)`
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 8px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


const StyledSearchInput = styled(TextField)`
  && {
    width: calc(100% - 32px);
    margin: 8px 16px;

    .MuiInputBase-root {
      background-color: white;
      z-index: 1;
      position: relative;
      width: 100%;
    }

    .MuiInputBase-input {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
      padding: 8px 11px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
`;