import { FC } from "react";
import styled from "styled-components";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import { TDashboardUnitFilter } from "types/dashboard";

interface MultipleSelectMenuItemProps {
  row: { attribute: string; mode: string; value: string | string[] };
  rowIndex: number;
  handleValueChange: (rowIndex: number, value: string | string[]) => void;
  getSegmentsList: (filter: any, rowIndex: number) => any[];
  disabled?: boolean;
}

const MultipleSelectMenuItem: FC<MultipleSelectMenuItemProps> = ({
  row,
  rowIndex,
  handleValueChange,
  getSegmentsList,
  disabled = false,
}) => {
  return (
    <MultipleSelectWrapper>
      <MultipleSelect
        options={getSegmentsList(row, rowIndex)}
        selectedList={row.value && typeof row.value === "object" ? row.value : []}
        onChange={(changedIndex, field, value) => {
          const processedValue = Array.isArray(value) 
            ? value.map(v => typeof v === 'object' ? v.value : v)
            : typeof value === 'object' ? value.value : value;
          handleValueChange(changedIndex, processedValue);
        }}
        name="value"
        index={rowIndex}
      />
    </MultipleSelectWrapper>
  );
};

export default MultipleSelectMenuItem;

const MultipleSelectWrapper = styled.div`
  width: calc(100% - 178px);

  svg {
    font-size: 16px;
  }
`; 