import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { ExpandLess, ExpandMore, CheckCircle } from "@mui/icons-material";
import styled from "styled-components";
import { TDashboardUnitFilter } from "types/dashboard";
import { Radio } from "@mui/material";
import store from "features/Dashboard/hotspots/store";

interface SingleTreeSelectProps {
  items: TDashboardUnitFilter[];
  onSelect: (selectedIds: string[]) => void;
  searchTerm?: string;
  currentMatchIndex?: number;
  hideUnselectedDisabledItems?: boolean;
  value?: string[];
  previousSelections?: string[];
  mode?: "initial" | "edit";
  isFirstFilter?: boolean;
}

interface MatchInfo {
  id: string;
  label: string;
}

const getInitialSelectedItems = (items: TDashboardUnitFilter[], value?: string[]): string[] => {
  if (!value || value.length === 0) {
    return [];
  }

  const selectedIds: string[] = [];

  const findItemsById = (allItems: TDashboardUnitFilter[], ids: string[]) => {
    for (const item of allItems) {
      if (ids.includes(item.id)) {
        selectedIds.push(item.id);
      }
      if (item.children) {
        findItemsById(item.children, ids);
      }
    }
  };

  findItemsById(items, value);
  return selectedIds;
};

const isDescendantOf = (
  items: TDashboardUnitFilter[],
  itemId: string,
  potentialAncestorId: string,
): boolean => {
  const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const item = findItem(items, itemId);
  if (!item) return false;

  let currentItem = item;
  const findParent = (
    items: TDashboardUnitFilter[],
    childId: string,
  ): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some((child) => child.id === childId)) {
        return item;
      }
      if (item.children) {
        const found = findParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  while (true) {
    const parent = findParent(items, currentItem.id);
    if (!parent) break;
    if (parent.id === potentialAncestorId) return true;
    currentItem = parent;
  }

  return false;
};

const filterItemsByPreviousUnit = (
  items: TDashboardUnitFilter[],
  previousUnitId: string | null,
): TDashboardUnitFilter[] => {
  if (!previousUnitId) return items;

  const findUnit = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findUnit(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const previousUnit = findUnit(items, previousUnitId);
  if (!previousUnit) return [];

  if (previousUnit.children && previousUnit.children.length > 0) {
    return previousUnit.children;
  }

  return [];
};

const findParentUnits = (allItems: TDashboardUnitFilter[], selectedIds: string[]): string[] => {
  const parentUnits = new Set<string>();

  const findDirectParent = (
    items: TDashboardUnitFilter[],
    childId: string,
  ): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some((child) => child.id === childId)) {
        return item;
      }
      if (item.children) {
        const found = findDirectParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  selectedIds.forEach((id) => {
    let currentId = id;
    while (true) {
      const parent = findDirectParent(allItems, currentId);
      if (!parent) break;
      parentUnits.add(parent.id);
      currentId = parent.id;
    }
  });

  return Array.from(parentUnits);
};

export const SingleTreeSelect = forwardRef<
  {
    scrollToMatch: (itemId: string) => void;
    clearSelection: () => void;
    handleSelect: (id: string) => void;
    updateInternalSelection: (selections: string[]) => void;
    resetComponent: () => void;
  },
  SingleTreeSelectProps
>(
  (
    {
      items,
      onSelect,
      searchTerm,
      currentMatchIndex = 0,
      hideUnselectedDisabledItems = false,
      value,
      previousSelections = [],
      mode = "initial",
      isFirstFilter = true,
    },
    ref,
  ) => {
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
    const [matches, setMatches] = useState<MatchInfo[]>([]);
    const [currentMatchId, setCurrentMatchId] = useState<string>("");
    const [renderKey, setRenderKey] = useState<number>(0);
    const itemRefs = useRef<Map<string, HTMLElement>>(new Map());
    const [previousUnitId, setPreviousUnitId] = useState<string | null>(null);
    const [previouslySelectedItems, setPreviouslySelectedItems] = useState<Set<string>>(
      new Set(previousSelections),
    );

    const [isSelectionMode, setIsSelectionMode] = useState<boolean>(
      previousSelections.length === 0,
    );
    const isSecondFilterRef = useRef<boolean>(!isFirstFilter);

    const itemLevelMap = useRef<Map<string, number>>(new Map());

    const isFirstRenderRef = useRef(true);

    const isSecondFilter = () => {
      return !isFirstFilter;
    };

    useEffect(() => {
      isSecondFilterRef.current = !isFirstFilter;
    }, [previousSelections, mode, isFirstFilter]);

    useEffect(() => {
      itemLevelMap.current.clear();

      const buildLevelMap = (itemsList: TDashboardUnitFilter[], level: number) => {
        itemsList.forEach((item) => {
          itemLevelMap.current.set(item.id, level);

          if (item.children && item.children.length > 0) {
            buildLevelMap(item.children, level + 1);
          }
        });
      };

      buildLevelMap(items, 0);
    }, [items]);

    useEffect(() => {
      const currentPrevSelected = Array.from(previouslySelectedItems);
      const newPrevSelected = Array.from(new Set(previousSelections));

      const prevSelectionsChanged =
        JSON.stringify(currentPrevSelected.sort()) !== JSON.stringify(newPrevSelected.sort());

      if (prevSelectionsChanged) {
        setPreviouslySelectedItems(new Set(previousSelections));
      }

      if (previousSelections.length > 0) {
        if (isSelectionMode) {
          setIsSelectionMode(false);
        }
        isSecondFilterRef.current = true;
      }
    }, [previousSelections, isSelectionMode, previouslySelectedItems]);

    useEffect(() => {
      // Skip the first render check to ensure this effect runs when mode changes
      // This ensures selections are properly restored in edit mode

      let targetState: Set<string> | null = null;

      if (mode === "edit") {
        // For edit mode, always preserve previous selections
        const valueArray = Array.isArray(value) ? value : value ? [value] : [];
        const validIds = getInitialSelectedItems(items, valueArray);

        if (validIds.length > 0) {
          // In edit mode, maintain all selections from value
          targetState = new Set(validIds);

          // Store the selected unit with its details if there's a single selection
          if (validIds.length === 1) {
            const selectedItem = findItem(items, validIds[0]);
            if (selectedItem && store && typeof store.storeSelectedUnit === "function") {
              storeSelectedUnit(selectedItem);
            }
          }
        } else if (previousSelections.length > 0) {
          // If there are no valid IDs but there are previous selections, use those
          targetState = new Set(previousSelections);

          // Store the selected unit with its details
          const selectedItem = findItem(items, previousSelections[0]);
          if (selectedItem && store && typeof store.storeSelectedUnit === "function") {
            storeSelectedUnit(selectedItem);
          }
        } else {
          targetState = new Set();
        }
      } else {
        const valueArray = Array.isArray(value) ? value : value ? [value] : [];
        const validIds = getInitialSelectedItems(items, valueArray);

        if (validIds.length > 0) {
          if (!isFirstFilter && validIds.length > 1 && previousSelections.length > 0) {
            targetState = new Set(validIds);
          } else {
            targetState = new Set([validIds[0]]);

            // Store the selected unit with its details
            const selectedItem = findItem(items, validIds[0]);
            if (selectedItem && store && typeof store.storeSelectedUnit === "function") {
              storeSelectedUnit(selectedItem);
            }
          }
        } else {
          targetState = new Set();
        }
      }

      // Only update if values have changed
      const currentSelectedArraySorted = Array.from(selectedItems).sort();
      const targetStateArraySorted = Array.from(targetState).sort();

      if (JSON.stringify(currentSelectedArraySorted) !== JSON.stringify(targetStateArraySorted)) {
        setSelectedItems(targetState);
      }

      const itemsForExpansionCheck =
        mode === "edit"
          ? previousSelections.length > 0
            ? previousSelections
            : Array.isArray(value)
              ? value
              : value
                ? [value]
                : []
          : previousSelections.length > 0
            ? [previousSelections[previousSelections.length - 1]]
            : [];

      if (itemsForExpansionCheck.length > 0) {
        const newExpandedItems = new Set(expandedItems);
        let hasExpandChanges = false;

        itemsForExpansionCheck.forEach((id) => {
          const parentIds = getParentIds(items, id);
          parentIds.forEach((parentId) => {
            if (!newExpandedItems.has(parentId)) {
              newExpandedItems.add(parentId);
              hasExpandChanges = true;
            }
          });

          // For both modes, expand the item if it has children
          const item = findItem(items, id);
          if (item && item.children && item.children.length > 0 && !newExpandedItems.has(id)) {
            newExpandedItems.add(id);
            hasExpandChanges = true;
          }
        });

        const currentExpandedSorted = Array.from(expandedItems).sort();
        const newExpandedSorted = Array.from(newExpandedItems).sort();
        if (
          hasExpandChanges ||
          JSON.stringify(currentExpandedSorted) !== JSON.stringify(newExpandedSorted)
        ) {
          setExpandedItems(newExpandedItems);
        }
      }
    }, [items, value, mode, isFirstFilter, previousSelections, selectedItems, expandedItems]);

    const toggleExpand = (itemId: string, event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const newExpanded = new Set(expandedItems);
      if (newExpanded.has(itemId)) {
        const itemToCollapse = findItem(items, itemId);
        if (itemToCollapse) {
          const descendantIds = getAllDescendantIds(itemToCollapse);
          newExpanded.delete(itemId);
          descendantIds.forEach((id) => newExpanded.delete(id));
        }
      } else {
        newExpanded.add(itemId);
      }
      setExpandedItems(newExpanded);
    };

    const getAllDescendantIds = (item: TDashboardUnitFilter): string[] => {
      const ids: string[] = [];
      if (item.children) {
        for (const child of item.children) {
          // Skip direct members when collecting descendant IDs
          if (isDirectMembersItem(child)) {
            continue;
          }
          ids.push(child.id);
          ids.push(...getAllDescendantIds(child));
        }
      }
      return ids;
    };

    const ensureItemsExpanded = () => {
      const newExpandedItems = new Set(expandedItems);
      let hasChanges = false;

      if (previousUnitId) {
        if (!newExpandedItems.has(previousUnitId)) {
          newExpandedItems.add(previousUnitId);
          hasChanges = true;
        }

        const parentIds = getParentIds(items, previousUnitId);
        parentIds.forEach((parentId) => {
          if (!newExpandedItems.has(parentId)) {
            newExpandedItems.add(parentId);
            hasChanges = true;
          }
        });

        const previousUnit = findItem(items, previousUnitId);
        if (previousUnit && previousUnit.children && previousUnit.children.length > 0) {
          previousUnit.children.forEach((child) => {
            if (!newExpandedItems.has(child.id)) {
              newExpandedItems.add(child.id);
              hasChanges = true;
            }
          });
        }
      }

      selectedItems.forEach((itemId) => {
        if (!newExpandedItems.has(itemId)) {
          newExpandedItems.add(itemId);
          hasChanges = true;
        }

        const parentIds = getParentIds(items, itemId);
        parentIds.forEach((parentId) => {
          if (!newExpandedItems.has(parentId)) {
            newExpandedItems.add(parentId);
            hasChanges = true;
          }
        });
      });

      if (hasChanges) {
        setExpandedItems(newExpandedItems);
      }
    };

    useEffect(() => {
      const nodesToExpand = new Set<string>();

      previousSelections.forEach((id) => {
        if (mode === "edit") {
          nodesToExpand.add(id);
        }

        const parentIds = getParentIds(items, id);
        parentIds.forEach((parentId) => nodesToExpand.add(parentId));
      });

      if (selectedItems.size > 0) {
        selectedItems.forEach((id) => {
          if (mode === "edit") {
            nodesToExpand.add(id);
          }

          const parentIds = getParentIds(items, id);
          parentIds.forEach((parentId) => nodesToExpand.add(parentId));
        });
      }

      if (isFirstRenderRef.current) {
        setExpandedItems(nodesToExpand);
      }
    }, [items, previousSelections, selectedItems, mode]);

    useEffect(() => {
      if (mode === "edit" && !isFirstRenderRef.current) {
        const newExpandedItems = new Set(expandedItems);
        let hasChanges = false;

        previousSelections.forEach((id) => {
          if (!newExpandedItems.has(id)) {
            newExpandedItems.add(id);
            hasChanges = true;
          }

          const item = findItem(items, id);
          if (item && item.children && item.children.length > 0) {
            item.children.forEach((child) => {
              if (!newExpandedItems.has(child.id) && child.children && child.children.length > 0) {
                newExpandedItems.add(child.id);
                hasChanges = true;
              }
            });
          }
        });

        if (hasChanges) {
          setExpandedItems(newExpandedItems);
        }
      }
    }, [mode, previousSelections, items]);

    const handleSelect = (id: string) => {
      const item = findItem(items, id);
      if (!item) {
        return;
      }

      // Special handling for "direct members" item selection
      const isDirectMember = isDirectMembersItem(item);
      let newSelected = new Set<string>();

      if (!isFirstFilter && mode === "edit") {
        // For the second filter in edit mode, always include the first filter
        const firstFilterId = previousSelections.length > 0 ? previousSelections[0] : null;

        if (!firstFilterId) {
          const fallbackSelection = new Set<string>([id]);
          setSelectedItems(fallbackSelection);
          onSelect([id]);

          // Store the selected unit with its details
          storeSelectedUnit(item);
          return;
        }

        if (id === firstFilterId) {
          return; // Can't select the first filter again
        }

        // In edit mode for 2nd filter, we want to maintain only a single path
        // Always include the first filter
        const editModeSelection = new Set<string>([firstFilterId]);

        // Add the newly selected item
        editModeSelection.add(id);

        // Get the level of the newly selected item
        const selectedLevel = itemLevelMap.current.get(id) || 0;

        // When selecting a child unit in edit mode, exclude its immediate parent
        const parentIds = getParentIds(items, id);

        if (parentIds.length > 0) {
          // Get the immediate parent ID
          const immediateParentId = parentIds[parentIds.length - 1];

          // Include all higher level parents, but exclude the immediate parent
          const higherLevelParents = parentIds.filter((pid) => pid !== immediateParentId);
          higherLevelParents.forEach((parentId) => {
            editModeSelection.add(parentId);
          });
        }

        // Find and include level 0 units that are currently selected
        Array.from(selectedItems).forEach((selectedId) => {
          const level = itemLevelMap.current.get(selectedId) || 0;
          if (level === 0) {
            editModeSelection.add(selectedId);
          }
        });

        setSelectedItems(editModeSelection);
        onSelect(Array.from(editModeSelection));

        // Store the selected unit with its details
        storeSelectedUnit(item);
        return;
      } else if (isDirectMember) {
        // For direct members, don't include the parent unit ID (changed behavior)
        newSelected.add(id);
      } else if (!isFirstFilter) {
        // Regular second filter logic (not edit mode)
        const firstFilterId = previousSelections.length > 0 ? previousSelections[0] : null;
        if (!firstFilterId) {
          const fallbackSelection = new Set<string>([id]);
          setSelectedItems(fallbackSelection);
          onSelect([id]);

          // Store the selected unit with its details
          storeSelectedUnit(item);
          return;
        }

        newSelected.add(firstFilterId);
        newSelected.add(id);
      } else {
        // First filter normal selection
        newSelected = new Set<string>([id]);
      }

      // In both modes, update the item.selected property for visual state
      const clearAllSelectionsExcept = (allItems: TDashboardUnitFilter[], targetId: string) => {
        allItems.forEach((item) => {
          item.selected = item.id === targetId;

          if (item.children) {
            clearAllSelectionsExcept(item.children, targetId);
          }
        });
      };

      clearAllSelectionsExcept(items, id);

      // Only update renderKey if needed - prevent infinite loops
      if (!selectedItems.has(id)) {
        setRenderKey((prevKey) => prevKey + 1);
      }

      setPreviousUnitId(id);

      const newExpanded = new Set(expandedItems);

      const parentIds = getParentIds(items, id);
      parentIds.forEach((parentId) => {
        newExpanded.add(parentId);
      });

      // If selecting an item with children, expand it
      const selectedItem = findItem(items, id);
      if (selectedItem && selectedItem.children && selectedItem.children.length > 0) {
        newExpanded.add(id);
      }

      // Only update expandedItems if there's a change to prevent infinite loops
      const currentExpandedIds = Array.from(expandedItems).sort();
      const newExpandedIds = Array.from(newExpanded).sort();
      if (JSON.stringify(currentExpandedIds) !== JSON.stringify(newExpandedIds)) {
        setExpandedItems(newExpanded);
      }

      setIsSelectionMode(false);

      // Only update selectedItems if there's a change to prevent infinite loops
      const currentSelectedIds = Array.from(selectedItems).sort();
      const newSelectedIds = Array.from(newSelected).sort();
      if (JSON.stringify(currentSelectedIds) !== JSON.stringify(newSelectedIds)) {
        setSelectedItems(newSelected);
        onSelect(Array.from(newSelected));

        // Store the selected unit with its details
        storeSelectedUnit(item);
      }
    };

    // New function to store the selected unit in the store
    const storeSelectedUnit = (item: TDashboardUnitFilter) => {
      // Check if item has the necessary properties for a unit
      if (!item) return;

      // Get the item level from the map
      const level = itemLevelMap.current.get(item.id) || 0;

      // Get parent unit
      const parentUnit = getParentUnit(item);
      const parentId = parentUnit?.id;

      // Create unit data object with all available information
      const unitData = {
        id: item.id,
        name: item.label,
        level: level,
        parent_id: parentId,
        // Only include unitHeadEmails if it exists on the item
        ...(item.unitHeadEmails !== undefined && { unitHeadEmails: item.unitHeadEmails }),
      };

      // Store the unit data in the store
      if (store && typeof store.storeSelectedUnit === "function") {
        store.storeSelectedUnit(unitData);
      }
    };

    // Helper function to get the parent unit
    const getParentUnit = (item: TDashboardUnitFilter): TDashboardUnitFilter | null => {
      const parentIds = getParentIds(items, item.id);
      if (parentIds.length === 0) return null;

      const parentId = parentIds[parentIds.length - 1];
      return findItem(items, parentId);
    };

    const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
      for (const item of items) {
        if (item.id === id) return item;
        if (item.children) {
          const found = findItem(item.children, id);
          if (found) return found;
        }
      }
      return null;
    };

    const expandParents = (
      items: TDashboardUnitFilter[],
      targetId: string,
      parents: Set<string> = new Set(),
    ): boolean => {
      for (const item of items) {
        if (item.id === targetId) {
          return true;
        }
        if (item.children) {
          if (expandParents(item.children, targetId, parents)) {
            parents.add(item.id);
            return true;
          }
        }
      }
      return false;
    };

    const getParentIds = (
      items: TDashboardUnitFilter[],
      targetId: string,
      path: string[] = [],
    ): string[] => {
      for (const item of items) {
        if (item.id === targetId) {
          return path;
        }
        if (item.children) {
          const found = getParentIds(item.children, targetId, [...path, item.id]);
          if (found.length) return found;
        }
      }
      return [];
    };

    const findAllMatches = (items: TDashboardUnitFilter[]): MatchInfo[] => {
      const matches: MatchInfo[] = [];

      const searchItems = (item: TDashboardUnitFilter) => {
        if (item.label.toLowerCase().includes(searchTerm?.toLowerCase() || "")) {
          matches.push({ id: item.id, label: item.label });
        }

        if (item.children) {
          item.children.forEach(searchItems);
        }
      };

      items.forEach(searchItems);
      return matches;
    };

    useEffect(() => {
      if (searchTerm) {
        const foundMatches = findAllMatches(items);
        setMatches(foundMatches);

        if (foundMatches.length > 0) {
          const matchIndex = Math.min(currentMatchIndex, foundMatches.length) - 1;
          setCurrentMatchId(foundMatches[matchIndex]?.id || "");
        } else {
          setCurrentMatchId("");
        }

        const nodesToExpand = new Set<string>(expandedItems);
        let hasChanges = false;

        foundMatches.forEach((match) => {
          if (!nodesToExpand.has(match.id)) {
            nodesToExpand.add(match.id);
            hasChanges = true;
          }

          const parentIds = getParentIds(items, match.id);
          parentIds.forEach((id) => {
            if (!nodesToExpand.has(id)) {
              nodesToExpand.add(id);
              hasChanges = true;
            }
          });
        });

        if (hasChanges) {
          setExpandedItems(nodesToExpand);
        }
      } else {
        setMatches([]);
        setCurrentMatchId("");
      }
    }, [searchTerm, items, currentMatchIndex]);

    const renderHighlightedText = (text: string, itemId: string) => {
      if (!searchTerm) return text;

      const matchIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());
      if (matchIndex === -1) return text;

      const isCurrentMatch = itemId === currentMatchId;

      return (
        <span>
          {text.slice(0, matchIndex)}
          <HighlightedText
            $isCurrent={isCurrentMatch}
            ref={(el) => {
              if (el) {
                itemRefs.current.set(itemId, el);
              }
            }}
          >
            {text.slice(matchIndex, matchIndex + searchTerm.length)}
          </HighlightedText>
          {text.slice(matchIndex + searchTerm.length)}
        </span>
      );
    };

    const isDirectMembersItem = (item: TDashboardUnitFilter): boolean => {
      const label = item.label?.toLowerCase() || '';
      const id = item.id?.toString()?.toLowerCase() || '';
      
      return (
        // Check label patterns
        label.includes("direct members") ||
        label.includes("direct member") ||
        
        // Check ID patterns
        id.includes("direct_members") ||
        id.includes("direct-members") ||
        id.includes("-direct") ||
        id.includes("_direct") ||
        
        // Check for labels that end with direct members/member
        label.endsWith("direct members") ||
        label.endsWith("direct member")
      );
    };

    const getParentOfDirectMembers = (directMembersItem: TDashboardUnitFilter): string | null => {
      const parentIds = getParentIds(items, directMembersItem.id);
      return parentIds.length > 0 ? parentIds[parentIds.length - 1] : null;
    };

    const isItemDisabled = (itemId: string): boolean => {
      if (isFirstFilter) {
        return false;
      }

      const firstFilterId = previousSelections.length > 0 ? previousSelections[0] : null;

      if (!firstFilterId) {
        return false;
      }

      // First filter is always disabled for 2nd filter onwards in both modes
      if (itemId === firstFilterId) {
        return true;
      }

      // Get all parent units for both the first filter and the currently selected units
      const parentUnits = new Set<string>();

      // Add parents of first filter
      const firstFilterParents = getParentIds(items, firstFilterId);
      firstFilterParents.forEach((id) => parentUnits.add(id));

      // For both init and edit modes, disable all parent units
      if (parentUnits.has(itemId)) {
        return true;
      }

      // Special case for edit mode - check parent-child relationships
      if (mode === "edit" && selectedItems.size > 0) {
        // Use Array.from to iterate over the Set
        for (const selectedId of Array.from(selectedItems)) {
          if (selectedId === itemId) continue; // Skip self-check

          // Get the parent IDs of the selected item
          const selectedItemParents = getParentIds(items, selectedId);

          // In edit mode, if a child unit is selected, its immediate parent should not be disabled
          if (selectedItemParents.length > 0) {
            // Get the immediate parent of the selected item
            const immediateParentId = selectedItemParents[selectedItemParents.length - 1];

            // If this is the immediate parent of a selected item, don't disable it
            if (itemId === immediateParentId) {
              return false;
            }

            // Disable higher level parents
            const higherParents = selectedItemParents.filter((pid) => pid !== immediateParentId);
            if (higherParents.includes(itemId)) {
              return true;
            }
          }
        }
      }

      return false;
    };

    const findParentItem = (
      items: TDashboardUnitFilter[],
      targetId: string,
    ): TDashboardUnitFilter | null => {
      for (const item of items) {
        if (item.children?.some((child) => child.id === targetId)) {
          return item;
        }
        if (item.children) {
          const found = findParentItem(item.children, targetId);
          if (found) return found;
        }
      }
      return null;
    };

    const shouldHideItem = (itemId: string, isSelected: boolean): boolean => {
      if (!hideUnselectedDisabledItems) return false;
      return isItemDisabled(itemId) && !isSelected;
    };

    const hasAvailableItems = (): boolean => {
      if (!isFirstFilter && previousSelections.length === 0) {
        return true;
      }

      const lastSelectedId = previousSelections[previousSelections.length - 1];
      const selectedItem = findItem(items, lastSelectedId);

      if (!selectedItem) {
        return true;
      }

      let hasSelectableItems = false;

      const checkItems = (itemsList: TDashboardUnitFilter[]) => {
        for (const item of itemsList) {
          if (item.id === lastSelectedId || getParentIds(items, lastSelectedId).includes(item.id)) {
            continue;
          }

          // Skip checking direct members since we don't display them
          if (isDirectMembersItem(item)) {
            continue;
          }

          if (isDescendantOf(items, item.id, lastSelectedId)) {
            hasSelectableItems = true;
            return;
          }

          const parentItem = findParentItem(items, lastSelectedId);
          if (parentItem && parentItem.children?.some((child) => child.id === item.id)) {
            hasSelectableItems = true;
            return;
          }

          if (item.children && item.children.length > 0) {
            checkItems(item.children);
            if (hasSelectableItems) return;
          }
        }
      };

      checkItems(items);
      return hasSelectableItems;
    };

    const renderItem = (
      item: TDashboardUnitFilter,
      level: number = 0,
      isLastItem: boolean = false,
    ) => {
      // Skip rendering direct members items
      if (isDirectMembersItem(item)) {
        return null;
      }
      return renderItemContent(item, level, isLastItem);
    };

    const renderItemContent = (
      item: TDashboardUnitFilter,
      level: number = 0,
      isLastItem: boolean = false,
    ) => {
      // Double-check to skip direct members
      if (isDirectMembersItem(item)) {
        return null;
      }
      
      const hasChildren = item.children && item.children.length > 0;
      const isExpanded = expandedItems.has(item.id);
      const isSelected = selectedItems.has(item.id);
      let isDisabled = isItemDisabled(item.id);

      return (
        <ItemContainer key={`item-${item.id}-${renderKey}`} $level={level}>
          <ItemWrapper $isLastItem={isLastItem}>
            <ItemRow
              $isSelected={isSelected}
              $hasMatch={
                searchTerm ? item.label.toLowerCase().includes(searchTerm.toLowerCase()) : false
              }
              role="treeitem"
              tabIndex={-1}
              $isCurrentMatch={item.id === currentMatchId}
              $isDisabled={isDisabled}
              onClick={(e) => {
                if (!isDisabled) {
                  e.stopPropagation();
                  handleSelect(item.id);
                }
              }}
            >
              <CheckboxLabel>
                <CheckboxContainer>
                  <div
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      opacity: isDisabled ? 0.6 : 1,
                    }}
                    onClick={(e) => {
                      if (!isDisabled) {
                        e.stopPropagation();
                        handleSelect(item.id);
                      }
                    }}
                  >
                    <Radio
                      checked={isSelected}
                      disabled={isDisabled}
                      size="small"
                      data-item-id={item.id}
                      key={`radio-${item.id}-${renderKey}`}
                    />
                  </div>
                </CheckboxContainer>
                <ItemText $isDisabled={isDisabled} $isSelected={isSelected}>
                  {renderHighlightedText(item.label, item.id)}
                </ItemText>
                {hasChildren && (
                  <ExpandButton
                    onClick={(e) => toggleExpand(item.id, e)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ExpandButton>
                )}
              </CheckboxLabel>
            </ItemRow>
          </ItemWrapper>
          {hasChildren && isExpanded && (
            <ChildrenWrapper $isLastItem={isLastItem}>
              <ChildrenContainer>
                {item.children!
                  .filter(child => !isDirectMembersItem(child)) // Filter out direct members from children
                  .map((child, index, filteredChildren) => {
                    return renderItem(child, level + 1, index === filteredChildren.length - 1);
                  })}
              </ChildrenContainer>
            </ChildrenWrapper>
          )}
        </ItemContainer>
      );
    };

    const scrollToMatch = (itemId: string) => {
      const element = itemRefs.current.get(itemId);
      if (element) {
        setCurrentMatchId(itemId);
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    const clearSelection = () => {
      setSelectedItems(previouslySelectedItems);
      onSelect(Array.from(previouslySelectedItems));
    };

    const updateInternalSelection = (selections: string[]) => {
      const newSelectedItems = new Set(selections);

      const currentSelectionArray = Array.from(selectedItems);
      const newSelectionArray = Array.from(newSelectedItems);

      if (
        JSON.stringify(currentSelectionArray.sort()) !== JSON.stringify(newSelectionArray.sort())
      ) {
        setSelectedItems(newSelectedItems);
      }

      const newExpanded = new Set(expandedItems);
      let hasExpandChanges = false;

      selections.forEach((id) => {
        const parentIds = getParentIds(items, id);
        parentIds.forEach((parentId) => {
          if (!newExpanded.has(parentId)) {
            newExpanded.add(parentId);
            hasExpandChanges = true;
          }
        });
      });

      if (hasExpandChanges) {
        setExpandedItems(newExpanded);
      }

      const updatedItems = [...items];
      updateSelectionVisualState(updatedItems, selections);
    };

    const updateSelectionVisualState = (
      allItems: TDashboardUnitFilter[],
      selectedIds: string[],
    ) => {
      allItems.forEach((item) => {
        item.selected = selectedIds.includes(item.id);
        if (item.children) {
          updateSelectionVisualState(item.children, selectedIds);
        }
      });
    };

    const resetComponent = () => {
      setSelectedItems(new Set());
      setPreviouslySelectedItems(new Set());
    };

    useImperativeHandle(ref, () => ({
      scrollToMatch,
      clearSelection,
      handleSelect,
      updateInternalSelection,
      resetComponent,
    }));

    useEffect(() => {
      if (isFirstRenderRef.current) {
        isFirstRenderRef.current = false;
        return;
      }
    }, []);

    useEffect(() => {
      if (isFirstFilter) {
        return;
      }

      if (previousSelections.length > 0) {
        const lastSelectedId = previousSelections[previousSelections.length - 1];
        const selectedItem = findItem(items, lastSelectedId);

        if (selectedItem) {
          setPreviousUnitId(lastSelectedId);

          const itemLevel = itemLevelMap.current.get(lastSelectedId) || 0;

          if (selectedItems.size === 0 || (itemLevel >= 2 && !selectedItems.has(lastSelectedId))) {
            if (itemLevel >= 2) {
              const newSelection = new Set([lastSelectedId]);

              if (!areSetsEqual(selectedItems, newSelection)) {
                setSelectedItems(newSelection);
              }
            } else if (selectedItems.size > 0) {
              setSelectedItems(new Set());
            }
          }
        }
      }
    }, [isFirstFilter, previousSelections, items]);

    const areSetsEqual = (a: Set<string>, b: Set<string>): boolean => {
      if (a.size !== b.size) return false;
      return Array.from(a).every((value) => b.has(value));
    };

    const shouldShowInFallbackMode = (
      item: TDashboardUnitFilter,
      lastSelectedId: string,
    ): boolean => {
      if (!findItem(items, lastSelectedId)) {
        return true;
      }

      if (isDirectMembersItem(item)) {
        return true;
      }

      if (isDescendantOf(items, item.id, lastSelectedId)) {
        return true;
      }

      const parentItem = findParentItem(items, lastSelectedId);
      if (parentItem && parentItem.children?.some((child) => child.id === item.id)) {
        return true;
      }

      if (item.id === lastSelectedId || getParentIds(items, lastSelectedId).includes(item.id)) {
        return true;
      }

      return true;
    };

    const items_to_render =
      !items || items.length === 0
        ? []
        : items.filter((item) => {
            // Always skip direct members items regardless of other conditions
            if (isDirectMembersItem(item)) {
              return false;
            }

            // Always show previously selected items
            if (previousSelections.includes(item.id) || selectedItems.has(item.id)) {
              return true;
            }

            if (mode === "edit") {
              // In edit mode, show descendants of any selected items
              for (const selectedId of [...previousSelections, ...Array.from(selectedItems)]) {
                if (isDescendantOf(items, item.id, selectedId)) {
                  return true;
                }

                // Also show parent items of any selected items
                if (getParentIds(items, selectedId).includes(item.id)) {
                  return true;
                }

                // Show siblings of selected items (items with same parent)
                const parentIds = getParentIds(items, selectedId);
                if (parentIds.length > 0) {
                  const immediateParentId = parentIds[parentIds.length - 1];
                  const parentItem = findItem(items, immediateParentId);
                  if (
                    parentItem &&
                    parentItem.children &&
                    parentItem.children.some((child) => child.id === item.id)
                  ) {
                    return true;
                  }
                }
              }
            }

            // For first filter, show all top-level items
            return isFirstFilter;
          });

    if (
      !isFirstFilter &&
      previousSelections.length > 0 &&
      (items_to_render.length === 0 || !hasAvailableItems())
    ) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      const selectedItem = findItem(items, lastSelectedId);

      if (selectedItem) {
        items_to_render.length = 0;

        previousSelections.forEach((id) => {
          const item = findItem(items, id);
          if (item && !isDirectMembersItem(item)) {
            items_to_render.push(item);
          }
        });
      } else {
        items_to_render.length = 0;
        items.forEach((item) => {
          if (!isDirectMembersItem(item)) {
            items_to_render.push(item);
          }
        });
      }
    }

    useEffect(() => {
      if (!isFirstFilter && previousSelections.length > 0 && selectedItems.size === 0) {
        const lastSelectedId = previousSelections[previousSelections.length - 1];

        if (findItem(items, lastSelectedId)) {
          setSelectedItems(new Set([lastSelectedId]));
          setPreviousUnitId(lastSelectedId);

          const newExpandedItems = new Set(expandedItems);
          newExpandedItems.add(lastSelectedId);

          const parentIds = getParentIds(items, lastSelectedId);
          parentIds.forEach((parentId) => {
            newExpandedItems.add(parentId);
          });

          const selectedItem = findItem(items, lastSelectedId);
          if (selectedItem && selectedItem.children) {
            selectedItem.children.forEach((child) => {
              newExpandedItems.add(child.id);
            });
          }

          setExpandedItems(newExpandedItems);
        }
      }
    }, [previousSelections, items]);

    useEffect(() => {
      if (value) {
        const newSelectedItems = new Set(value);

        if (
          JSON.stringify(Array.from(newSelectedItems).sort()) !==
          JSON.stringify(Array.from(selectedItems).sort())
        ) {
          setSelectedItems(newSelectedItems);
        }
      }
    }, [value]);

    // Add this effect to handle mode changes
    useEffect(() => {
      // When mode changes to "edit", ensure previously selected items remain selected
      if (mode === "edit" && value && value.length > 0) {
        const valueArray = Array.isArray(value) ? value : [value];
        const validIds = getInitialSelectedItems(items, valueArray);

        if (validIds.length > 0) {
          const newSelectedItems = new Set(validIds);

          // Check if the selection state needs to be updated
          const currentSelectedArray = Array.from(selectedItems).sort();
          const newSelectedArray = Array.from(newSelectedItems).sort();

          if (JSON.stringify(currentSelectedArray) !== JSON.stringify(newSelectedArray)) {
            setSelectedItems(newSelectedItems);

            // If there's only one item selected, store it
            if (validIds.length === 1) {
              const selectedItem = findItem(items, validIds[0]);
              if (selectedItem && store && typeof store.storeSelectedUnit === "function") {
                storeSelectedUnit(selectedItem);
              }
            }
          }
        }
      }
    }, [mode, value, items, selectedItems]);

    return (
      <Container>
        <TreeSelectWrapper>
          <TreeSelectContainer>
            {!items || items.length === 0 ? (
              <div>No items to display</div>
            ) : items_to_render.length === 0 ? (
              <div>No matching items found. Please try a different selection.</div>
            ) : (
              <>
                {items_to_render.map((item, index) =>
                  renderItem(item, 0, index === items_to_render.length - 1),
                )}
              </>
            )}
          </TreeSelectContainer>
        </TreeSelectWrapper>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 10px 0;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
  min-height: 200px;
  background: #fafafa;
`;

const TreeSelectContainer = styled.div`
  padding-top: 8px;
  min-width: fit-content;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const ItemContainer = styled.div<{ $level: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${(props) => props.$level * 24}px;
  padding-bottom: 2px;

  ${(props) =>
    props.$level === 0 &&
    `
    > ${ItemWrapper}:before,
    > ${ItemWrapper}:after,
    > ${ChildrenWrapper}:before {
      display: none;
    }
  `}
`;

const ItemWrapper = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: -24px;
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: var(--colorBrandForeground2);
  }

  &:after {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${(props) => (props.$isLastItem ? "50%" : "110%")};
    background-color: var(--colorBrandForeground2);
  }
`;

const ItemRow = styled.div<{
  $isSelected: boolean;
  $hasMatch: boolean;
  $isDisabled?: boolean;
  $isCurrentMatch?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${(props) => {
    if (props.$isSelected && props.$isDisabled) return "var(--colorPaletteGreenBackground4)";
    if (props.$isSelected) return "var(--colorNeutralBackground2)";
    return "var(--colorNeutralBackground1)";
  }};
  margin: 2px 0;
  width: fit-content;
  border: 1px solid
    ${(props) => {
      if (props.$isCurrentMatch) return "#6B4EFF";
      if (props.$isSelected) return "var(--colorBrandForeground3)";
      return "transparent";
    }};
  min-height: 36px;
  transition: all 0.3s;
  outline: none;
  box-shadow: ${(props) => (props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "none")};
  opacity: ${(props) => (props.$isDisabled && !props.$isSelected ? 0.7 : 1)};
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => {
      if (props.$isDisabled) {
        if (props.$isSelected) return "var(--colorNeutralBackground2)";
        return "var(--colorNeutralBackground1)";
      }
      if (props.$isSelected) return "var(--colorNeutralBackground2)";
      return "var(--colorNeutralBackground3)";
    }};
    transform: ${(props) => (props.$isDisabled ? "none" : "translateY(-1px)")};
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 2px 4px rgba(0, 0, 0, 0.1)"};
  }

  &:active {
    transform: ${(props) => (props.$isDisabled ? "none" : "translateY(0)")};
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 1px 2px rgba(0, 0, 0, 0.1)"};
  }

  &:focus {
    box-shadow: ${(props) =>
      props.$isDisabled ? "0 0 0 1px var(--colorNeutralStroke2)" : "0 0 0 2px #6B4EFF"};
  }
`;

const ItemText = styled.div<{ $isDisabled: boolean; $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => {
    if (props.$isDisabled && !props.$isSelected) return "var(--colorNeutralForeground3)";
    if (props.$isDisabled && props.$isSelected) return "var(--colorNeutralForeground1)";
    if (props.$isSelected) return "var(--colorNeutralForeground1)";
    return "inherit";
  }};
  font-weight: ${(props) => (props.$isSelected ? "500" : "normal")};
`;

const DisabledNote = styled.span`
  font-size: 11px;
  color: var(--colorNeutralForeground3);
  font-style: italic;
  margin-top: 2px;
`;

const SelectedNote = styled.span`
  font-size: 11px;
  color: var(--colorBrandForeground3);
  display: flex;
  align-items: center;
  margin-top: 2px;

  svg {
    color: var(--colorBrandForeground3);
    font-size: 16px;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-height: 24px;
  cursor: pointer;

  span {
    user-select: none;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const ExpandButton = styled.button`
  border: none;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: var(--colorBrandForeground3);
  }

  &:active {
    background-color: var(--colorNeutralBackground1);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const ChildrenWrapper = styled.div<{ $isLastItem?: boolean }>`
  position: relative;
  display: flex;

  &:before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${(props) => (props.$isLastItem ? "0" : "calc(100% + 12px)")};
    background-color: var(--colorBrandForeground2);
  }
`;

const HighlightedText = styled.span<{ $isCurrent: boolean }>`
  color: #6b4eff;
  font-weight: 600;
  background-color: ${(props) => (props.$isCurrent ? "#F5F3FF" : "transparent")};
  padding: ${(props) => (props.$isCurrent ? "2px 4px" : "0")};
  border-radius: ${(props) => (props.$isCurrent ? "4px" : "0")};
  scroll-margin: 100px;
`;

const TreeSelectWrapper = styled.div.attrs({ className: "TreeSelectWrapper" })`
  flex: 1;
  overflow: auto;
  padding-bottom: 24px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;
