import { FC, useState, useEffect, useRef } from "react";
import { Checkbox, Box, Typography, TextField, InputAdornment, IconButton, Popper, Paper, ClickAwayListener, List } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styled from "styled-components";
import { TDashboardUnitFilter } from "types/dashboard";
import hotspotsStore from "../store";

interface SingleSelectMenuItemProps {
  row: { attribute: string; mode: string; value: string | string[] };
  rowIndex: number;
  openSelects: { [key: number]: boolean };
  handleSelectOpen: (index: number) => void;
  handleSelectClose: (index: number) => void;
  handleValueChange: (rowIndex: number, value: string | string[]) => void;
  segmentSearchValue: string;
  setSegmentSearchValue: (value: string) => void;
  attributeList: any[];
  renderValue: (row: { attribute: string; mode: string; value: string | string[] }, rowIndex: number) => React.ReactNode;
  disabled?: boolean;
}

const SingleSelectMenuItem: FC<SingleSelectMenuItemProps> = ({
  row,
  rowIndex,
  openSelects,
  handleSelectOpen,
  handleSelectClose,
  handleValueChange,
  segmentSearchValue,
  setSegmentSearchValue,
  attributeList,
  renderValue,
  disabled = false,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [previousUnitId, setPreviousUnitId] = useState<string | null>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  
  // Access the dashboard_unit_filter feature flag from the store
  const showCheckboxAndIndentation = hotspotsStore.dashboard_unit_filter;

  useEffect(() => {
    // Initialize selected values from row.value
    if (row.value) {
      if (Array.isArray(row.value)) {
        setSelectedValues(row.value);
      } else {
        setSelectedValues([row.value.toString()]);
      }
    } else {
      setSelectedValues([]);
    }
  }, [row.value]);

  // Check if we're dealing with Unit selection
  const isUnitSelection = row.attribute === "Unit";

  // Handle checkbox change
  const handleCheckboxChange = (value: string, event: React.MouseEvent<HTMLElement>) => {
    // Stop propagation to prevent the dropdown from closing
    event.stopPropagation();
    
    let newSelectedValues: string[];

    if (isUnitSelection) {
      // For Unit selection, handle the special case
      const unitList = attributeList.find((el) => String(el.label) === "Unit")?.list || [];
      const selectedUnit = unitList.find(unit => unit.id.toString() === value);
      
      if (!selectedUnit) return;

      const unitTopParentId = getTopLevelParentId(selectedUnit, unitList);
      
      // If the unit is already selected, just remove it (no auto-removal of descendants)
      if (selectedValues.includes(value)) {
        newSelectedValues = selectedValues.filter(v => v !== value);
      } else {
        // If selecting a unit from a different hierarchy, clear previous selections
        if (previousUnitId && unitTopParentId !== previousUnitId) {
          // Clear all previous selections and select only this new unit
          newSelectedValues = [value];
          setPreviousUnitId(unitTopParentId);
        } else {
          // First selection or same hierarchy, just add this unit
          if (!previousUnitId) {
            setPreviousUnitId(unitTopParentId);
          }
          newSelectedValues = [...selectedValues, value];
        }
      }
    } else {
      // For regular attributes, just toggle the selection
      if (selectedValues.includes(value)) {
        newSelectedValues = selectedValues.filter(v => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
    }

    setSelectedValues(newSelectedValues);
    handleValueChange(rowIndex, newSelectedValues.length === 1 ? newSelectedValues[0] : newSelectedValues);
  };

  // Get the top-level parent ID of a unit
  const getTopLevelParentId = (unit: any, unitList: any[]): string => {
    let currentUnit = unit;
    while (currentUnit.parent_id) {
      const parentUnit = unitList.find(u => u.id === currentUnit.parent_id);
      if (!parentUnit) break;
      currentUnit = parentUnit;
    }
    return currentUnit.id.toString();
  };

  // Get all ancestor unit IDs for a given unit
  const getAncestorUnitIds = (unit: any, unitList: any[]): string[] => {
    const ancestors: string[] = [];
    let currentUnit = unit;
    
    while (currentUnit.parent_id) {
      const parentUnit = unitList.find(u => u.id === currentUnit.parent_id);
      if (!parentUnit) break;
      ancestors.push(parentUnit.id.toString());
      currentUnit = parentUnit;
    }
    
    return ancestors;
  };

  // Get all descendant unit IDs for a given unit ID
  const getDescendantUnitIds = (unitId: string, unitList: any[]): string[] => {
    const descendants: string[] = [];
    
    const findDescendants = (id: string) => {
      const childUnits = unitList.filter(unit => unit.parent_id === Number(id));
      childUnits.forEach(child => {
        descendants.push(child.id.toString());
        findDescendants(child.id.toString());
      });
    };
    
    findDescendants(unitId);
    return descendants;
  };

  // Render unit items with proper hierarchy and selection logic
  const renderUnitItems = () => {
    const unitList = attributeList.find((el) => String(el.label) === "Unit")?.list || [];
    
    // Filter units based on search
    const filteredUnits = unitList.filter(unit => {
      if (!unit?.name) return false;
      
      // Apply search filter if there's a search term
      if (segmentSearchValue) {
        return unit.name.toLowerCase().includes(segmentSearchValue.toLowerCase());
      }
      
      return true; // Show all units, even from different hierarchies
    });

    return filteredUnits.map((unit, index) => {
      const unitId = unit.id.toString();
      const isSelected = selectedValues.includes(unitId);
      
      // Calculate indentation based on level
      const indentLevel = showCheckboxAndIndentation ? unit.level - 1 : 0;
      
      return (
        <CustomMenuItem 
          key={unitId + index}
          onClick={(e) => e.stopPropagation()}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', ml: indentLevel * 2, width: '100%' }}>
            {showCheckboxAndIndentation && (
              <StyledCheckbox 
                checked={isSelected}
                onClick={(e) => handleCheckboxChange(unitId, e)}
                color="primary"
                size="small"
              />
            )}
            <ItemText onClick={(e) => handleCheckboxChange(unitId, e)}>
              {`${unit.name} (Level ${unit.level})`}
            </ItemText>
          </Box>
        </CustomMenuItem>
      );
    });
  };

  // Render regular (non-Unit) items
  const renderRegularItems = () => {
    const items = attributeList
      .find((el) => String(el.label) === String(row.attribute))
      ?.list.filter((item) => {
        if (!item?.value) return false;
        // Only filter if there's an active search
        if (segmentSearchValue) {
          return item.value.toLowerCase().includes(segmentSearchValue.toLowerCase());
        }
        return true;
      }) || [];

    return items.map((item, index) => {
      const value = item.value.toString();
      const isSelected = selectedValues.includes(value);
      
      return (
        <CustomMenuItem 
          key={value + index}
          onClick={(e) => e.stopPropagation()}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {showCheckboxAndIndentation && (
              <StyledCheckbox 
                checked={isSelected}
                onClick={(e) => handleCheckboxChange(value, e)}
                color="primary"
                size="small"
              />
            )}
            <ItemText onClick={(e) => handleCheckboxChange(value, e)}>
              {value}
            </ItemText>
          </Box>
        </CustomMenuItem>
      );
    });
  };

  // Format the display value for the select
  const formatDisplayValue = () => {
    if (selectedValues.length === 0) {
      return <Placeholder>Select segments</Placeholder>;
    }

    if (isUnitSelection) {
      const unitList = attributeList.find((el) => String(el.label) === "Unit")?.list || [];
      const selectedUnit = unitList.find(unit => unit.id.toString() === selectedValues[0]);
      
      if (selectedValues.length === 1 && selectedUnit) {
        return `${selectedUnit.name} (Level ${selectedUnit.level})`;
      }
      
      return `${selectedValues.length} units selected`;
    }
    
    if (selectedValues.length === 1) {
      return selectedValues[0];
    }
    
    return `${selectedValues.length} items selected`;
  };

  const toggleDropdown = () => {
    if (disabled) return;
    
    if (openSelects[rowIndex]) {
      handleSelectClose(rowIndex);
    } else {
      handleSelectOpen(rowIndex);
    }
  };

  return (
    <div style={{ width: 'calc(100% - 178px)' }}>
      <SelectWrapper ref={anchorRef} onClick={toggleDropdown} $disabled={disabled}>
        <SelectDisplay>
          {formatDisplayValue()}
        </SelectDisplay>
        <IconWrapper>
          {openSelects[rowIndex] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconWrapper>
      </SelectWrapper>

      <Popper
        open={openSelects[rowIndex] || false}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        style={{ width: anchorRef.current?.offsetWidth, zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={() => handleSelectClose(rowIndex)}>
          <Paper elevation={8}>
            <SearchHeader>
              <StyledSearchInput
                placeholder="Search for segment"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={segmentSearchValue || ""}
                onChange={(e) => {
                  setSegmentSearchValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                autoComplete="off"
                fullWidth
              />
            </SearchHeader>
            <ItemsList>
              {isUnitSelection ? renderUnitItems() : renderRegularItems()}
            </ItemsList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default SingleSelectMenuItem;

const SelectWrapper = styled.div<{ $disabled?: boolean }>`
  height: 39px;
  width: 100%;
  background-color: ${props => props.$disabled ? 'var(--colorNeutralBackground3)' : 'var(--colorNeutralBackground1)'};
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  opacity: ${props => props.$disabled ? 0.7 : 1};
`;

const SelectDisplay = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

const Placeholder = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
  margin: 0;
`;

const SearchHeader = styled.div`
  padding: 8px;
  border-bottom: 1px solid var(--colorNeutralForeground4);
`;

const StyledSearchInput = styled(TextField)`
  && {
    .MuiInputBase-root {
      background-color: white;
      z-index: 1;
      position: relative;
      width: 100%;
    }

    .MuiInputBase-input {
      color: rgba(0, 0, 0, 0.87);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
      padding: 8px 11px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
`;

const ItemsList = styled(List)`
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
`;

const CustomMenuItem = styled.div`
  padding: 4px 16px;
  height: auto;
  min-height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 4px 8px 4px 0;
`;

const ItemText = styled(Typography)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`; 