import { observer } from "mobx-react";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import { getUnitName, getUnitSegmentsName } from "utils/dashboard/useGetSegmentName";
import { DASHBOARD_MENU_VALUES } from "constants/dashboard-menu";
import { FC, useEffect, useMemo, useState } from "react";
import { TDasbboardFilter, TDashboardGlobalFilter, TDashboardUnitFilter } from "types/dashboard";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import BelowThresholdLabel from "./BelowThresholdLabel";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import CustomButton from "components/button/Button";
import SecondaryCustomButton from "components/button/SecondaryButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import UnitSelectionPopup from "./UnitSelectionPopup";
import topStore  from "features/Dashboard/store";
import DiscardFilterPopupChanges from "./DiscardFilterChangesPopup";

type FiltersPopupProps = {
  open: boolean;
  handleHide: () => void;
  surveyId: number;
  units: TDashboardUnitFilter[];
};

const FiltersPopup: FC<FiltersPopupProps> = observer((props) => {
  const [localFilters, setLocalFilters] = useState<TDasbboardFilter[]>([
    {
      unit_or_attr: null,
      segments: [],
    },
  ]);
  const [initialFilters, setInitialFilters] = useState<TDasbboardFilter[]>([]);
  const [showUnitSelection, setShowUnitSelection] = useState(false);
  const [activeFilterIndex, setActiveFilterIndex] = useState<number | null>(null);
  const [units, setUnits] = useState<TDashboardUnitFilter[]>(props.units);
  const [isLoadingResponseCount, setIsLoadingResponseCount] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);
  const [removingFilterIndex, setRemovingFilterIndex] = useState<number | null>(null);
  const [resetUnitSearch, setResetUnitSearch] = useState(false);

  const areFiltersEqual = (filters1: TDasbboardFilter[], filters2: TDasbboardFilter[]): boolean => {
    if (filters1.length !== filters2.length) return false;

    return filters1.every((filter1, index) => {
      const filter2 = filters2[index];

      // Compare unit_or_attr
      if (!filter1.unit_or_attr && !filter2.unit_or_attr) return true;
      if (!filter1.unit_or_attr || !filter2.unit_or_attr) return false;
      if (filter1.unit_or_attr.id !== filter2.unit_or_attr.id) return false;

      // Compare segments
      if (filter1.segments.length !== filter2.segments.length) return false;
      return filter1.segments.every((segment, i) => segment === filter2.segments[i]);
    });
  };

  const closePopup = () => {
    const isFiltersUnchanged = areFiltersEqual(localFilters, initialFilters);

    if (isFiltersUnchanged) {
      props.handleHide();
      return;
    }

    setShowDiscardDialog(true);
  };

  useEffect(() => {
    if (props.open) {
      const filters = store.filters;
      setLocalFilters(filters);
      setInitialFilters(filters);
    }
  }, [props.open]);

  useEffect(() => {
    setUnits(props.units);
  }, [props.units]);

  useEffect(() => {
    if (props.surveyId && store.activeTab !== DASHBOARD_MENU_VALUES.trends)
      store.getSurveyAttributes(StoreLayout.currentWorkspaceId, StoreLayout.currentEntityId, store.selectedProject.id, props.surveyId);
    else if (StoreLayout.currentEntityId && store.activeTab === DASHBOARD_MENU_VALUES.trends)
      store.getProjectAttributes(store.selectedProject.id);
  }, [props.surveyId, store.selectedProject, store.role, store.secondaryRole]);

  const addFilter = () => {
    if (
      localFilters[localFilters.length - 1].unit_or_attr &&
      localFilters[localFilters.length - 1].segments.length > 0
    ) {
      setLocalFilters((prev) => [
        ...prev,
        {
          unit_or_attr: null,
          segments: [],
        },
      ]);
    }
  };

  const removeFilter = async (removeIndex: number) => {
    setRemovingFilterIndex(removeIndex);
    setIsLoadingResponseCount(true);

    try {
      if (localFilters[removeIndex].unit_or_attr?.name === 'Unit') {
        const updateUnitSelection = (unitList: TDashboardUnitFilter[]): TDashboardUnitFilter[] => {
          return unitList.map(unit => ({
            ...unit,
            selected: false,
            children: unit.children?.length ? updateUnitSelection(unit.children) : []
          }));
        };

        const updatedUnits = updateUnitSelection(units);
        setUnits(updatedUnits);
      }

      let updatedFilters;
      if (localFilters.length === 1) {
        updatedFilters = [
          {
            unit_or_attr: null,
            segments: [],
          },
        ];
      } else {
        updatedFilters = localFilters.filter((_, index) => index !== removeIndex);
      }

      setLocalFilters(updatedFilters);
      await checkConfidentialityThreshold(updatedFilters);
    } finally {
      setIsLoadingResponseCount(false);
      setRemovingFilterIndex(null);
    }
  };

  const changeUnitOrAttribute = (changeIndex: number, value: TDashboardGlobalFilter) => {
    setResetUnitSearch(prev => !prev);
    if (value?.name === "Unit") {
      if (topStore.isDashboardUnitFilterEnabled) {
        setActiveFilterIndex(changeIndex);
        setShowUnitSelection(true);
      }
    }

    setLocalFilters((prev) =>
      prev.map((filter, index) => {
        if (index === changeIndex) {
          return {
            unit_or_attr: value,
            segments: [],
          };
        } else return filter;
      })
    );
  };

  const handleUnitSelection = async (selectedUnits: string[]) => {
    if (activeFilterIndex !== null) {
      const cleanedUnits = Array.from(new Set(
        selectedUnits.map(unitId => String(unitId))
      ));

      setLocalFilters((prev) =>
        prev.map((filter, index) => {
          if (index === activeFilterIndex) {
            return {
              ...filter,
              segments: cleanedUnits,
            };
          } else return filter;
        })
      );

      const updateUnitSelection = (unitList: TDashboardUnitFilter[]) => {
        return unitList.map(unit => ({
          ...unit,
          selected: selectedUnits.includes(unit.id),
          children: unit.children?.length ? updateUnitSelection(unit.children) : []
        }));
      };

      const updatedUnits = updateUnitSelection(units);
      setUnits(updatedUnits);

      const updatedFilters = localFilters.map((filter, index) => {
        if (index === activeFilterIndex) {
          return {
            ...filter,
            segments: cleanedUnits,
          };
        } else return filter;
      });

      await checkConfidentialityThreshold(updatedFilters);
    }

    setShowUnitSelection(false);
    setActiveFilterIndex(null);
  };

  const changeSegments = (changeIndex: number, field: string, value: string | string[]) => {
    setLocalFilters((prev) =>
      prev.map((filter, index) => {
        if (index === changeIndex) {
          return { ...filter, [field]: value };
        } else return filter;
      })
    );
  };

  const checkConfidentialityThreshold = async (filters: TDasbboardFilter[]) => {
    const filtersToCheck = filters
      .filter(filter => filter.unit_or_attr && filter.segments.length > 0)
      .map(filter => ({
        id: filter.unit_or_attr.id,
        name: filter.unit_or_attr.name,
        segments: filter.segments
      }));

    if (filtersToCheck.length > 0) {
      setIsLoadingResponseCount(true);
      const isBelowThreshold = await store.checkConfidentialityThreshold(filtersToCheck);
      setIsBelowThreshold(isBelowThreshold);
      setIsLoadingResponseCount(false);
    } else {
      setIsBelowThreshold(false);
    }
  };

  const filteringLocalListFilters = useMemo(() => {
    const filteredArray = store.filterAttributesList.filter(
      (filter) =>
        !localFilters.some((localFilter) => localFilter.unit_or_attr?.name === filter?.name)
    );
    return filteredArray;
  }, [localFilters, store.filterAttributesList]);

  const getSegmentsList = (filter: TDasbboardFilter) => {
    if (filter.unit_or_attr?.name === "Unit")
      return (
        filter.unit_or_attr?.values.map((el) => ({
          label: `${el?.name} (Level ${el.level})`,
          value: el.id.toString(),
        })) ?? []
      );

    return filter.unit_or_attr?.values.map((el) => el.value) ?? [];
  };

  const applyFilters = () => {
    props.handleHide();
    store.changeFilters(localFilters, units)
  };

  const getSegmentDisplay = (filter: TDasbboardFilter) => {
    const segments = filter.segments.map(segment => 
      getUnitSegmentsName(filter, segment, store.filterAttributesList, topStore.isDashboardUnitFilterEnabled, units)
    );
    
    const attributeName = filter.unit_or_attr?.name;
    const displaySegments = segments.slice(0, 3).join(", ");
    
    if (segments.length > 3) {
      const remainingCount = segments.length - 3;
      return `${attributeName}: ${displaySegments} +${remainingCount} more`;
    }
    
    return `${attributeName}: ${displaySegments}`;
  };

  const getAllSelectedUnits = (units: TDashboardUnitFilter[]): TDashboardUnitFilter[] => {
    let selectedUnits: TDashboardUnitFilter[] = [];
    
    units.forEach(unit => {
      if (unit.selected) {
        selectedUnits.push(unit);
      }
      if (unit.children?.length) {
        selectedUnits = [...selectedUnits, ...getAllSelectedUnits(unit.children)];
      }
    });
    
    return selectedUnits;
  };

  const handleDiscardChanges = async () => {
    setLocalFilters(initialFilters);
    setUnits(props.units);

    setShowDiscardDialog(false);
    props.handleHide();
  };

  const handleCloseDiscardDialog = () => {
    setShowDiscardDialog(false);
  };

  return (
    <>
      <StyledDialog open={props.open && !showDiscardDialog}>
        <CloseBtnWrapper>
          <CloseButton onClick={closePopup} disabled={isLoadingResponseCount}>
            <ClearIcon />
          </CloseButton>
        </CloseBtnWrapper>
        <DialogTitle>Select filters</DialogTitle>
        {isBelowThreshold && <BelowThresholdLabel />}

        <StyledDialogContent>
          {store.activeTab === DASHBOARD_MENU_VALUES.trends && (
            <TrendsLabel>
              <ErrorOutlineIcon />
              <TrendsLabelText>
                Filters on 'Trend results' are based on respondents within all surveys of a project
              </TrendsLabelText>
            </TrendsLabel>
          )}
          {localFilters.map((filter, index) => (
            <FilterColumn key={index} $index={index}>
              <FilterRow>
                <FilterControls>
                  <StyledAutocomplete
                    disablePortal
                    id="select_unit_or_attribute"
                    onChange={(_, value: TDashboardGlobalFilter) => changeUnitOrAttribute(index, value)}
                    value={filter.unit_or_attr}
                    options={filteringLocalListFilters}
                    getOptionLabel={(option: any) => option.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} placeholder="Select attribute" />}
                    ListboxProps={{ style: { maxHeight: 150, overflow: "auto" } }}
                  />
                  {filter.unit_or_attr && (
                    filter.unit_or_attr.name === "Unit" ? (
                      topStore.isDashboardUnitFilterEnabled ? (
                        <ReselectUnitsButton 
                          disabled={isLoadingResponseCount}
                          onClick={() => {
                            setActiveFilterIndex(index);
                            setShowUnitSelection(true);
                          }}
                        >
                          Reselect units
                        </ReselectUnitsButton>
                      ) : (
                        <MultipleSelect
                          options={getSegmentsList(filter)}
                          selectedList={filter.segments}
                          onChange={changeSegments}
                          name="segments"
                          index={index}
                          onClose={() => {checkConfidentialityThreshold(localFilters)}}
                          resetSearch={resetUnitSearch}
                        />
                      )
                    ) : (
                      <MultipleSelect
                        options={getSegmentsList(filter)}
                        selectedList={filter.segments}
                        onChange={changeSegments}
                        name="segments"
                        index={index}
                        onClose={() => {checkConfidentialityThreshold(localFilters)}}
                        resetSearch={resetUnitSearch}
                      />
                    )
                  )}
                </FilterControls>
                {localFilters.length === 1 &&
                  !localFilters[0].unit_or_attr &&
                  localFilters[0].segments.length === 0 ? null : (
                  <RemoveFilterButton
                    onClick={() => removeFilter(index)}
                    disabled={removingFilterIndex !== null || isLoadingResponseCount}
                  >
                    Remove
                  </RemoveFilterButton>
                )}
              </FilterRow>
              {filter.segments.length > 0 && (
                <SegmentsRow>
                  {filter.unit_or_attr?.name === "Unit" && topStore.isDashboardUnitFilterEnabled ? (
                    <>
                      {getAllSelectedUnits(units)
                        .slice(0, 10)
                        .map((unit) => (
                          <StyledChip
                            key={unit.id}
                            label={getUnitName(unit)}
                            size="small"
                          />
                        ))}
                      {getAllSelectedUnits(units).length > 10 && (
                        <MoreItemsLabel>
                          {getAllSelectedUnits(units).length - 10} more
                        </MoreItemsLabel>
                      )}
                    </>
                  ) : (
                    <SelectedSegment>
                      {getSegmentDisplay(filter)}
                    </SelectedSegment>
                  )}
                </SegmentsRow>
              )}
            </FilterColumn>
          ))}
          <AddFilterButton onClick={addFilter}>+ Add filter</AddFilterButton>
          <ButtonWrapper>
            <ApplyCustomButton
              onClick={applyFilters}
              variant="contained"
              type="submit"
              disabled={
                isLoadingResponseCount ||
                isBelowThreshold ||
                (localFilters.length > 1 &&
                !localFilters[localFilters.length - 1].unit_or_attr &&
                localFilters[localFilters.length - 1].segments.length === 0)
              }
            >
              Apply filters (
              {localFilters.length === 1 && !localFilters[0].segments.length
                ? 0
                : localFilters.length}
              )
            </ApplyCustomButton>
          </ButtonWrapper>
        </StyledDialogContent>
      </StyledDialog>

      {topStore.isDashboardUnitFilterEnabled && (
        <UnitSelectionPopup
          open={showUnitSelection}
          onClose={() => setShowUnitSelection(false)}
          onSelect={handleUnitSelection}
          units={units}
          isSingleSelect={false}
          isEditMode={activeFilterIndex !== null && activeFilterIndex > 0 && 
                     localFilters[activeFilterIndex]?.segments && 
                     localFilters[activeFilterIndex].segments.length > 0}
          isFirstFilter={activeFilterIndex === 0}
          previousSelections={activeFilterIndex !== null && activeFilterIndex > 0 
            ? localFilters
              .filter((f, i) => i < activeFilterIndex && f.unit_or_attr?.name === "Unit")
              .flatMap(f => f.segments)
            : []}
        />
      )}

      <DiscardFilterPopupChanges
        open={showDiscardDialog}
        onClose={handleCloseDiscardDialog}
        onDiscard={handleDiscardChanges}
        isLoading={isLoadingResponseCount}
      />
    </>
  );
});

export default FiltersPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 48px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
  padding: 10px 48px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
`;

const ApplyCustomButton = styled(CustomButton)`
  width: 100%;
  height: 36px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 175px !important;

  input {
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 175px !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
    padding: 7px 9px !important;
    max-height: 34px !important;
  }
`;

const FilterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
`;

const ReselectUnitsButton = styled(SecondaryCustomButton)`
  border: 1px solid var(--colorBrandBackground2);
  color: var(--colorBrandBackground2);

  &[disabled] {
    border: 1px solid var(--colorNeutralForeground5) !important;
    color: var(--colorNeutralForeground5) !important;
  }
`;

const RemoveFilterButton = styled(CustomButton)`
  margin-left: 12px !important;
`;

const AddFilterButton = styled(SecondaryCustomButton)`
  max-width: 100px;
  margin-top: 12px !important;
`;

const FilterColumn = styled.div<{ $index: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.$index === 0 ? '16px' : '32px'};
`;

const SegmentsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 8px;
`;

const SelectedSegment = styled.span<{ $isUnit?: boolean }>`
  padding: 4px 12px;
  display: inline-block;
  margin: 2px 8px 2px 0px;
  background-color: ${props => props.$isUnit ? 'var(--colorBrandBackground1)' : 'var(--colorPaletteGreenBackground4)'};
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: ${props => props.$isUnit ? 'var(--colorNeutralForegroundInverted1)' : 'var(--colorNeutralForeground1)'};
  border-radius: 4px;
`;

const TrendsLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  svg {
    font-size: 16px;
    margin: 0px;
    color: var(--colorNeutralForeground2);
  }
`;

const TrendsLabelText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  margin-left: 2px;
`;

const MoreItemsLabel = styled.span`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--colorNeutralForeground2);
  padding: 4px 0;
`;

const StyledChip = styled(Chip)`
  background-color: var(--grenBackgroundColor3) !important;
  border: 1px solid var(--colorNeutralStroke1) !important;
  border-radius: 4px !important;
  color: var(--colorNeutralForeground1) !important;
  font-size: 12px !important;
  height: 24px !important;
  font-family: Roboto !important;

  .MuiChip-label {
    padding: 0 12px !important;
  }

  &:hover {
    background-color: var(--colorNeutralBackground2) !important;
  }
`;
