import { ROLE_LIST } from "constants/role-list";
import { DIMENSION, ENPS, OVERAL_SCORE } from "constants/dashboard-overview";
import { useGetProjects } from "api/dasboard/useGetProjects";
import { useGetDashboardEnps } from "api/dasboard/useGetDashboardEnps";
import { useGetBenchmarkList } from "api/dasboard/useGetBenchmarkList";
import { useGetSurveyDashboard } from "api/dasboard/useGetSurvey";
import { useGetDashboardTrends, useGetDashboardTrendsWidget } from "api/dasboard/useGetDashboardTrends";
import { DASHBOARD_MENU_VALUES, PROJECT_STATUSES } from "constants/dashboard-menu";
import { useGetDashboardSegments } from "api/dasboard/useGetDashboardSegments";
import { getSurveyAttributeAsync } from "api/dasboard/useGetSurveyAttribute";
import { getProjectAttributeAsync } from "api/dasboard/useGetProjectAttribute";
import { useGetDimensionsQuestion } from "api/dasboard/useGetDimensionsQuestion";
import { useGetQuestionBySurveyId } from "api/dasboard/useGetQuestionBySurveyId";
import { useGetDashboardDimensions } from "api/dasboard/useGetDashboardDimensions";
import { action, computed, reaction, toJS } from "mobx";
import { useGetDashboardOveralScore } from "api/dasboard/useGetDashboardOveralScore";
import { getSurveyResponseCountAsync } from "api/dasboard/useGetSurveyResponseCount";
import { useGetDashboardParticipation } from "api/dasboard/useGetDashboardParticipation";
import { makeAutoObservable, runInAction } from "mobx";
import { useGetDashboardOpenEndedQuestions } from "api/dasboard/useGetDashboardOpenEndedQuestions";
import { NavigateOptions, URLSearchParamsInit } from "react-router-dom";
import { TDasbboardFilter, TDashboardGlobalFilter, TDashboardUnitFilter, TProject, TProjectInfo } from "types/dashboard";

import dayjs from "dayjs";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { useGetDashboardData } from "api/dasboard/useGetDashboardData";
import { USE_DASHBOARD_API } from "constants/config";
import { useGetandAcknowledgeHistoricalImport } from "api/useGetAndAcknowledgeHistoricalImport";
import { SURVEY_TYPE_ORDER } from "constants/dashboard-menu";
import { processFilters } from "utils/dashboard/processFilters";
import MainStore from "MainStore";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";
import { buildTree } from "./FilterBuildTreeUnit";

class Store {
  projectInfo: TProjectInfo = null;

  activeTab: string = "overview";

  isSurveyProjectsPending = false;
  isSurveyPending = false;
  isOveralScorePending = false;
  isEnpsPending = false;
  isDimensionsPending = false;
  isParticipationLoading = false;
  isDimensionQuestionsPending = false;
  isTrendsPending = false;
  isSegmentsPending = false;
  isOpenEndPending = false;
  isSurveyResponseCountPending = false;
  isSureyAttributesPending = false;
  isProjectAttributesPending = false;
  isSurveyQuestionsListPending = false;
  isBenchmarkListPending = false;
  isDashboardDataPending = false;
  isDashboardDataError = false;

  responsesSelectedSentiment: string = "";
  isShowSentiment: boolean = false;

  benchmarkFilterList: { value: number; label: string }[] = [];

  overviewBenchmark: number = undefined;
  filters: TDasbboardFilter[] = [
    {
      unit_or_attr: null,
      segments: [],
    },
  ];

  filterAttributesList: TDashboardGlobalFilter[] = [];
  filterSegmentsList: { label: string; value: number }[] = [];
  filterUnitList: TDashboardUnitFilter[] = [];
  isDashboardUnitFilterEnabled: boolean = false;


  overviewSegmentsCardFilter: number = 0;
  selectedOverviewSegmentsCardFilter: string = null;
  overviewTrendCardFilter: number = 0;
  overviewTrendCardDimenssionTypeList: { label: string; value: number }[] = [];
  selectedOverviewTrendCardDimenssionType: string = null;

  surveyProjectsList: TProject[] = [];
  surveyList = [];
  viewTypes: { name: string; value: string }[] = [];

  selectedProject: TProject = null;
  selectedSurvey: {
    label: string;
    value: number;
    status: string;
    threshold: number;
    isFirst: boolean;
  } = null;
  selectedViewType: string;

  statusIdNavName: string = "";

  selectedResponsesOpenEndedQuestion: number = null;

  isOpenFiltersPopup: boolean = false;

  questionSelectedOpenEndedCard: number = null;
  openEndedTopics: number = 0;
  openEndedTopicsValid: number = 0;

  surveyQuestionsList = [];

  isSurveyHaveResponseCount: boolean = false;
  isDisplayOpenEndedCard: boolean = false;
  isDisplayTrends: boolean = true;

  isResponseScoredTabDisplay: boolean = true;
  isResponseOPenEndedTabDisplay: boolean = true;
  isResponseOthersTabDisplay: boolean = true;
  isNotOnlyOpenendQuestions: boolean = false;

  hasScoredQuestions: boolean = false;
  hasOpenEndedQuestions: boolean = false;
  hasOthersQuestions: boolean = false;

  isOveralScoreEmpty: boolean = false;
  isENPSEmpty: boolean = false;
  isDimensionsEmpty: boolean = false;
  isSegmentsEmpty: boolean = false;

  recipientsCount: number = 0;
  respondentCount: number = 0;

  role: string = "ADMIN";
  secondaryRole = "ROLL_UP_TEAM";

  isAllCompaniesBenchmark: boolean = true;

  hotspotsViewingAs: string = "admin";

  responsesActiveLocalTab: string = "scored_questions";

  lastUpdated: string = `${dayjs().format("h")}:${Number(dayjs().format("m")) >= 30 ? "30" : "00"
    } ${dayjs().format("A")}`;

  trendsWidgetData = null;


  responsesPagesName: string[] = [];
  overviewPagesName: string[] = [];
  prioritizePagesName: string[] = [];

  constructor() {
    makeAutoObservable(this, {
      calculateRespondentsCount: computed,
      isPending: computed,
      isSegmentsThresholdShown: computed,
      isOpenEndedShown: computed,
      clearFilters: action,
    });

    reaction(
      () => [StoreLayout.currentEntityId, StoreLayout.currentWorkspaceId, this.role],
      () => this.clearFilters()
    );

    reaction(
      () => [
        this.activeTab,
        this.selectedSurvey,
        this.isResponseScoredTabDisplay,
        this.isResponseOPenEndedTabDisplay,
        this.isResponseOthersTabDisplay,
      ],
      () => {
        if (this.activeTab === DASHBOARD_MENU_VALUES.responses) {
          if (this.isResponseScoredTabDisplay) {
            this.responsesActiveLocalTab = "scored_questions";
          } else if (this.isResponseOPenEndedTabDisplay) {
            this.responsesActiveLocalTab = "open_ended";
          } else {
            this.responsesActiveLocalTab = "others";
          }
        }
      }
    );

    reaction(
      () => [this.questionSelectedOpenEndedCard, this.filters],
      () => {
        if (!USE_DASHBOARD_API) {
          return;
        }

        const selectedItem = this.surveyQuestionsList.find(
          (el) => el.value === this.questionSelectedOpenEndedCard
        );

        if (selectedItem) {
          runInAction(() => {
            this.openEndedTopics = selectedItem.topics;
            this.openEndedTopicsValid = selectedItem.validTopics;
            this.projectInfo = { ...this.projectInfo, openEndQuestion: selectedItem.data || [] };
          });
        }
      }
    );
  }

  get isSegmentsThresholdShown() {
    return Boolean(
      store.filters.find((filter: TDasbboardFilter) => Boolean(filter.segments.length > 1))
    );
  }

  get isOpenEndedShown() {
    return Boolean(
      this.isDisplayOpenEndedCard &&
      this.surveyQuestionsList?.length > 0 &&
      this.projectInfo?.openEndQuestion?.length > 0
    );
  }

  get isPending() {
    return Boolean(
      this.isSurveyProjectsPending ||
      this.isSurveyPending ||
      this.isOveralScorePending ||
      this.isEnpsPending ||
      this.isDimensionsPending ||
      this.isParticipationLoading ||
      this.isDimensionQuestionsPending ||
      this.isTrendsPending ||
      this.isSegmentsPending ||
      this.isOpenEndPending ||
      this.isSurveyResponseCountPending ||
      this.isSureyAttributesPending ||
      this.isProjectAttributesPending ||
      this.isSurveyQuestionsListPending ||
      this.isBenchmarkListPending
    );
  }

  get calculateRespondentsCount() {
    if (this.respondentCount === this.recipientsCount && this.isSurveyHaveResponseCount)
      return "all";

    return this.respondentCount;
  }

  clearFilters() {
    this.filters = [
      {
        unit_or_attr: null,
        segments: [],
      },
    ];
  }

  redirectOpenEnded = () => {
    this.activeTab = "responses";
    this.responsesActiveLocalTab = "open_ended";
  };

  setData = (value: any, field: string) => {
    this[field] = value;
  };

  getDimensionsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isDimensionsPending = true;
      const processedFilters = processFilters(filters, this.filterUnitList);

      const response = await useGetDashboardDimensions({
        survey_id,
        attributes: processedFilters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, dimensions: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isDimensionsPending = false;
    }
  };

  getParticipationData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    this.isParticipationLoading = true;
    try {
      const processedFilters = processFilters(filters, this.filterUnitList);

      const response = await useGetDashboardParticipation({
        survey_id,
        attributes: processedFilters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, participation: response.data };
        });
      } else {
        throw new Error();
      };
    } catch (error) {
      console.error(error);
    } finally {
      this.isParticipationLoading = false;
    }
  };

  getSegmentsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      const processedFilters = processFilters(filters, this.filterUnitList);
      this.isSegmentsPending = true;
      const response = await useGetDashboardSegments({
        survey_id,
        attributes: processedFilters,
        segmentFilter: {
          type: this.overviewSegmentsCardFilter,
          conditional:
            this.overviewSegmentsCardFilter !== DIMENSION
              ? null
              : this.selectedOverviewSegmentsCardFilter,
        },
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, segments: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isSegmentsPending = false;
    }
  };

  getEnpsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      const processedFilters = processFilters(filters, this.filterUnitList);
      this.isEnpsPending = true;
      const response = await useGetDashboardEnps({
        survey_id,
        attributes: processedFilters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, enps: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isEnpsPending = false;
    }
  };

  getOveralScoreData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isOveralScorePending = true;
      const processedFilters = processFilters(filters, this.filterUnitList);
      const response = await useGetDashboardOveralScore({
        survey_id,
        attributes: processedFilters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, overalScore: response.data };
          this.isOveralScorePending = false;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
      this.isOveralScorePending = false;
    }
  };

  updateDashboardPendingStatuses = (val: boolean) => {
    this.isParticipationLoading = val;
    this.isOveralScorePending = val;
    this.isEnpsPending = val;
    this.isDimensionsPending = val;
    this.isSurveyQuestionsListPending = val;
    this.isOpenEndPending = val;
  };

  getDashboardData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.updateDashboardPendingStatuses(true);

      const processedFilters = processFilters(filters, this.filterUnitList);
      const response = await useGetDashboardData({
        dashboardParameters: {
          workspace_id: StoreLayout.currentWorkspaceId,
          project_id: this.selectedProject.id,
          entity_id: StoreLayout.currentEntityId,
          survey_id,
          viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        },
        attributes: processedFilters,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          const topicsFromOpenEndedQuestions = (response.data?.topicsFromOpenEndedQuestions || [])
            .filter(question => question.id != null && question.data?.length > 0);

          const firstQuestionWithData = topicsFromOpenEndedQuestions[0];

          this.projectInfo = {
            ...this.projectInfo,
            participation: response.data?.participation,
            overalScore: response.data?.overallScore,
            enps: response.data?.enps,
            dimensions: response.data?.dimensions,
            openEndQuestion: firstQuestionWithData?.data || []
          };

          this.isOpenEndPending = false;

          this.surveyQuestionsList = topicsFromOpenEndedQuestions.map(({title, id, topics, validTopics, ...el}) => ({
            label: title,
            value: id,
            topics,
            validTopics,
            ...el
          }));

          const initialQuestion = this.surveyQuestionsList.length > 0 ? this.surveyQuestionsList[0] : null;
          if (initialQuestion) {
            this.questionSelectedOpenEndedCard = initialQuestion.value;
            this.openEndedTopics = initialQuestion.topics;
            this.openEndedTopicsValid = initialQuestion.validTopics;
          } else {
            this.questionSelectedOpenEndedCard = null;
            this.openEndedTopics = 0;
            this.openEndedTopicsValid = 0;
          }
          this.updateDashboardPendingStatuses(false);
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      this.updateDashboardPendingStatuses(false);
    }
  };

  getTrendsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[],
    refreshData?: boolean
  ) => {
    try {
      this.isTrendsPending = true;
      const processedFilters = processFilters(filters, this.filterUnitList);
      if (USE_DASHBOARD_API) {
        if (refreshData || !this.trendsWidgetData) {
          this.trendsWidgetData = null;
          this.projectInfo = { ...this.projectInfo, trends: null };
          this.overviewTrendCardDimenssionTypeList = [];
          this.selectedOverviewTrendCardDimenssionType = null;
          this.overviewTrendCardFilter = null;
          const response = await useGetDashboardTrendsWidget({
            dashboardParameters: {
              workspace_id: StoreLayout.currentWorkspaceId,
              entity_id: StoreLayout.currentEntityId,
              project_id: this.selectedProject.id,
              survey_id,
              viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
            },
            attributes: processedFilters,
            benchmarkType: this.overviewBenchmark,
          });

          if (response.status === 200 || response.status === 201) {
            runInAction(() => {
              this.trendsWidgetData = response.data;
              this.overviewTrendCardFilter = OVERAL_SCORE;
              this.overviewTrendCardDimenssionTypeList = response?.data?.dimensions?.map((el) => ({
                label: el.name,
                value: el.name,
              })) || [];
              this.selectedOverviewTrendCardDimenssionType = this.overviewTrendCardDimenssionTypeList[0]?.label;
              this.projectInfo = { ...this.projectInfo, trends: {trends: response?.data?.overallScore} };
            });
          } else throw new Error();
        } else {
          let widgetData = {trends: []};
          if (this.overviewTrendCardFilter === ENPS) {
            widgetData = {
              trends: this.trendsWidgetData?.enps
            }
          } else if (this.overviewTrendCardFilter === OVERAL_SCORE) {
            widgetData = {
              trends: this.trendsWidgetData?.overallScore
            }
          } else if (this.overviewTrendCardFilter === DIMENSION) {
            const dimensionData = this.trendsWidgetData?.dimensions?.find(d => d.name === this.selectedOverviewTrendCardDimenssionType);
            if (dimensionData) {
              widgetData = dimensionData;
            }
          }
          runInAction(() => {
            this.projectInfo = { ...this.projectInfo, trends: widgetData };
          });
        }

      } else {
        const response = await useGetDashboardTrends({
          survey_id,
          attributes: filters,
          segmentFilter: {
            type: this.overviewTrendCardFilter,
            conditional:
              this.overviewTrendCardFilter !== DIMENSION
                ? null
                : this.selectedOverviewTrendCardDimenssionType,
          },
          viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
          benchmarkType: this.overviewBenchmark,
        });
        if (response.status === 200 || response.status === 201) {
          runInAction(() => {
            this.projectInfo = { ...this.projectInfo, trends: response.data };
          });
        } else throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isTrendsPending = false;
    }
  };

  getOpenEndedQuestionsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isOpenEndPending = true;
      const processedFilters = processFilters(filters, this.filterUnitList);
      const response = await useGetDashboardOpenEndedQuestions({
        survey_id,
        attributes: processedFilters,
        questionId: Number(this.questionSelectedOpenEndedCard),
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, openEndQuestion: response.data?.data || [] };
          this.openEndedTopics = response.data.topics;
          this.openEndedTopicsValid = response.data.validTopics;
        });
      } else throw new Error();
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isOpenEndPending = false;
    }
  };

  getSurveyProjects = async (
    project_id: string,
    setQueryParams: (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => void
  ) => {
    try {
      this.isSurveyProjectsPending = true;
      const response = await useGetProjects(StoreLayout.currentEntityId);
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          const sortedProjects = response.data.sort((a, b) => {
            return a.status - b.status;
          });

          this.surveyProjectsList = sortedProjects.map((el) => ({
            label: el.name,
            value: el.id,
            ...el,
          }));
        });
        runInAction(() => {
          if (project_id && response.data.some((project) => project.id === Number(project_id))) {
            this.selectedProject = response.data.find(
              (project) => project.id === Number(project_id)
            );
          } else {
            setQueryParams({ project_id: String(response.data[0].id) });
            this.selectedProject = response.data[0];
          }
        });
      } else throw new Error();
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isSurveyProjectsPending = false;
    }
  };

  getSurvey = async (
    project_id: number,
    survey_id: string,
    setQueryParams: (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => void
  ) => {
    try {
      this.isSurveyPending = true;
      const historicalImportProgress = await useGetandAcknowledgeHistoricalImport(
        project_id,
        "PROCESSING"
      );

      const excludedSurveyId =
        historicalImportProgress.data.jobs?.find((job) => job.status === "PROCESSING")?.survey_id || null;

      const response = await useGetSurveyDashboard(project_id);


      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        const filteredData = excludedSurveyId
          ? response.data.filter((survey) => survey.id !== excludedSurveyId)
          : response.data;
        filteredData.sort((a, b) => {
          return SURVEY_TYPE_ORDER[a.status_idCode] - SURVEY_TYPE_ORDER[b.status_idCode];
        })
        if (filteredData.length === 0) {
          throw new Error("No available surveys to display.");
        }
        runInAction(() => {
          this.surveyList = filteredData.map((el) => ({
            label: el.name,
            value: el.id,
            status: el.status_idNavName,
            threshold: el.threshold,
            isFirst: el.isFirst,
          }));
        });

        runInAction(() => {
          if (survey_id && filteredData.some((survey) => survey.id === Number(survey_id))) {
            const survey = filteredData.find((survey) => survey.id === Number(survey_id));
            this.selectedSurvey = {
              label: survey.name,
              value: survey.id,
              status: survey.status_idNavName,
              threshold: survey.threshold,
              isFirst: survey.isFirst,
            };
            localStorage.setItem(
              "dashboardActiveSurvey",
              JSON.stringify({
                projectName: this.selectedProject.name,
                surveyName: survey.name,
                status: survey.status_idNavName,
              })
            );
          } else {
            this.selectedSurvey = {
              label: filteredData[0].name,
              value: filteredData[0].id,
              status: filteredData[0].status_idNavName,
              threshold: filteredData[0].threshold,
              isFirst: filteredData[0].isFirst,
            };
            setQueryParams({
              project_id: String(project_id),
              survey_id: String(filteredData[0].id),
            });
            localStorage.setItem(
              "dashboardActiveSurvey",
              JSON.stringify({
                projectName: this.selectedProject.name,
                surveyName: filteredData[0].name,
                status: filteredData[0].status_idNavName,
              })
            );
          }
        });
      } else {
        throw new Error("Failed to fetch surveys.");
      }
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isSurveyPending = false;
    }
  };



  getSurveyAttributes = async (workspace_id: number, entity_id: number, project_id: number, survey_id: number) => {
    try {
      this.isSureyAttributesPending = true;
      this.isDashboardDataError = false;
      const response = await getSurveyAttributeAsync({
        workspace_id,
        entity_id,
        project_id,
        survey_id,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role
      });
      this.filterAttributesList = response;

      if (this.isDashboardUnitFilterEnabled) {
        var unit = response.find((el) => el.name.toLowerCase() === "unit");
        var selectedUnit = this.filters.find((el) => el.unit_or_attr?.name.toLowerCase() === "unit");
      if (unit) {

        runInAction(() => {
          this.filterUnitList = buildTree(unit.values, selectedUnit);
        });
        }
      }
    } catch (error) {
      console.error(error);
      this.isDashboardDataError = true;
    } finally {
      this.isSureyAttributesPending = false;
    }
  };

  getProjectAttributes = async (project_id: number) => {
    try {
      this.isProjectAttributesPending = true;
      const response = await getProjectAttributeAsync(project_id);
      this.filterAttributesList = response;
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isProjectAttributesPending = false;
    }
  };

  changeFilters = (filters: TDasbboardFilter[], units: TDashboardUnitFilter[]) => {
    this.filters = filters;
    this.filterUnitList = units;
    this.isSurveyHaveResponseCount = false;
  };

  getDimensionQuestions = async (selectedSurvey: number) => {
    try {
      this.isDimensionQuestionsPending = true;
      const response = await useGetDimensionsQuestion(selectedSurvey);
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          this.overviewTrendCardDimenssionTypeList = response.data.map((el) => ({
            label: el.name,
            value: el.id,
          }));
        });
        runInAction(() => {
          this.selectedOverviewTrendCardDimenssionType = response.data[0].name;
          this.selectedOverviewSegmentsCardFilter = response.data[0].name;
        });
      } else throw new Error();
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isDimensionQuestionsPending = false;
    }
  };

  getSurveyQuestionsList = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isSurveyQuestionsListPending = true;
      this.surveyQuestionsList = [];
      const processedFilters = processFilters(filters, this.filterUnitList);
      const response = await useGetQuestionBySurveyId({ survey_id, attributes: processedFilters });
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          this.surveyQuestionsList = response.data.map((el) => ({
            label: el.title,
            value: el.id,
          }));
        });
        runInAction(() => {
          this.questionSelectedOpenEndedCard = response.data[0].id;
        });
        runInAction(() => {
          this.openEndedTopics = response.data.topics;
        });
        runInAction(() => {
          this.openEndedTopicsValid = response.data.validTopics;
        });
      } else throw new Error();
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isSurveyQuestionsListPending = false;
    }
  };

  private async fetchSurveyResponseCount(
    filters: { id: number; name: string; segments: string[] }[]
  ) {
    const processedFilters = processFilters(filters, this.filterUnitList);
    
    const requestData = {
      dashboardParameters: {
        workspace_id: StoreLayout.currentWorkspaceId,
        entity_id: StoreLayout.currentEntityId,
        project_id: this.selectedProject.id,
        survey_id: this.selectedSurvey.value,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
      },
      statsLevel: this.activeTab === DASHBOARD_MENU_VALUES.trends ? 'project' : 'survey',
      benchmarkType: this.overviewBenchmark,
      attributes: processedFilters,
    };

    return await getSurveyResponseCountAsync(requestData);
  }

  checkConfidentialityThreshold = async (
    filters: { id: number; name: string; segments: string[] }[]
  ): Promise<boolean> => {
    try {
      const response = await this.fetchSurveyResponseCount(filters);

      if (!response || response.confidentiality_threshold === null || response.confidentiality_threshold === undefined) {
        return false;
      }

      if (response.respondents < response.confidentiality_threshold) {
        return true
      } else{
        return false
      }
    } catch (err) {
      return false;
    }
  };

  getSurveyResponseCount = async (
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    this.isDashboardDataError = false;
    try {
      this.isSurveyResponseCountPending = true
      const response = await this.fetchSurveyResponseCount(filters);

      if (!response || response.confidentiality_threshold === null || response.confidentiality_threshold === undefined) {
        this.isDashboardDataError = true;
        return;
      }

      runInAction(() => {
        this.recipientsCount = response.recepients;
        this.respondentCount = response.respondents;

        if (typeof this.selectedSurvey?.threshold === "number") {
          this.isSurveyHaveResponseCount = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold
          );

          this.isDisplayOpenEndedCard = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold && el.type === "open-end"
          );

          this.isOveralScoreEmpty = response.questions.every((el) => !el.isOverall || el.totalResponces === 0);
          this.isENPSEmpty = response.questions.every((el) => el.type.toLowerCase() !== "enps" || el.totalResponces === 0)
          this.isDimensionsEmpty = response.questions.every((el) => (el.type !== "rating" && el.type !== "checkbox") || el.totalResponces === 0)
          this.isSegmentsEmpty = response.questions.every((el) => (el.type !== "rating" && el.type !== "checkbox" && el.type.toLowerCase() !== "enps") || el.totalResponces === 0)

          this.isResponseScoredTabDisplay = response.questions.some(
            (el) =>
              el.totalResponces >= this.selectedSurvey.threshold &&
              (el.type === "rating" || el.type === "enps")
          );

          this.isResponseOPenEndedTabDisplay = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold && el.type === "open-end"
          );

          this.isResponseOthersTabDisplay = response.questions.some(
            (el) =>
              el.totalResponces >= this.selectedSurvey.threshold &&
              el.type !== "open-end" &&
              el.type !== "rating" &&
              el.type !== "enps"
          );

          this.hasScoredQuestions = response.questions.some(
            (el) => el.type === "rating" || el.type === "enps"
          );

          this.hasOpenEndedQuestions = response.questions.some(
            (el) => el.type === "open-end"
          );

          this.hasOthersQuestions = response.questions.some(
            (el) => el.type !== "rating" && el.type !== "enps" && el.type !== "open-end"
          );

          this.isNotOnlyOpenendQuestions = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold && el.type !== "open-end"
          );
        } else {
          this.isDisplayTrends = response.questions.some((el) => el.type !== "open-end");
          this.isSurveyHaveResponseCount = false;
          this.isDisplayOpenEndedCard = false;
        }
      });
      this.isSurveyResponseCountPending = false
    } catch (err) {
      this.isSurveyResponseCountPending = false
      this.isOveralScoreEmpty = false;
      this.isENPSEmpty = false;
      console.error(err);
    }
  };

  getBenchmarkList = async (survey_id: number) => {
    try {
      this.isBenchmarkListPending = true;
      const response = await useGetBenchmarkList(survey_id);
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.benchmarkFilterList = response.data.map((el) => ({ value: el.id, label: el.name }));
          this.overviewBenchmark =
            response.data && response.data.length > 0 ? response.data[0].id : null;
        });
      } else throw new Error();
    } catch (error) {
      this.isDashboardDataError = true;
      console.error(error);
    } finally {
      this.isBenchmarkListPending = false;
    }
  };

  setOverviewPagesName(pages: string[]) {
    this.overviewPagesName = pages.map(page => `.${page}`);
   }

  setResponsesPagesName(pages: string[]) {
    this.responsesPagesName = pages.map(page => `.${page}`);
   }

   setPrioritizePagesName(pages: string[]) {
    this.prioritizePagesName = pages.map(page => `.${page}`);
   }

   async loadFeatureFlagDashboardUnitFilter() {
    try {
      const response = await getFeatureFlagStatus("dashboard_unit_filter");
        this.isDashboardUnitFilterEnabled = 
          response?.data?.enable_dashboard_unit_filter || this.isDashboardUnitFilterEnabled;
    } catch (err) {
      console.error(err);
    }
  }
}

const store = new Store();

export default store;
