import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import {
  FAVOURABLE,
  FAVOURABLE_VALUE,
  NEUTRAL,
  NEUTRAL_VALUE,
  UNFAVOURABLE,
  UNFAVOURABLE_VALUE,
} from "constants/dashboard-overview";
import { TScoredQuestionsDimensionResponse } from "types/dashboard";

import styled from "styled-components";
import topStore from "../../store";
import Accordion from "@mui/material/Accordion";
import MessageIcon from "@mui/icons-material/Message";
import PaginationList from "../PaginationList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Info } from "@mui/icons-material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

type CommentCardProps = {
    question: TScoredQuestionsDimensionResponse;
    isLastQuestion: boolean;
    localFilters: { id: number; name: string; segments: string[] }[];
    isQuestion?: boolean;
    isCommentsExpanded?: boolean;
};

const CommentCard: FC<CommentCardProps> = observer((props) => { 
  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(props.isCommentsExpanded || false);
  const [activeCommentsTab, setActiveCommentsTab] = useState<number>(FAVOURABLE_VALUE);
  const [commentsList, setCommentsList] = useState<string[]>([]);
  const [isBelowCT, setIsBelowCT] = useState(false);

  const changeCommentsTab = (tab: number) => {
    setActiveCommentsTab(tab);
  };

  useEffect(() => {
    const commentsDetails = props.question?.commentsDetails;
    if(commentsDetails){
      if(activeCommentsTab === FAVOURABLE_VALUE){
        setCommentsList(commentsDetails?.favorable?.comments || [] );
      }else if(activeCommentsTab === NEUTRAL_VALUE){
        setCommentsList(commentsDetails?.neutral?.comments || []);
      }else if(activeCommentsTab === UNFAVOURABLE_VALUE){
        setCommentsList(commentsDetails?.unfavorable?.comments || []);
      }
    }
  }, [
    isCommentsExpanded,
    activeCommentsTab,
    topStore.selectedSurvey,
    topStore.overviewBenchmark,
    props.localFilters
  ]);

  useEffect(() => {
    setIsBelowCT(props.question?.respondedCount < topStore.selectedSurvey.threshold);
  }, [props.question]);

  // Calculate meaninglessCount based on the question structure
  const calculateMeaninglessCount = () => {
    // For scored questions
    if (props.question.commentsDetails) {
      return (
        (props.question.commentsDetails?.neutral?.meaninglessCount || 0) + 
        (props.question.commentsDetails?.favorable?.meaninglessCount || 0) + 
        (props.question.commentsDetails?.unfavorable?.meaninglessCount || 0)
      );
    }
    
    // For other question types with direct meaninglessCount property
    if ('meaninglessCount' in props.question) {
      return props.question.meaninglessCount || 0;
    }
    
    // For other question types with responses array
    if ('responses' in props.question && Array.isArray(props.question.responses)) {
      return props.question.responses.reduce((sum, response) => 
        sum + (response.meaninglessCount || 0), 0);
    }
    
    return 0;
  };

  const meaninglessCount = calculateMeaninglessCount();

  return (
    <>
      {props.question.commentsCount > 0 && !isBelowCT && (
        <StyledAccordion
          expanded={isCommentsExpanded}
          onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
        >
          <AccordionSummary aria-controls="comments-accordion" id="comments">
            <AccordionTitle>
              <AccordionTitleGroup>
                <StyledMessageIcon />
                <AccordionTitleText>
                  Comments ({props.question.commentsCount})
                </AccordionTitleText>
              </AccordionTitleGroup>
              <RightContainer>
                {isCommentsExpanded && meaninglessCount > 0 && (
                  <ResponsesNotShownContainer>
                    <NilCountText>{meaninglessCount} comments are not shown</NilCountText>
                    <TooltipWrapper>
                      <CustomWidthTooltip 
                        title="These responses are hidden because they are less meaningful, for example 'nil' or 'no comment'."
                        placement="top"
                        arrow
                      >
                        <StyledInfoIcon fontSize="small" />
                      </CustomWidthTooltip>
                    </TooltipWrapper>
                  </ResponsesNotShownContainer>
                )}
                <AccordionToogleButton>
                  {isCommentsExpanded ? "Hide" : "Show"}
                  <AccordionToogleButtonLabel>
                    {isCommentsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </AccordionToogleButtonLabel>
                </AccordionToogleButton>
              </RightContainer>
            </AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionMenu>
              <StyledTab
                $active={Boolean(activeCommentsTab === FAVOURABLE_VALUE)}
                onClick={() => changeCommentsTab(FAVOURABLE_VALUE)}
              >
                <StyledTabText>
                  {FAVOURABLE} ({props.question.positiveCommentsCount - props.question.commentsDetails?.favorable?.meaninglessCount || 0})
                </StyledTabText>
              </StyledTab>
              <StyledTab
                $active={Boolean(activeCommentsTab === NEUTRAL_VALUE)}
                onClick={() => changeCommentsTab(NEUTRAL_VALUE)}
              >
                <StyledTabText>
                  {NEUTRAL} ({props.question.passiveCommentsCount - props.question.commentsDetails?.neutral?.meaninglessCount || 0})
                </StyledTabText>
              </StyledTab>
              <StyledTab
                $active={Boolean(activeCommentsTab === UNFAVOURABLE_VALUE)}
                onClick={() => changeCommentsTab(UNFAVOURABLE_VALUE)}
              >
                <StyledTabText>
                  {UNFAVOURABLE} ({props.question.negativeCommentsCount - props.question.commentsDetails?.unfavorable?.meaninglessCount || 0})
                </StyledTabText>
              </StyledTab>
            </AccordionMenu>
            <AccordionContent>
              <PaginationList
                list={commentsList}
              />
            </AccordionContent>
          </AccordionDetails>
        </StyledAccordion>
      )}
    </>
  )
});

export default CommentCard;

const AccordionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
`;

const StyledMessageIcon = styled(MessageIcon)`
  color: var(--colorNeutralForeground5);
  font-size: 16px !important;
`;

const AccordionTitleText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-left: 6px;
`;

const AccordionToogleButton = styled.button`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorBrandForeground1);
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
  border-radius: 10px !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;
  box-shadow: 0px 2px 8px 0px var(--colorNeutralBackground3) !important;

  &:before {
    display: none !important;
  }
`;

const AccordionTitleGroup = styled.div`
  display: flex;
  align-items: center;
`;

const AccordionToogleButtonLabel = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const AccordionContent = styled.div`
  width: 100%;
  padding: 24px;
  background-color: var(--colorNeutralForeground8);
`;

const StyledTab = styled.span<{ $active: boolean }>`
  background-color: ${(props) =>
    props.$active ? "var(--colorNeutralBackground1)" : "var(--colorPaletteGrayBackground1)"};
  border: 1px solid var(--colorPaletteBlueBackground1);
  padding: 10px 24px;
  height: 40px;
  min-height: 0;
  width: fit-content;
  border-radius: 10px 10px 0px 0px;
  margin: 0px 4px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground2)"};
  }
`;

const StyledTabText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AccordionMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ResponsesNotShownContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const NilCountText = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const TooltipWrapper = styled.span`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
`;

const StyledInfoIcon = styled(Info)`
  width: 14px;
  height: 14px;
  color: var(--colorPaletteVioletBackground2);
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 200,
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  }
});

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;